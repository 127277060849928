<template>
  <div class="escalator-monitor">
    <div class="em-wrapper">
      <div class="em-title">{{elevator.name}}</div>
      <div
        class="em-monitor"
        :class="{'em-monitor-down':monitorData.s1_direction===2,'em-monitor-run':monitorRun}"></div>
      <div v-if="wsShowTip" class="em-connect-status-wrapper">
        <div class="em-connect-status">
          <div class="tip">
            <img class="icon" src="/static/images/escalatorMonitor/loading.gif"/>
            <span class="text">{{wsTipText}}</span>
          </div>
          <el-button
            v-if="wsShowRetry"
            class="retry"
            type="primary"
            icon="el-icon-refresh"
            @click="createWs">
            重试
          </el-button>
        </div>
      </div>
      <div class="em-detail">
        <div class="em-status">
          {{monitorData.s1_direction_desc}}
        </div>
        <table class="em-base-info-right">
          <tr>
            <th>电梯编号:</th>
            <td>{{elevator.no}}</td>
            <th>设备代码:</th>
            <td>{{elevator.regNo}}</td>
          </tr>
          <tr>
            <th>终端编号:</th>
            <td>{{elevator.dtuCode}}</td>
            <th>节点:</th>
            <td>{{elevator.nodeCode}}</td>
          </tr>
          <tr>
            <th>电梯名称:</th>
            <td>{{elevator.name}}</td>
            <th>使用单位:</th>
            <td>{{elevator.useUnitName}}</td>
          </tr>
          <tr>
            <th>维保企业:</th>
            <td colspan="3">{{elevator.maintComName}}</td>
          </tr>
        </table>
        <el-tabs value="tabStatus" class="tab" type="border-card" @tab-click="onTabClick">
          <el-tab-pane label="基本状态" name="tabStatus">
            <table class="vm-table" style="margin-top: 7px;width: 50%">
              <tr>
                <th colspan="3"><span class="title-icon"></span>电梯状态</th>
              </tr>
              <tr>
                <td>
                  <i class="iconfont iot-service"></i>
                  <div class="cell-title">当前服务模式</div>
                  <div class="cell-value">{{monitorData.s1_mode_desc}}</div>
                </td>
                <td>
                  <i class="iconfont iot-car"></i>
                  <div class="cell-title">扶梯运行状态</div>
                  <div class="cell-value">{{monitorData.s1_run_desc}}</div>
                </td>
                <td>
                  <i class="iconfont iot-lock"></i>
                  <div class="cell-title">扶梯运行方向</div>
                  <div class="cell-value">{{monitorData.s1_direction_desc}}</div>
                </td>
              </tr>
            </table>
            <table class="vm-table single-table" style="margin-top: 20px">
              <tr>
                <th colspan="8"><span class="title-icon"></span>上机坑信号</th>
              </tr>
              <tr>
                <td :class="getTdClass(0)">运行触发</td>
                <td :class="getTdClass(1)">上梯级陷</td>
                <td :class="getTdClass(2)">上部急停</td>
                <td :class="getTdClass(3)">下行信号</td>
                <td :class="getTdClass(4)">上行信号</td>
                <td :class="getTdClass(5)">上部检修</td>
                <td :class="getTdClass(6)">下梯级1</td>
                <td :class="getTdClass(7)">上梯级1</td>
              </tr>
              <tr>
                <td :class="getTdClass(8)">上扶手左</td>
                <td :class="getTdClass(8+1)">上梳板左</td>
                <td :class="getTdClass(8+2)">主脉冲B</td>
                <td :class="getTdClass(8+3)">主脉冲A</td>
                <td :class="getTdClass(8+4)">驱断左1</td>
                <td :class="getTdClass(8+5)">附加制动</td>
                <td :class="getTdClass(8+6)">安全回1</td>
                <td :class="getTdClass(8+7)">抱闸反馈</td>
              </tr>
            </table>
            <table class="vm-table single-table" style="margin-top: 20px">
              <tr>
                <th colspan="8"><span class="title-icon"></span>下机坑信号</th>
              </tr>
              <tr>
                <td :class="getTdClass(16)">驱断左2</td>
                <td :class="getTdClass(16+1)">下扶手左</td>
                <td :class="getTdClass(16+2)">下梳齿左</td>
                <td :class="getTdClass(16+3)">安全回2</td>
                <td :class="getTdClass(16+4)">扶梯电源</td>
                <td :class="getTdClass(16+5)">下急停钮</td>
                <td :class="getTdClass(16+6)">下梯级陷</td>
                <td :class="getTdClass(16+7)">下部检修</td>
              </tr>
              <tr>
                <td class="gray"></td>
                <td class="gray"></td>
                <td class="gray"></td>
                <td class="gray"></td>
                <td class="gray"></td>
                <td class="gray"></td>
                <td class="gray"></td>
                <td :class="getTdClass(24+7)">下梯级2</td>
              </tr>
            </table>
          </el-tab-pane>
          <el-tab-pane label="电梯故障" name="tabFault">
            <table class="vm-table" style="margin-top: 7px">
              <tr>
                <td :class="{'selected':!monitorData.faultCodes||!monitorData.faultCodes.length}">扶梯无故障</td>
                <td :class="{'selected':hasFault(2)}">安全回路断路</td>
              </tr>
              <tr>
                <td :class="{'selected':hasFault(3)}">扶梯超速保护</td>
                <td :class="{'selected':hasFault(4)}">非操纵逆转保护</td>
              </tr>
              <tr>
                <td :class="{'selected':hasFault(5)}">梯级或踏板的缺失保护</td>
                <td :class="{'selected':hasFault(6)}">附加制动器动作</td>
              </tr>
              <tr>
                <td :class="{'selected':hasFault(7)}">驱动链断裂或过份伸长</td>
                <td :class="{'selected':hasFault(8)}">梳齿板处异物卡阻</td>
              </tr>
              <tr>
                <td :class="{'selected':hasFault(9)}">扶手带入口夹入异物</td>
                <td :class="{'selected':hasFault(10)}">梯级或踏板下陷</td>
              </tr>
              <tr>
                <td :class="{'selected':hasFault(11)}">制动系统意外未释放</td>
                <td :class="{'selected':hasFault(12)}">其他阻止扶梯再启动的故障</td>
              </tr>
            </table>
          </el-tab-pane>
          <el-tab-pane label="故障记录" name="tabFaultList">
            <el-button
              type="primary"
              icon="el-icon-refresh"
              style="margin-bottom: 10px"
              @click="getFaultList(1)">
              刷新
            </el-button>
            <vm-table-new
              ref="faultTable"
              :filter.sync="faultSearch"
              url="sensorFaults">
              <el-table-column prop="code" label="故障代码" align="center" width="100px"></el-table-column>
              <el-table-column label="故障名称">
                <template slot-scope="scope">
                  {{sensorFault[scope.row.code] || "未知"}}
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="发生时间"></el-table-column>
            </vm-table-new>
          </el-tab-pane>
          <el-tab-pane label="事件记录" name="tabEvent">
            <el-button
              type="primary"
              icon="el-icon-refresh"
              style="margin-bottom: 10px"
              @click="getEventList(1)">
              刷新
            </el-button>
            <vm-table-new
              ref="eventTable"
              :filter.sync="eventSearch"
              url="sensorEvents">
              <el-table-column prop="code" label="事件代码" align="center"></el-table-column>
              <el-table-column label="事件名称">
                <template slot-scope="scope">
                  {{sensorEvent[scope.row.code] || "未知"}}
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="发生时间"></el-table-column>
            </vm-table-new>
          </el-tab-pane>
          <el-tab-pane label="扶梯设置" name="tabSetting">
            <div class="setting">
              <div style="text-align: center">
                <el-button style="width: 135px" @click="set(5)">修改采集盒时间</el-button>
                <el-button style="width: 135px" @click="set(3)">设置信号开关(32路)</el-button>
                <el-button style="width: 135px" @click="set(201)">打开检验模式</el-button>
                <el-button style="width: 135px" @click="set(7)">设置输出服务器ip</el-button>
              </div>
              <div style="text-align: center;margin-top: 10px">
                <el-button style="width: 135px" @click="set(6)">重启采集盒</el-button>
                <el-button style="width: 135px" @click="set(4)">设置信号逻辑(32路)</el-button>
                <el-button style="width: 135px" @click="set(200)">关闭检验模式</el-button>
                <el-button style="width: 135px" @click="set(8)">设置IO屏蔽字(32路)</el-button>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="统计数据" name="tabStats">
            <el-button

              type="primary"
              @click="sendHH()">
              发送查询命令
            </el-button>
            <table class="vm-table" style="margin-top: 8px">
              <tr>
                <th>设备累计运行时间</th>
                <td>{{hh.runningTime}}分钟</td>
              </tr>
              <tr>
                <th>设备累计运行次数</th>
                <td>{{hh.runningCount}}次</td>
              </tr>
            </table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <cmd-h-d ref="cmdHD" :dtu-code="elevator.dtuCode" :node-code="elevator.nodeCode" proto-type="HI"></cmd-h-d>
  </div>
</template>
<script>
  import VmTableNew from "@/components/refactor/VmTable";
  import CmdHD from "./CmdHD";

  const s1_mode = {
    0: "未知",
    1: "正常运行",
    2: "停止服务",
    3: "检修服务",
    4: "检验模式",
  };
  const s1_run = {
    0: "停止",
    1: "运行",
  };
  const s1_direction = {
    0: "无方向",
    1: "上行",
    2: "下行",
  };

  const wsUrl = window.config.wsUrl;
  export default {
    components: {CmdHD, VmTableNew},
    props: {
      baseInfo: {
        type: Object,
      },
    },
    data() {
      return {
        ws: null,
        wsShowTip: false,
        wsShowRetry: false,
        wsTipText: "等待数据...",
        elevator: {},
        monitorData: {
          faultCodes: [],
          eventCodes: [],
          s1_mode: 0,
          s1_run: 0,
          s1_direction: 0,
          s1_mode_desc: "--",
          s1_run_desc: "--",
          s1_direction_desc: "--",
        },
        fault: {
          data: [],
          loading: false,
          currentPage: 1,
          total: 0,
        },
        eventSearch: {
          elevatorId: 0,
        },
        faultSearch: {
          elevatorId: 0,
        },
        sensorEvent: {
          1: "扶梯恢复自动运行模式",
          2: "进入检修运行模式",
          3: "主电源断电",
          4: "急停开关动作",
        },
        sensorFault: {
          2: "安全回路断路",
          3: "扶梯超速保护",
          4: "非操纵逆转保护",
          5: "梯级或踏板的缺失保护",
          6: "附加制动器动作",
          7: "驱动链断裂或过份伸长",
          8: "梳齿板处异物卡阻",
          9: "扶手带入口夹入异物",
          10: "梯级或踏板下陷",
          11: "制动系统意外未释放",
          12: "其他阻止扶梯再启动的故障",
        },
        hf: {
          dataSwitch: [],
          dataLogic: [],
          signalStatus: [],
        },
        hh: {
          runningTime: 0,
          runningCount: 0,
        },
      };
    },
    computed: {
      monitorRun() {
        return this.monitorData.s1_run === 1 && this.monitorData.s1_direction !== 0;
      },
    },
    destroyed() {
      if (this.ws) {
        this.ws.close();
      }
    },
    methods: {
      init(data) {
        document.title = "扶梯监控";
        this.elevator = data.elevator;
        this.createWs();
        this.getEventList(1);
        this.getFaultList(1);
      },
      getEventList(pageIndex) {
        this.eventSearch.elevatorId = this.elevator.id;
        this.$refs.eventTable.getList(pageIndex);
      },
      getFaultList(pageIndex) {
        this.faultSearch.elevatorId = this.elevator.id;
        this.$refs.faultTable.getList(pageIndex);
      },
      createWs() {
        this.wsShowTip = false;
        this.wsShowRetry = false;
        try {
          this.ws = new WebSocket(wsUrl);
          this.initWsEvent();
        } catch (e) {
          this.wsTipText = "出错";
          this.showRetry();
        }
      },
      showRetry() {
        this.wsShowTip = true;
        this.wsShowRetry = true;
      },
      hasFault(faultCode) {
        return this.monitorData.faultCodes.indexOf(faultCode) !== -1;
      },
      initWsEvent() {
        this.ws.onopen = () => {
          this.wsShowTip = true;
          this.wsTipText = "等待数据...";
          let authProto = {
            t: "B03",
            d: {regCode: this.elevator.regNo},
          };
          this.ws.send(JSON.stringify(authProto)); // 发送认证信息
        };
        this.ws.onclose = () => {
          this.showRetry();
        };
        this.ws.onerror = () => {
          this.wsTipText = "出错";
          this.showRetry();
        };
        this.ws.onmessage = (event) => {
          try {
            let proto = JSON.parse(event.data);
            console.log(proto);
            let type = proto.t;
            switch (type) {
              case "A06":
                this.wsShowTip = true;
                this.wsTipText = "缓存中未找到该电梯";
                break;
              case "A08":
                this.wsShowTip = true;
                this.wsTipText = "监控数据超时";
                break;
              case "A10":
                this.wsShowTip = true;
                this.wsTipText = "主板离线";
                break;
              case "A11":
                this.wsShowTip = true;
                this.wsTipText = "网关离线";
                break;
              case "HF":
                this.hf.dataSwitch = [...proto.d.dataSwitch];
                this.hf.dataLogic = [...proto.d.dataLogic];
                this.hf.signalStatus = [...proto.d.signalStatus];
                break;
              case "HG":
                this.monitorData = JSON.parse(JSON.stringify(proto.d));
                let data = proto.d;
                this.monitorData.s1_mode_desc = s1_mode[data.s1_mode] || "--";
                this.monitorData.s1_run_desc = s1_run[data.s1_run] || "--";
                this.monitorData.s1_direction_desc = s1_direction[data.s1_direction] || "--";

                let mode = data.s1_mode;
                if (mode !== 1) {
                  this.wsShowTip = true;
                  this.wsTipText = this.monitorData.s1_mode_desc;
                } else if (this.monitorData.faultCodes && this.monitorData.faultCodes.length) {
                  this.wsShowTip = true;
                  this.wsTipText = "发生故障";
                } else {
                  this.wsShowTip = false;
                }
                break;
              case "HH":
                this.hh = proto.d;
                break;
            }
          } catch (e) {
            console.log(e);
          }
        };
      },
      set(value) {
        let param = {
          dtuCode: this.elevator.dtuCode,
          nodeCode: this.elevator.nodeCode,
          protoType: "HI",
        };
        switch (value) {
          case 201:
          case 200:
            this.$confirm(`确定${value === 201 ? "打开" : "关闭"}检验模式吗?`, "提示").then(() => {
              param.commandType = 2;
              param.value = (value === 201 ? 1 : 0);
              this.$api.getData("remote/sendHDOrHI", param).then(() => {
                this.$message.success("命令发送成功");
              });
            });
            break;
          case 6:
            this.$confirm("确定重启采集盒吗?", "提示").then(() => {
              param.commandType = 6;
              this.$api.getData("remote/sendHDOrHI", param).then(() => {
                this.$message.success("命令发送成功");
              });
            });
            break;
          case 3:
          case 4:
          case 5:
          case 7:
          case 8:
            this.$refs.cmdHD.open(value, this.hf);
            break;
        }
      },
      sendHH() {
        this.$api.getData("remote/sendHH", {
          dtuCode: this.elevator.dtuCode,
          nodeCode: this.elevator.nodeCode,
        }).then(response => {
          this.$message.success("查询命令发送成功");
        });
      },
      onTabClick(tab) {
        if (tab.name === "tabStats") {
          this.sendHH();
        }
      },
      getTdClass(index) {
        if (this.hf.dataSwitch[index] === "0") {
          return "gray";
        }
        return this.hf.signalStatus[index] === "1" ? "green" : "blue";
      },
    },
  };
</script>
<style lang="scss" scoped>
.escalator-monitor {
  background-color: black;
  width: 100%;
  height: 100vh;
  min-height: 750px;
  padding-top: 20px;
  box-sizing: border-box;

  .em-wrapper {
    height: 645px;
    width: 1200px;
    margin: 0 auto;
    background: url(/static/images/escalatorMonitor/bg.jpg) no-repeat center;
    background-size: cover;
    position: relative;

    .em-title {
      background-color: #fcfcfc;
      padding: 12px 15px;
      font-size: 15px;
    }

    .em-status {
      position: absolute;
      padding: 20px 30px;
      width: 85px;
      color: white;
      top: 0;
      left: 0;
      font-size: 35px;
      text-align: center;
      background-color: #0288D1;
    }

    .em-monitor {
      position: absolute;
      width: 535px;
      left: 10px;
      top: 80px;
      height: 100%;
      background: url(/static/images/escalatorMonitor/animate.png) no-repeat 0 0;
    }

    .em-monitor-run {
      animation: monitor-animate 0.6s step-start infinite;
      -moz-animation: monitor-animate 0.6s step-start infinite;
    }

    .em-monitor-slow {
      animation-duration: 2s;
      -moz-animation-duration: 2s;
    }

    .em-monitor-down {
      animation-direction: reverse;
      -moz-animation-direction: reverse;
    }

    .em-connect-status-wrapper {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;

      .em-connect-status {
        position: absolute;
        top: 40%;
        left: 14%;
        transform: translate(-14%, -40%);
        text-align: center;

        .tip {
          display: flex;
          align-items: center;

          .icon {
            width: 60px;
            background-color: rgba(164, 243, 145, 0.1);
            padding: 5px;
            border: 1px solid white;
            border-radius: 50%;
          }

          .text {
            color: white;
            font-size: 30px;
            margin-left: 10px;
          }
        }

        .retry {
          margin-top: 20px;
        }
      }

    }

    .em-base-info-right {
      margin-left: 170px;
      font-size: 13px;
      color: #555;
      padding: 12px 10px 0 10px;

      td {
        max-width: 138px;
        min-width: 110px;
        padding: 4px 2px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      th {
        padding: 4px 2px;
        font-weight: normal;
        color: #333;
        white-space: nowrap;
      }
    }

    .em-detail {
      position: absolute;
      background-color: white;
      left: 500px;
      right: 0;
      top: 0;
      bottom: 0;

      .tab {
        margin-top: 10px;

        .info-item {
          padding: 10px;
          border-bottom: 1px dotted #ccc;
          font-size: 13px;
          color: #333;

          .title {
            color: #888;
          }
        }
      }
    }

    @keyframes monitor-animate {
      0% {
        background-position: 0 0;
      }
      12.5% {
        background-position: -535px 0;
      }
      25% {
        background-position: -1070px 0;
      }
      37.5% {
        background-position: -1605px 0;
      }
      50% {
        background-position: -2140px 0;
      }
      62.5% {
        background-position: -2675px 0;
      }
      75% {
        background-position: -3210px 0;
      }
      87.5% {
        background-position: -3745px 0;
      }
      100% {
        background-position: 0 0;
      }
    }
  }

  .title-icon {
    position: absolute;
    background-color: #0288D1;
    border-radius: 5px;
    height: 5px;
    width: 5px;
    left: 10px;
    top: 13px;
  }

  .iconfont {
    font-size: 25px;
  }

  .cell-title {
    margin-top: 4px;
  }

  .cell-value {
    display: inline-block;
    margin-top: 8px;
    padding: 3px 5px;
    background-color: #03A9F4;
    color: white;
    border-radius: 1px;
    font-size: 11px;
  }

  //普通table使用
  table.vm-table {
    width: 100%;
    font-size: 13px;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: unset;
    border-top: #eee 1px solid;
    border-right: #eee 1px solid;

    th, td {
      border-left: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }

    th {
      color: #555;
      padding: 9px 20px;
      background-color: #fff;
      text-align: left;
      font-weight: normal;
      position: relative;
    }

    td {
      color: #666;
      padding: 11px 5px;
      background-color: #ffffff;
      text-align: center;
    }

    td.selected {
      background-color: #E91E63;
      color: white
    }
  }

  table.single-table {
    td.gray {
      background-color: #ddd;
      height: 15px;
    }

    td.blue {
      background-color: #409EFF;
      color: white
    }

    td.green {
      background-color: #67C23A;
      color: white
    }
  }
}
</style>
