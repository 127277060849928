<template>
  <div class="mainContent liftMonitor">
    <div class="header">
      <img class="large" src="../../assets/log.png" style="width:140px;height:40px"/>
      <div>电梯监控 /</div>
      <div>单梯监控 /</div>
      <div @click="goToOld">{{elevatorInfo.elevator.no}}</div>
    </div>
    <div class="main">
      <!-- 模型图 -->
      <div class="mainLeft">
        <div
          v-if="elevatorInfo.elevator.no === 'DT00015144'"
          class="elevatorName"
          style="text-align: center;font-weight: 700;">
          <div style="font-size: 70px;">新时达</div>
          <div>数字化电梯改造方案</div>
        </div>
        <div v-else class="elevatorName" style="font-size: 20px;">
          {{elevatorInfo.elevator.name}}
        </div>
        <div class="model">
          <div class="modelImg">
            <img class="large" src="../../assets/elevator.png" style="max-width: 100%;max-height: 100%;"/>
          </div>
        </div>
        <div :class="['toggle', toggleModalShow ? 'toggleActive' : '']" @click="toggleModalShow = !toggleModalShow">
          切换
        </div>
        <div v-if="toggleModalShow" class="toggleModal">
          <ul>
            <li
              v-for="item in useUnitElevators"
              :key="item.id"
              :style="{ color: item.id === elevatorInfo.elevator.id ? '#0747fd' : '' }"
              @click="changeElevator(item.id)">
              {{
                item.name + " (" +
                item.no + ")"
              }}
            </li>
          </ul>
        </div>
      </div>
      <!-- 电梯信息 -->
      <div class="mainRight">
        <div class="mainRightContent">
          <div class="contentTop">
            <div class="contentLeft">
              <!-- 实时数据 -->
              <div class="liveData module">
                <div class="moduleTitle">
                  <div class="leftLine"></div>
                  <div class="title">实时数据</div>
                  <div class="right">
                    <div v-if="connectState === '连接正常'">
                      <i class="el-icon-link"></i>
                      <span style="margin-left:3px">{{connectState}}</span>
                    </div>
                    <div v-else-if="connectState === '连接断开'" style="color:#FFB22C">
                      <i class="el-icon-paperclip"></i>
                      <span style="margin-left:3px">{{connectState}}</span>
                    </div>
                    <div v-else>{{connectState}}</div>
                  </div>
                </div>
                <div class="moduleContent">
                  <div class="liveDataItem">
                    <div class="liveDataTitle">运行状态</div>
                    <div class="liveDataContent">{{currenStatus ? currenStatus : "-"}}</div>
                  </div>
                  <div class="liveDataItem">
                    <div class="liveDataTitle">当前楼层</div>
                    <div class="liveDataContent">
                      {{webSocketInfo.Car_Position ? webSocketInfo.Car_Position : "-"}}
                    </div>
                  </div>
                  <div class="liveDataItem">
                    <div class="liveDataTitle">运行方向</div>
                    <div v-if="connectState === '连接正常'" class="liveDataContent">
                      <div v-if="webSocketInfo.Car_Status === 0">
                        <div v-if="webSocketInfo.Car_Direction === 1" style="display:flex;align-items: center;">
                          <img src="/static/images/elevator/up1.png" style="width:14px;height:20px;margin-right: 3px;"/>
                          <span>上行</span>
                        </div>
                        <div v-else-if="webSocketInfo.Car_Direction === 2" style="display:flex;align-items: center;">
                          <img
                            src="/static/images/elevator/down1.png"
                            style="width:14px;height:20px;margin-right: 3px;"/>
                          <span>下行</span>
                        </div>
                        <div v-else>-</div>
                      </div>
                      <div v-else>
                        <div v-if="webSocketInfo.Car_Direction === 1" style="display:flex;align-items: center;">
                          <img src="../../assets/up.gif" style="width:14px;height:20px;margin-right: 3px;"/>
                          <span>上行</span>
                        </div>
                        <div v-else-if="webSocketInfo.Car_Direction === 2" style="display:flex;align-items: center;">
                          <img src="../../assets/down.gif" style="width:14px;height:20px;margin-right: 3px;"/>
                          <span>下行</span>
                        </div>
                      </div>
                    </div>
                    <div v-else class="liveDataContent"> -</div>
                  </div>
                  <div class="liveDataItem">
                    <div class="liveDataTitle">运行速度</div>
                    <div class="liveDataContent" v-if="webSocketInfo.Run_Speed === '-1'">
                      {{currentSpeed ? (currentSpeed / 100).toFixed(2) + "m/s" : "-"}}
                    </div>
                    <div class="liveDataContent" v-else>
                      {{(webSocketInfo.Run_Speed / 1000).toFixed(2) + "m/s"}}
                    </div>
                  </div>
                  <div class="liveDataItem">
                    <div class="liveDataTitle">门状态</div>
                    <div v-if="connectState === '连接正常'" class="liveDataContent">
                      {{
                        webSocketInfo.Door_Switch_Status ===
                        1 ? "开门" : "关门"
                      }}
                    </div>
                    <div v-else class="liveDataContent"> -</div>
                  </div>
                  <div class="liveDataItem borderBottomNone">
                    <div class="liveDataTitle floorAction">上召</div>
                    <div class="liveDataContent">
                      <span v-for="item in webSocketInfo.Outer_Up_Orders" :key="item" class="floor">{{item}}</span>
                    </div>
                  </div>
                  <div class="liveDataItem borderBottomNone">
                    <div class="liveDataTitle floorAction">下召</div>
                    <div class="liveDataContent">
                      <span v-for="item in webSocketInfo.Outer_Down_Orders" :key="item" class="floor">{{item}}</span>
                    </div>
                  </div>
                  <div class="liveDataItem borderBottomNone">
                    <div class="liveDataTitle floorAction">轿内</div>
                    <div class="liveDataContent">
                      <span v-for="item in webSocketInfo.Inner_Orders" :key="item" class="floor">{{item}}</span>
                    </div>
                  </div>
                  <!-- 重新连接 -->
                  <div v-if="connectState === '连接断开'" class="reconnect">
                    <div class="reconnectBtn" @click="createWs()">重新连接</div>
                  </div>
                </div>
              </div>
              <!-- 电梯状态 -->
              <div class="elevatorStatus module">
                <div class="moduleTitle">
                  <div class="leftLine"></div>
                  <div class="title">电梯状态</div>
                </div>
                <div class="moduleContent">
                  <el-radio v-model="webSocketInfo.Service_Mode" :label="1" disabled>自动</el-radio>
                  <el-radio v-model="webSocketInfo.Group_Control" :label="true" disabled>并联/群控</el-radio>
                  <el-radio v-model="webSocketInfo.Fault_Status" :label="true" disabled>故障</el-radio>
                  <el-radio v-model="webSocketInfo.Service_Mode" :label="2" disabled>检修</el-radio>
                  <el-radio v-model="webSocketInfo.Load_Status" :label="2" disabled>超载</el-radio>
                  <el-radio v-model="webSocketInfo.Load_Status" :label="1" disabled>满载</el-radio>
                  <el-radio v-model="webSocketInfo.Safety_Circuit_Status" :label="0" disabled>安全回路断</el-radio>
                  <el-radio v-model="webSocketInfo.Service_Mode" :label="4" disabled>消防员</el-radio>
                  <el-radio v-if="false" v-model="webSocketInfo.Fire_Run" :label="1" disabled>消防员</el-radio>
                  <el-radio v-model="webSocketInfo.Service_Mode" :label="11" disabled>独立</el-radio>
                  <el-radio v-model="webSocketInfo.Service_Mode" :label="10" disabled>锁梯</el-radio>
                  <el-radio v-model="webSocketInfo.Service_Mode" :label="12" disabled>司机</el-radio>
                  <el-radio v-model="webSocketInfo.Fire_Return" :label="1" disabled>消防返回</el-radio>
                  <el-radio v-model="webSocketInfo.Earthquake_Model" :label="1" disabled>地震模式</el-radio>
                  <el-radio v-model="webSocketInfo.Emergency_Power" :label="1" disabled>应急电源</el-radio>
                  <el-radio v-model="webSocketInfo.Serviceable" :label="1" disabled>电梯可服务</el-radio>
                  <el-radio v-model="webSocketInfo.Open_Button" :label="1" disabled>开门按钮</el-radio>
                  <!-- <el-radio v-model="webSocketInfo.Net_Ctrl" :label="1" disabled>联网控制</el-radio> -->
                  <el-radio v-model="webSocketInfo.Door_Zone" :label="true" disabled>开锁区域</el-radio>
                  <el-radio v-model="webSocketInfo.Door_Open_Status" :label="true" disabled>开门到位</el-radio>
                  <el-radio v-model="webSocketInfo.Door_Status" :label="true" disabled>关门到位</el-radio>
                  <el-radio v-model="webSocketInfo.Passenger_Status" :label="true" disabled>轿内有人</el-radio>
                  <el-radio v-model="webSocketInfo.Service_Mode" :label="7" disabled>当前服务模式未知</el-radio>
                  <el-radio v-model="webSocketInfo.Door_Switch_Status" :label="0" disabled>门锁回路</el-radio>
                  <el-radio v-model="webSocketInfo.Door_Lock_Status" :label="1" disabled>厅门锁止</el-radio>
                  <el-radio v-model="webSocketInfo.Car_Status" :label="1" disabled>制动器提起</el-radio>
                  <el-radio v-model="webSocketInfo.Car_Status" :label="0" disabled>制动器释放</el-radio>
                </div>
              </div>
            </div>
            <!-- 电梯概况到故障记录 -->
            <div class="contentRight module">
              <el-tabs>
                <el-tab-pane label="电梯概况">
                  <div class="moduleContent elevatorProduct">
                    <div v-if="heathIndexShow" class="healthIndex">
                      <div class="number">{{heathIndex}}</div>
                      <div class="title">健康指数</div>
                    </div>
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">电梯名称</div>
                      <div class="elevatorInfoRight">
                        {{elevatorInfo.elevator.name ? elevatorInfo.elevator.name : "-"}}
                      </div>
                    </div>
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">电梯编号</div>
                      <div class="elevatorInfoRight">{{elevatorInfo.elevator.no ? elevatorInfo.elevator.no : "-"}}</div>
                    </div>
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">设备代码</div>
                      <div class="elevatorInfoRight">
                        {{elevatorInfo.elevator.regNo ? elevatorInfo.elevator.regNo : "-"}}
                      </div>
                    </div>
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">电梯品牌</div>
                      <div class="elevatorInfoRight">
                        {{elevatorInfo.elevator.brand ? elevatorInfo.elevator.brand : "-"}}
                      </div>
                    </div>
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">电梯规格型号</div>
                      <div class="elevatorInfoRight">
                        {{elevatorInfo.elevator.model ? elevatorInfo.elevator.model : "-"}}
                      </div>
                    </div>
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">梯种</div>
                      <div class="elevatorInfoRight">
                        {{
                          elevatorInfo.elevator.elevatorProductTypeName ?
                            elevatorInfo.elevator.elevatorProductTypeName : "-"
                        }}
                      </div>
                    </div>
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">下次年检时间</div>
                      <div class="elevatorInfoRight">
                        {{
                          elevatorInfo.nextYearCheckDate ? elevatorInfo.nextYearCheckDate :
                            "-"
                        }}
                      </div>
                    </div>
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">下次维保时间</div>
                      <div class="elevatorInfoRight">
                        {{
                          elevatorInfo.nextMaintRecordDate ? elevatorInfo.nextMaintRecordDate
                            :
                            "-"
                        }}
                      </div>
                    </div>
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">电梯地址</div>
                      <div class="elevatorInfoRight">
                        {{
                          elevatorInfo.elevator.address ? elevatorInfo.elevator.address : "-"
                        }}
                      </div>
                    </div>
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">使用单位</div>
                      <div class="elevatorInfoRight">
                        {{
                          elevatorInfo.elevator.useUnitName ?
                            elevatorInfo.elevator.useUnitName
                            : "-"
                        }}
                      </div>
                    </div>
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">维保企业</div>
                      <div class="elevatorInfoRight">
                        {{
                          elevatorInfo.elevator.maintComName ? elevatorInfo.elevator.maintComName : "-"
                        }}
                      </div>
                    </div>
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">联系人</div>
                      <div class="elevatorInfoRight">
                        {{
                          elevatorInfo.elevator.maintEmpName ? elevatorInfo.elevator.maintEmpName : "-"
                        }}
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="运行参数">
                  <div class="moduleContent runParams">
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">电梯运行次数</div>
                      <div class="elevatorInfoRight">
                        {{
                          webSocketInfo.Present_Counter_Value !== -1 ?
                            webSocketInfo.Present_Counter_Value + "次" : "-"
                        }}
                      </div>
                    </div>
                    <!-- <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">安全触板动作次数</div>
                      <div class="elevatorInfoRight">{{
                          webSocketInfo.Safe_Touch_Action_Times !== -1
                            ? webSocketInfo.Safe_Touch_Action_Times + "次" : "-"
                        }}
                      </div>
                    </div> -->
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">电梯运行时间</div>
                      <div class="elevatorInfoRight">
                        {{
                          webSocketInfo.Total_Running_Time ?
                            getHM(webSocketInfo.Total_Running_Time * 60) : "-"
                        }}
                      </div>
                    </div>
                    <!-- <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">光幕动作次数</div>
                      <div class="elevatorInfoRight">{{
                          webSocketInfo.Light_Action_Times !== -1
                            ? webSocketInfo.Light_Action_Times + "次" : "-"
                        }}
                      </div>
                    </div> -->
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">钢丝绳运行弯折次数</div>
                      <div class="elevatorInfoRight">
                        {{
                          webSocketInfo.Wirerope_Bending_Times !== -1 ?
                            webSocketInfo.Wirerope_Bending_Times + "次" : "-"
                        }}
                      </div>
                    </div>
                    <!-- <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">轿内照明点灯次数</div>
                      <div class="elevatorInfoRight">{{
                          webSocketInfo.Car_Light_Times !== -1
                            ? webSocketInfo.Car_Light_Times + "次" : "-"
                        }}
                      </div>
                    </div> -->
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">电梯开关门次数</div>
                      <div class="elevatorInfoRight">
                        {{
                          webSocketInfo.Door_Open_Close_Times !== -1
                            ? webSocketInfo.Door_Open_Close_Times + "次" : "-"
                        }}
                      </div>
                    </div>
                    <!-- <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">轿内照明点灯时间</div>
                      <div class="elevatorInfoRight">{{
                          webSocketInfo.Car_Light_Running_Time !== -1
                            ? getHMS(webSocketInfo.Car_Light_Running_Time) : "-"
                        }}
                      </div>
                    </div> -->
                    <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">电梯运行距离</div>
                      <div class="elevatorInfoRight">
                        {{
                          webSocketInfo.Total_Run_Distance ?
                            (webSocketInfo.Total_Run_Distance / 1000).toFixed(2) + "千米" : "-"
                        }}
                      </div>
                    </div>
                    <!-- <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">上行再平层次数</div>
                      <div class="elevatorInfoRight">{{
                          webSocketInfo.Upward_Releveling_Times !== -1
                            ? webSocketInfo.Upward_Releveling_Times + "次" : "-"
                        }}
                      </div>
                    </div> -->
                    <!-- <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">基站层开门次数</div>
                      <div class="elevatorInfoRight">{{
                          webSocketInfo.Reference_Floor_Open_Times !== -1
                            ? webSocketInfo.Reference_Floor_Open_Times + "次" : "-"
                        }}
                      </div>
                    </div> -->
                    <!-- <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">下行再平层次数</div>
                      <div class="elevatorInfoRight">{{
                          webSocketInfo.Down_Releveling_Times !== -1
                            ? webSocketInfo.Down_Releveling_Times + "次" : "-"
                        }}
                      </div>
                    </div> -->
                    <!-- <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">门开次数</div>
                      <div class="elevatorInfoRight">{{
                          webSocketInfo.Door_Open_Times !== -1
                            ? webSocketInfo.Door_Open_Times + "次" : "-"
                        }}
                      </div>
                    </div> -->
                    <!-- <div class="elevatorInfo">
                      <div class="elevatorInfoLeft">运行速度</div>
                      <div class="elevatorInfoRight">{{ webSocketInfo.Run_Speed !== '-1' ? webSocketInfo.Run_Speed +
                        'm/s' : '-' }}
                      </div>
                    </div> -->
                  </div>
                </el-tab-pane>
                <el-tab-pane label="安全隐患">
                  <div  v-loading="heathIndexLoading" class="moduleContent">
                    <div v-if="heathIndexShow" style="height: 100%;">
                      <div v-if="potentialFaults.length" style="height: 100%;">
                        <div class="dangersNum">
                          <span style="color: #FF4B00 ;">
                            <span style="font-size: 30px;">{{potentialFaults.length}}</span>
                            <span>项</span>
                          </span>
                          <span style="margin-left: 32px;color: #F1A118;">电梯云为您检测到以下故障隐患，建议重点关注，及时消除隐患：</span>
                        </div>
                        <div class="dangersList">
                          <ul>
                            <li v-for="(item,index) in potentialFaults" :key="index">{{index + 1 + "、" + item}}</li>
                          </ul>
                        </div>
                      </div>
                      <div v-else style="height: 100%;">
                        <div class="dangersNum" style="background-color: #EBFAF6; color: #00C291;">
                          <span>
                            <span style="font-size: 30px;">0</span>
                            <span>项</span>
                          </span>
                          <span style="margin-left: 32px;">太棒了，您的电梯很健康！</span>
                        </div>
                        <div class="dangersList" style="text-align: center;padding-left: 0;margin-top: 50px;">
                          <img src="../../assets/zan.png" style="width:150px;"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="事件记录">
                  <div class="moduleContent" style="padding:6px 0">
                    <table
                      v-if="elevatorInfo.deviceEvenVos.length"
                      class="workOrderRecordVos"
                      cellspacing="0"
                      cellpadding="0">
                      <tr>
                        <td style="width:160px;text-align: center;">发生时间</td>
                        <td style="width:80px;text-align: center;">事件名称</td>
                      </tr>
                      <tr v-for="(item, index) in elevatorInfo.deviceEvenVos" :key="index">
                        <td style="width:80px;text-align: center;">{{item.eventTime}}</td>
                        <td style="width:160px;text-align: center;">{{item.eventName}}</td>
                      </tr>
                    </table>
                    <el-empty v-else></el-empty>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="故障记录">
                  <div class="moduleContent" style="padding:6px 0">
                    <table
                      v-if="elevatorInfo.faultRecordVos.length"
                      class="workOrderRecordVos"
                      cellspacing="0"
                      cellpadding="0">
                      <tr>
                        <td>故障描述</td>
                        <td style="width:80px;text-align: center;">故障代码</td>
                        <td style="width:160px;text-align: center;">故障时间</td>
                      </tr>
                      <tr v-for="(item, index) in elevatorInfo.faultRecordVos" :key="index">
                        <td>{{item.faultDesc}}</td>
                        <td style="width:80px;text-align: center;">{{item.faultCode}}</td>
                        <td style="width:160px;text-align: center;">{{item.faultTime}}</td>
                      </tr>
                    </table>
                    <el-empty v-else></el-empty>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="急修维保记录">
                  <div class="moduleContent" style="padding:6px 0;border-radius: 12px">
                    <div class="history" style="height: 100%;display: flex;">
                      <div class="repairRecord" style="flex: 1;">
                        <el-table :data="elevatorInfo.workOrderRecordVos" style="width: 98%;">
                          <el-table-column prop="workOrderNo" label="工单编号" align="center"></el-table-column>
                          <el-table-column prop="alarmTime" label="报警时间" align="center"></el-table-column>
                        </el-table>
                      </div>
                      <div style="width: 48px;"></div>
                      <div class="maintenRecord" style="flex: 1;">
                        <div class="repairRecord" style="flex: 1;">
                          <el-table :data="elevatorInfo.maintRecordVos" style="width: 98%;">
                            <el-table-column prop="maintDate" label="保养日期" align="center"></el-table-column>
                            <el-table-column prop="maintStatus" label="状态" align="center">
                              <template slot-scope="scope">
                                <el-tag v-if="scope.row.maintStatus === 0" type="danger">
                                  {{$l("maintWorkOrder.invalid", "作废")}}
                                </el-tag>
                                <el-tag v-else-if="scope.row.maintStatus === 1" type="warning">
                                  {{$l("maintWorkOrder.inPlan", "计划中")}}
                                </el-tag>
                                <el-tag v-else-if="scope.row.maintStatus === 2">
                                  {{$l("maintWorkOrder.notAccepted", "未接受")}}
                                </el-tag>
                                <el-tag v-else-if="scope.row.maintStatus === 3">
                                  {{$l("maintWorkOrder.accepted", "已接受")}}
                                </el-tag>
                                <el-tag v-else-if="scope.row.maintStatus === 4" type="info">
                                  {{$l("maintWorkOrder.signedIn", "已签到")}}
                                </el-tag>
                                <el-tag v-else-if="scope.row.maintStatus === 5" type="success">
                                  {{$l("maintWorkOrder.completed", "已完成")}}
                                </el-tag>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane v-if="elevatorInfo.elevator.faultMark === 'step'" label="IO 端口">
                  <div class="moduleContent">
                    <div class="portTitle">主控制板输入口状态</div>
                    <div>
                      <el-radio v-model="a4.X0" :label="true" disabled>X0 检修1</el-radio>
                      <el-radio v-model="a4.X1" :label="true" disabled>X1 检修2</el-radio>
                      <el-radio v-model="a4.X2" :label="true" disabled>X2 检修上行</el-radio>
                      <el-radio v-model="a4.X3" :label="true" disabled>X3 检修下行</el-radio>
                      <el-radio v-model="a4.X4" :label="true" disabled>X4 上限位</el-radio>
                      <el-radio v-model="a4.X5" :label="true" disabled>X5 下限位</el-radio>
                      <el-radio v-model="a4.X6" :label="true" disabled>X6 上平层</el-radio>
                      <el-radio v-model="a4.X7" :label="true" disabled>X7 下平层</el-radio>
                      <el-radio v-model="a4.X8" :label="true" disabled>X8 上一减速</el-radio>
                      <el-radio v-model="a4.X9" :label="true" disabled>X9 下一减速</el-radio>
                      <el-radio v-model="a4.X10" :label="true" disabled>X10 上二减速</el-radio>
                      <el-radio v-model="a4.X11" :label="true" disabled>X11 下二减速</el-radio>
                      <el-radio v-model="a4.X12" :label="true" disabled>X12 上三减速</el-radio>
                      <el-radio v-model="a4.X13" :label="true" disabled>X13 下三减速</el-radio>
                      <el-radio v-model="a4.X14" :label="true" disabled>X14 上四减速</el-radio>
                      <el-radio v-model="a4.X15" :label="true" disabled>X15 下四减速</el-radio>
                      <el-radio v-model="a4.X16" :label="true" disabled>X16 门区开关</el-radio>
                      <el-radio v-model="a4.X17" :label="true" disabled>X17 开门再平层或提前开门继电器检测</el-radio>
                      <el-radio v-model="a4.X18" :label="true" disabled>X18 消防返回</el-radio>
                      <el-radio v-model="a4.X19" :label="true" disabled>X19 消防员</el-radio>
                      <el-radio v-model="a4.X20" :label="true" disabled>X20 调速器进线接触器检测</el-radio>
                      <el-radio v-model="a4.X21" :label="true" disabled>X21 调速器出线接触器检测</el-radio>
                      <el-radio v-model="a4.X22" :label="true" disabled>X22 抱闸接触器检测</el-radio>
                      <el-radio v-model="a4.X23" :label="true" disabled>X23 抱闸开关1</el-radio>
                      <el-radio v-model="a4.X24" :label="true" disabled>X24 抱闸开关2</el-radio>
                      <el-radio v-model="a4.X25" :label="true" disabled>X25 电动机过热保护</el-radio>
                      <el-radio v-model="a4.X26" :label="true" disabled>X26 安全回路继电器检测</el-radio>
                      <el-radio v-model="a4.X27" :label="true" disabled>X27 门锁回路继电器检测</el-radio>
                      <el-radio v-model="a4.X28" :label="true" disabled>X28 变频器运行信号检测</el-radio>
                      <el-radio v-model="a4.X29" :label="true" disabled>X29 变频器故障信号</el-radio>
                      <el-radio v-model="a4.X30" :label="true" disabled>X30 地震</el-radio>
                      <el-radio v-model="a4.X31" :label="true" disabled>X31 停电应急平层</el-radio>
                      <el-radio v-model="a4.X32" :label="true" disabled>X32 后备电源</el-radio>
                      <el-radio v-model="a4.X33" :label="true" disabled>X33 封星检测</el-radio>
                      <el-radio v-model="a4.X34" :label="true" disabled>X34 端站开关</el-radio>
                      <el-radio v-model="a4.X36" :label="true" disabled>X36 安全回路</el-radio>
                      <el-radio v-model="a4.X37" :label="true" disabled>X37 门锁回路</el-radio>
                      <el-radio v-model="a4.X38" :label="true" disabled>X38 厅门锁回路</el-radio>
                    </div>
                    <div class="portTitle">轿厢板输入口状态</div>
                    <div>
                      <el-radio v-model="a4.GX0" :label="true" disabled>GX0 前门开门按钮</el-radio>
                      <el-radio v-model="a4.GX1" :label="true" disabled>GX1 前门关门按钮</el-radio>
                      <el-radio v-model="a4.GX2" :label="true" disabled>GX2 前门开门保持按钮</el-radio>
                      <el-radio v-model="a4.GX3" :label="true" disabled>GX3 后门开门按钮</el-radio>
                      <el-radio v-model="a4.GX4" :label="true" disabled>GX4 后门关门按钮</el-radio>
                      <el-radio v-model="a4.GX5" :label="true" disabled>GX5 后门开门保持按钮</el-radio>
                      <el-radio v-model="a4.GX8" :label="true" disabled>GX8 司机换向</el-radio>
                      <el-radio v-model="a4.GX9" :label="true" disabled>GX9 司机</el-radio>
                      <el-radio v-model="a4.GX10" :label="true" disabled>GX10 独立</el-radio>
                      <el-radio v-model="a4.GX11" :label="true" disabled>GX11 直驶</el-radio>
                      <el-radio v-model="a4.GX12" :label="true" disabled>GX12 消防员</el-radio>
                      <el-radio v-model="a4.GX13" :label="true" disabled>GX13 非服务层设置</el-radio>
                      <el-radio v-model="a4.GX14" :label="true" disabled>GX14 前门选择开关</el-radio>
                      <el-radio v-model="a4.GX15" :label="true" disabled>GX15 后门选择开关</el-radio>
                    </div>
                    <div class="portTitle">轿顶板输入口状态</div>
                    <div>
                      <el-radio v-model="a4.HX0" :label="true" disabled>HX0 前门开门到位</el-radio>
                      <el-radio v-model="a4.HX1" :label="true" disabled>HX1 前门关门到位</el-radio>
                      <el-radio v-model="a4.HX2" :label="true" disabled>HX2 前门堵转</el-radio>
                      <el-radio v-model="a4.HX3" :label="true" disabled>HX3 前门光幕</el-radio>
                      <el-radio v-model="a4.HX4" :label="true" disabled>HX4 前门安全触板</el-radio>
                      <el-radio v-model="a4.HX5" :label="true" disabled>HX5 后门开门到位</el-radio>
                      <el-radio v-model="a4.HX6" :label="true" disabled>HX6 后门关门到位</el-radio>
                      <el-radio v-model="a4.HX7" :label="true" disabled>HX7 后门堵转</el-radio>
                      <el-radio v-model="a4.HX8" :label="true" disabled>HX8 后门光幕</el-radio>
                      <el-radio v-model="a4.HX9" :label="true" disabled>HX9 后门安全触板</el-radio>
                      <el-radio v-model="a4.HX10" :label="true" disabled>HX10 超载</el-radio>
                      <el-radio v-model="a4.HX11" :label="true" disabled>HX11 满载</el-radio>
                      <el-radio v-model="a4.HX12" :label="true" disabled>HX12 轻载</el-radio>
                    </div>
                    <div class="portTitle">主控制板输出口状态</div>
                    <div>
                      <el-radio v-model="a4.Y0" :label="true" disabled>Y0 抱闸接触器输出</el-radio>
                      <el-radio v-model="a4.Y1" :label="true" disabled>Y1 抱闸强激接触器输出</el-radio>
                      <el-radio v-model="a4.Y2" :label="true" disabled>Y2 调速器进线接触器输出</el-radio>
                      <el-radio v-model="a4.Y3" :label="true" disabled>Y3 调速器出线接触器输出</el-radio>
                      <el-radio v-model="a4.Y4" :label="true" disabled>Y4 提前开门输出</el-radio>
                      <el-radio v-model="a4.Y5" :label="true" disabled>Y5 消防指示</el-radio>
                      <el-radio v-model="a4.Y6" :label="true" disabled>Y6 停电应急平层完成</el-radio>
                      <el-radio v-model="a4.Y8" :label="true" disabled>Y8 前门开门</el-radio>
                      <el-radio v-model="a4.Y9" :label="true" disabled>Y9 前门关门</el-radio>
                      <el-radio v-model="a4.Y10" :label="true" disabled>Y10 前门强迫关门</el-radio>
                      <el-radio v-model="a4.Y11" :label="true" disabled>Y11 后门开门</el-radio>
                      <el-radio v-model="a4.Y12" :label="true" disabled>Y12 后门关门</el-radio>
                      <el-radio v-model="a4.Y13" :label="true" disabled>Y13 后门强迫关门</el-radio>
                      <el-radio v-model="a4.Y16" :label="true" disabled>Y16 调速器上行方向</el-radio>
                      <el-radio v-model="a4.Y17" :label="true" disabled>Y17 调速器下行方向</el-radio>
                      <el-radio v-model="a4.Y18" :label="true" disabled>Y18 调速器运行使能</el-radio>
                      <el-radio v-model="a4.Y19" :label="true" disabled>Y19 调速器多段速端口1</el-radio>
                      <el-radio v-model="a4.Y20" :label="true" disabled>Y20 调速器多段速端口2</el-radio>
                      <el-radio v-model="a4.Y21" :label="true" disabled>Y21 调速器多段速端口3</el-radio>
                      <el-radio v-model="a4.Y22" :label="true" disabled>Y22 调速器多段速端口4</el-radio>
                    </div>
                    <div class="portTitle">轿厢板输出口状态</div>
                    <div>
                      <el-radio v-model="a4.GY0" :label="true" disabled>GY0 前门开门按钮灯</el-radio>
                      <el-radio v-model="a4.GY1" :label="true" disabled>GY1 前门关门按钮灯</el-radio>
                      <el-radio v-model="a4.GY2" :label="true" disabled>GY2 前门开门保持按钮灯</el-radio>
                      <el-radio v-model="a4.GY3" :label="true" disabled>GY3 后门开门按钮灯</el-radio>
                      <el-radio v-model="a4.GY4" :label="true" disabled>GY4 后门关门按钮灯</el-radio>
                      <el-radio v-model="a4.GY5" :label="true" disabled>GY5 后门开门保持按钮灯</el-radio>
                    </div>
                    <div class="portTitle">轿顶板输出口状态</div>
                    <div>
                      <el-radio v-model="a4.HY0" :label="true" disabled>HY0 前门开门</el-radio>
                      <el-radio v-model="a4.HY1" :label="true" disabled>HY1 前门关门</el-radio>
                      <el-radio v-model="a4.HY2" :label="true" disabled>HY2 前门强迫关门</el-radio>
                      <el-radio v-model="a4.HY3" :label="true" disabled>HY3 后门开门</el-radio>
                      <el-radio v-model="a4.HY4" :label="true" disabled>HY4 后门关门</el-radio>
                      <el-radio v-model="a4.HY5" :label="true" disabled>HY5 后门强迫关门</el-radio>
                      <el-radio v-model="a4.HY6" :label="true" disabled>HY6 照明</el-radio>
                      <el-radio v-model="a4.HY7" :label="true" disabled>HY7 风扇</el-radio>
                      <el-radio v-model="a4.HY8" :label="true" disabled>HY8 上到站钟</el-radio>
                      <el-radio v-model="a4.HY9" :label="true" disabled>HY9 下到站钟</el-radio>
                      <el-radio v-model="a4.HY10" :label="true" disabled>HY10 强蜂鸣器</el-radio>
                      <el-radio v-model="a4.HY11" :label="true" disabled>HY11 弱蜂鸣器</el-radio>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane v-if="editAuth" label="指令设置">
                  <div class="moduleContent instruct">
                    <div ref="div1">
                      <span style="margin-right: 20px;">{{$l("monitor.status.remoteLockElevator", "远程锁梯")}}</span>
                      <el-radio-group v-model="lockStatus" style="width: 120px;">
                        <el-radio :label="0">关</el-radio>
                        <el-radio :label="1">开</el-radio>
                      </el-radio-group>
                      <el-button :loading="lockLoading" type="text" @click="setLockStatus">设置</el-button>
                    </div>
                    <div ref="div2">
                      <span style="margin-right: 20px;">{{$l("monitor.status.networkControl", "联网控制")}}</span>
                      <el-radio-group v-model="isAC" style="width: 120px">
                        <el-radio :label="true">{{$l("monitor.status.Open", "开")}}</el-radio>
                        <el-radio :label="false">{{$l("monitor.status.Close", "关")}}</el-radio>
                      </el-radio-group>
                      <el-button :loading="radioLoading" type="text" @click="setAC">设置</el-button>
                    </div>
                    <div ref="div2">
                      <span style="margin-right: 20px;">{{$t("monitor.status.faultReset")}}</span>
                      <el-button type="text" :loading="resetLoading" @click="setRestElevatorFault">{{$t("event.reset")}}</el-button>
                    </div>
                    <div style="margin-top: 25px;height: 70%;" v-if="floorAuth">
                      <span style="margin-right: 20px;">{{$l("monitor.status.networkControl", "封层设置")}}</span>
                      <div style="height: 90%;">
                        <el-table
                          :data="tableData"
                          style="margin-top: 5px;"
                          height="100%"
                          max-height="300">
                          <el-table-column
                            prop="lockTime"
                            label="封锁时段"
                            width="100">
                          </el-table-column>
                          <el-table-column
                            prop="lockDisplayFloor"
                            label="封锁楼层"
                            width="120">
                          </el-table-column>
                          <el-table-column
                            align="center"
                            prop="status"
                            label="激活状态"
                            width="80">
                            <template v-slot="scope">
                              <el-tag v-if="scope.row.status === 1 " type="success">启用中</el-tag>
                              <el-tag v-if="scope.row.status === 0" type="danger">已禁用</el-tag>
                            </template>
                          </el-table-column>
                          <el-table-column
                            align="center"
                            prop="sendStatus"
                            label="发送状态"
                            width="80">
                            <template v-slot="scope">
                              <el-tag v-if="scope.row.sendStatus ===1" type="success">成功</el-tag>
                              <el-tag v-if="scope.row.sendStatus ===0" type="danger">失败</el-tag>
                              <el-tag v-if="scope.row.sendStatus ===-1" type="info">未发送</el-tag>
                            </template>
                          </el-table-column>
                          <el-table-column
                            align="center"
                            prop="sendTime"
                            label="发送时间"
                            width="150">
                          </el-table-column>

                          <el-table-column
                            align="center"
                            label="操作"
                            width="120"
                            fixed="right">
                            <template slot-scope="scope">
                              <el-button
                                type="text"
                                size="small"
                                @click.native.prevent="$refs.lockEdit.open(scope.row.elevatorId,scope.row)">
                                {{$t("common.edit")}}
                              </el-button>
                              <el-button
                                v-if="scope.row.status === 1"
                                type="text"
                                size="small"
                                @click.native.prevent="changeState(scope.row,0)">
                                {{$t("common.Disable")}}
                              </el-button>
                              <el-button
                                v-if="scope.row.status === 0"
                                type="text"
                                size="small"
                                @click.native.prevent="changeState(scope.row,1)">
                                {{$t("common.Enable")}}
                              </el-button>
                              <el-button
                                type="text"
                                class="operateDelBtn"
                                @click.native.prevent="deleteRow(scope.row)">
                                {{$t("common.remove")}}
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                        <lock-floor-edit ref="lockEdit"
                                        @save-success="getLockFloorList(elevatorInfo.elevator.id)"></lock-floor-edit>
                      </div>
                      <div style="height: 25px;">
                        <el-button type="text" @click="$refs.lockEdit.open(elevatorInfo.elevator.id,null)"> + 新增封锁规则
                        </el-button>
                        <span style="font-size: 12px;color: #909399">（未添加封锁规则的时段，电梯召唤无楼层限制）</span>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div class="contentBottom">
            <!-- 实时故障 -->
            <div class="contentLeft module">
              <div class="moduleTitle">
                <div class="leftLine"></div>
                <div class="title">实时故障</div>
              </div>
              <div v-if="webSocketInfo.Fault_Code" class="moduleContent liveFault">
                <div class="faultLeft">
                  <div>
                    <div style="text-align:center;">原始故障</div>
                    <div style="margin:40px 0 20px 0">
                      <span style="color:#808080;margin-right: 16px;">故障代码</span>
                      <span>{{webSocketInfo.Fault_Code}}</span>
                    </div>
                    <div>
                      <span style="color:#808080;margin-right: 16px;">故障描述</span>
                      <span>{{faultDescription}}</span>
                    </div>
                  </div>
                </div>
                <!-- DT00029639 -->
                <div class="faultRight">
                  <div style="text-align:center;">对应国标或地标信号</div>
                  <!-- 默纳克标准 -->
                  <div v-if="elevatorInfo.elevator.faultMark === 'monarch'">
                    <div
                      v-if="['1','2','3','4','5','6','7','8','9','12','13','14','15','18'].includes(webSocketInfo.Fault_Code)"
                      class="dmbFaultBox">
                      <span class="dmbFault">电梯变频器故障</span>
                    </div>
                    <div
                      v-if="['1','12','13','15','16','17','18','20','29','36','37','38','41','42','43','44','45','47','48','49','51','53','56','65','66'].includes(webSocketInfo.Fault_Code)"
                      class="dmbFaultBox">
                      <span class="dmbFault">不能再启动</span>
                    </div>
                    <div
                      v-if="['16','17','20','21','22','23','24','25','29','30','33','34','35','36','37','38','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','62','64','65','66'].includes(webSocketInfo.Fault_Code)"
                      class="dmbFaultBox">
                      <span class="dmbFault">电梯控制装置故障</span>
                    </div>
                    <div v-if="['9','12','13','15'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">主电源故障</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '14'" class="dmbFaultBox">
                      <span class="dmbFault">机房高温、散热器过热报警</span>
                    </div>
                    <div v-if="['11','19','39'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">电梯曳引机故障</span>
                    </div>
                    <div v-if="['16','17','20'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">编码器失效</span>
                    </div>
                    <div v-if="['22','46','50'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">平层不良</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '30'" class="dmbFaultBox">
                      <span class="dmbFault">楼层位置丢失</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '33'" class="dmbFaultBox">
                      <span class="dmbFault">超速</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '36'" class="dmbFaultBox">
                      <span class="dmbFault">运行接触器故障</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '37'" class="dmbFaultBox">
                      <span class="dmbFault">抱闸接触器故障</span>
                    </div>
                    <div v-if="['37','66'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">电梯制动系统故障</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '41'" class="dmbFaultBox">
                      <span class="dmbFault">安全回路断开</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '42'" class="dmbFaultBox">
                      <span class="dmbFault">运行中开门、运行时门锁回路断开</span>
                    </div>
                    <div v-if="['41','42'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">急停、轿厢在开锁区域外停止</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '43'" class="dmbFaultBox">
                      <span class="dmbFault">冲顶</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '44'" class="dmbFaultBox">
                      <span class="dmbFault">蹲底</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '46'" class="dmbFaultBox">
                      <span class="dmbFault">基准层非平层停梯报警</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '48'" class="dmbFaultBox">
                      <span class="dmbFault">开门故障</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '49'" class="dmbFaultBox">
                      <span class="dmbFault">关门故障</span>
                    </div>
                    <div v-if="['48','49'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">开关门故障</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '55'" class="dmbFaultBox">
                      <span class="dmbFault">电动机运转时间限制器动作</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '56'" class="dmbFaultBox">
                      <span class="dmbFault">反复开关门</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '65'" class="dmbFaultBox">
                      <span class="dmbFault">开门运行、轿厢意外移动</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '66'" class="dmbFaultBox">
                      <span class="dmbFault">制动器故障</span>
                    </div>
                  </div>
                  <!-- 奥立达_汇川标准 -->
                  <div v-if="elevatorInfo.elevator.faultMark === 'aolida_inovance'">
                    <div v-if="['41'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">电梯运行时安全回路断开</span>
                    </div>
                    <div v-if="['49'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">关门故障</span>
                    </div>
                    <div v-if="['48'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">开门故障</span>
                    </div>
                    <div v-if="['41'].includes(webSocketInfo.Fault_Code) && !webSocketInfo.Door_Zone"
                         class="dmbFaultBox">
                      <span class="dmbFault">轿厢在开锁区域外停止</span>
                    </div>
                    <div v-if="['65'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">轿厢意外移动</span>
                    </div>
                    <div v-if="['30'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">电动机运转时间限制器动作</span>
                    </div>
                    <div v-if="['22'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">楼层位置丢失</span>
                    </div>
                    <div v-if="['44'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">蹲底故障</span>
                    </div>
                    <div v-if="['43'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">冲顶故障</span>
                    </div>
                    <div v-if="['33'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">电梯超速</span>
                    </div>
                    <div v-if="['37','66'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">电梯制动系统故障</span>
                    </div>
                    <div
                      v-if="!['41','49','48','65','30','22','44','43','33','37','66'].includes(webSocketInfo.Fault_Code)"
                      class="dmbFaultBox">
                      <span class="dmbFault">防止电梯再运行故障</span>
                    </div>
                    <!--                    电梯运行时安全回路断开-->
                    <!--                    关门故障-->
                    <!--                    开门故障-->
                    <!--                    轿厢在开锁区域外停止-->
                    <!--                    轿厢意外移动-->
                    <!--                    电动机运转时间限制器动作-->
                    <!--                    楼层位置丢失-->
                    <!--                    蹲底故障-->
                    <!--                    冲顶故障-->
                    <!--                    防止电梯再运行故障-->

                  </div>
                  <!-- 新时达标准 -->
                  <div v-else>
                    <div
                      v-if="webSocketInfo.Fault_Code === '2' || webSocketInfo.Fault_Code === '32'"
                      class="dmbFaultBox">
                      <span class="dmbFault">轿厢在开锁区域外停止</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '2'" class="dmbFaultBox">
                      <span class="dmbFault">运行中开门</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '3'" class="dmbFaultBox">
                      <span class="dmbFault">冲顶</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '4'" class="dmbFaultBox">
                      <span class="dmbFault">蹲底</span>
                    </div>
                    <div v-if="['23', '87', '88', '100'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">超速</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '51'" class="dmbFaultBox">
                      <span class="dmbFault">轿厢意外移动</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '32'" class="dmbFaultBox">
                      <span class="dmbFault">安全回路断路</span>
                    </div>
                    <div
                      v-if="webSocketInfo.Fault_Code === '2' || webSocketInfo.Fault_Code === '37'"
                      class="dmbFaultBox">
                      <span class="dmbFault">层门锁回路故障</span>
                    </div>
                    <div
                      v-if="webSocketInfo.Fault_Code === '2' || webSocketInfo.Fault_Code === '37'"
                      class="dmbFaultBox">
                      <span class="dmbFault">轿门锁回路故障</span>
                    </div>
                    <div
                      v-if="parseInt(webSocketInfo.Fault_Code) >= 2 && parseInt(webSocketInfo.Fault_Code) <= 69"
                      class="dmbFaultBox">
                      <span class="dmbFault">电梯控制装置故障</span>
                    </div>
                    <div
                      v-if="['81', '86', '87', '88', '101', '105'].includes(webSocketInfo.Fault_Code)"
                      class="dmbFaultBox">
                      <span class="dmbFault">电梯曳引机故障</span>
                    </div>
                    <div
                      v-if="parseInt(webSocketInfo.Fault_Code) >= 71 && parseInt(webSocketInfo.Fault_Code) <= 120"
                      class="dmbFaultBox">
                      <span class="dmbFault">变频器故障</span>
                    </div>
                    <div
                      v-if="webSocketInfo.Fault_Code === '38' || webSocketInfo.Fault_Code === '35'"
                      class="dmbFaultBox">
                      <span class="dmbFault">电梯制动系统故障</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '54'" class="dmbFaultBox">
                      <span class="dmbFault">门锁电气装置短接</span>
                    </div>
                    <div v-if="['26', '27', '28', '30', '68'].includes(webSocketInfo.Fault_Code)" class="dmbFaultBox">
                      <span class="dmbFault">平层感应故障</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '30'" class="dmbFaultBox">
                      <span class="dmbFault">楼层位置丢失</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '20'" class="dmbFaultBox">
                      <span class="dmbFault">电动机运转时间限制器动作</span>
                    </div>
                    <div v-if="webSocketInfo.Fault_Code === '6'" class="dmbFaultBox">
                      <span class="dmbFault">关门故障</span>
                    </div>
                    <div
                      v-if="webSocketInfo.Fault_Code === '5' || webSocketInfo.Fault_Code === '37'"
                      class="dmbFaultBox">
                      <span class="dmbFault">开门故障</span>
                    </div>
                    <div
                      v-if="webSocketInfo.Fault_Code === '2' || webSocketInfo.Fault_Code === '32'"
                      class="dmbFaultBox">
                      <span class="dmbFault">急停</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="moduleContent">
                <el-empty image=""></el-empty>
              </div>
            </div>
            <!-- 统计图 -->
            <div class="contentRight">
              <div style="height:100%;display: flex;">
                <!-- 近七天运行统计 -->
                <div class="runCount module">
                  <div class="moduleTitle">
                    <div class="leftLine"></div>
                    <div class="title">近七天运行统计</div>
                  </div>
                  <div class="moduleContent">
                    <run-and-repair-times-chart :id="elevatorInfo.elevator.id"></run-and-repair-times-chart>
                  </div>
                </div>
                <!-- 近七天重点关注 -->
                <div class="attention module">
                  <div class="moduleTitle">
                    <div class="leftLine"></div>
                    <div class="title">近七天重点关注</div>
                  </div>
                  <div class="moduleContent">
                    <fault-type-chart :id="elevatorInfo.elevator.id"></fault-type-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import LockFloorEdit from "@/views/monitor/LockFloorEdit";
  import RunAndRepairTimesChart from "./RunAndRepairTimesChart.vue";
  import FaultTypeChart from "./FaultTypeChart.vue";

  export default {
    components: {RunAndRepairTimesChart, FaultTypeChart, LockFloorEdit},

    data() {
      return {
        floorAuth: this.$auth(842),
        // tableHeight: 'calc(100% -this.$refs.div1.style.height - this.$refs.div2.style.height)',
        tableData: [],
        heathIndexShow: false,
        heathIndexLoading: false,
        heathIndex: 100,
        potentialFaults: [],
        webSocketStartTime: "",
        webSocketEndTime: "",
        currentSpeed: 0,
        wsUrl: window.config.wsUrl,
        lockReconnect: false, // 避免重复连接
        reconnectInterval: 3000,
        reconnectNum: 3, // 重试次数
        useUnitElevators: [],
        toggleModalShow: false,
        elevatorInfo: {
          elevator: {},
          workOrderRecordVos: [],
          maintRecordVos: [],
          faultRecordVos: [],
          deviceEvenVos:[],
        },
        connectState: "",
        webSocketInfo: {
          Car_Status: 0,
          Door_Open_Times: -1,
          Down_Releveling_Times: -1,
          Reference_Floor_Open_Times: -1,
          Upward_Releveling_Times: -1,
          Door_Open_Close_Times: -1,
          Car_Light_Times: -1,
          Wirerope_Bending_Times: -1,
          Light_Action_Times: -1,
          Safe_Touch_Action_Times: -1,
          Present_Counter_Value: -1,
          Car_Light_Running_Time: -1,
          Run_Speed: "-1",
        },
        currenStatus: "",
        currentTab: 1,
        faultDescription: "",
        a4: {},   // IO端口
        lockStatus: "",
        isAC: "",
        lockLoading: false,
        radioLoading: false,
        resetLoading: false,
        remoteLock: 0,  // 远程锁梯
        editAuth: this.$auth(408),
      };
    },
    mounted(){
      this.timer = setInterval(() => {
        this.getLockFloorList(this.$route.params.elevatorId);
      }, 10000);
    },

    watch: {
      // 鉴定电梯实时故障代码
      "webSocketInfo.Fault_Code": {
        handler(newVal, oldVal) {
          if (newVal && newVal !== oldVal) {
            this.getDescription(this.elevatorInfo.elevator.faultId, this.webSocketInfo.Fault_Code);
          }
        },
      },

      // 监听电梯是否在运行
      "webSocketInfo.Car_Status": {
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            if (newVal) {
              this.animateValue(this.elevatorInfo.elevator.ratedSpeed);
            } else {
              // this.currentSpeed = 0;
              this.animateValue2(this.elevatorInfo.elevator.ratedSpeed);
            }
          }

        },
      },
    },

    methods: {
      // 故障复位
      setRestElevatorFault() {
        let param = {
          dtuCode: this.elevatorInfo.elevator.dtuCode,
          nodeCode: this.elevatorInfo.elevator.nodeCode,
        };
        this.$confirm(this.$t("monitor.tip.confirmReset"), this.$t("common.tips"), {type: "warning"}).then(() => {
          this.resetLoading = true;
          this.$api.getData("remote/resetElevatorFault", param).then((res) => {
            this.resetLoading = false;
            if (res.data.status === 0) {
              this.$message.error(this.$t("monitor.tip.faultResetFail"));
            } else {
              this.$message.success(this.$t("monitor.tip.faultResetSuccess"));
            }
          }).catch(() => {
            this.resetLoading = false;
            this.$message.error(this.$t("monitor.tip.faultResetFail"));
          });
        });
      },

      deleteRow(row) {
        this.$confirm(this.$t("common.delete") + " " + "封锁时段为" + " " + row.lockTime + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById("lockFloor", row.id).then(res => {
            this.getLockFloorList(row.elevatorId);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch((error) => {
            if (error.response) {
              this.$message.error(this.$t("common.tip.deleteError") + "," + error.response.data.message);
            }
          });
        });
      },
      changeState(row, state) {
        this.$http.post("lockFloor/updateState/" + row.id + "/" + state).then((res) => {
          this.getLockFloorList(row.elevatorId);
        });
      },
      getLockFloorList(elevatorId) {
        this.$http.get("lockFloor/list/" + elevatorId).then((res) => {
          this.tableData = res.data;
        });
      },
      // 联网设置
      setLockStatus() {
        this.lockLoading = true;
        let param = {
          dtuCode: this.elevatorInfo.elevator.dtuCode,
          node: this.elevatorInfo.elevator.nodeCode,
          lockStatus: this.lockStatus,
        };
        this.$api.getData("elevators/lock", param).then((res) => {
          this.$message.success("发送锁梯指令成功");
          if (res.data.status == 0) {
            this.$message.error(res.data.err);
          } else {
            this.$message.success(res.data.data);
          }
          this.lockLoading = false;
        }).catch(() => {
          this.lockLoading = false;
          this.$message.error("设置指令失败");
        });
      },
      setAC() {
        this.radioLoading = true;
        let param = {
          dtuCode: this.elevatorInfo.elevator.dtuCode,
          nodeCode: this.elevatorInfo.elevator.nodeCode,
          status: this.isAC,
        };
        this.$api.getData("remote/setAC", param).then((res) => {
          this.$message.success("发送指令成功");
          if (res.data.status == 0) {
            this.$message.error(res.data.err);
          } else {
            this.$message.success(res.data.data);
          }
          this.radioLoading = false;
        }).catch(() => {
          this.radioLoading = false;
          this.$message.error("设置指令失败");
        });
      },
      comparisonLockElevator(value) {
        if (value === 1) {
          if (this.remoteLock === 1) {
            this.$message.success("设置指令成功");
            this.lockStatus = this.remoteLock;
          } else {
            this.lockStatus = 0;
            this.$message.error("设置指令失败");
          }
        } else {
          if (this.remoteLock === 0) {
            this.$message.success("设置指令成功");
            this.lockStatus = this.remoteLock;
          } else {
            this.lockStatus = 1;
            this.$message.error("设置指令失败");
          }
        }
        this.lockLoading = false;
      },

      goToOld() {
        window.open("/#/singleMonitorOld/" + this.elevatorInfo.elevator.id);
      },

      animateValue(ratedSpeed) {
        this.currentSpeed = 0;
        const targetValue = ratedSpeed * 100;
        const increment = 35;
        const interval = setInterval(() => {
          if (this.currentSpeed + increment < targetValue) {
            this.currentSpeed += increment;
          } else {
            this.currentSpeed = targetValue;
            clearInterval(interval);
          }
        }, 600);
      },

      animateValue2(ratedSpeed) {
        let maxSpeed = ratedSpeed * 100;
        const increment = 35;
        const interval = setInterval(() => {
          if (maxSpeed - increment > 0) {
            maxSpeed -= increment;
            this.currentSpeed = maxSpeed;
          } else {
            this.currentSpeed = 0;
            clearInterval(interval);
          }
        }, 600);
      },

      changeElevator(id) {
        this.remoteLock = 0;
        this.ws.close();
        this.initPage(id);
      },

      // 页面初始化
      init(data) {
        this.initPage(data.elevator.id);
      },

      // 获取电梯信息
      initPage(elevatorId) {
        this.$api.getById("singleLadderMonitor", elevatorId).then(res => {
          this.elevatorInfo = res.data;
          this.getList();
          this.getHealthIndex(elevatorId);
          this.getLockFloorList(elevatorId);
          this.createWs();
        }).catch(() => {
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },

      // 获取健康指数
      getHealthIndex(elevatorId) {
        this.heathIndexLoading = true;
        this.$http.get(`potentialFaults/programs?elevatorId=${elevatorId}`).then(res => {
          this.heathIndexLoading = false;
          this.heathIndexShow = true;
          this.potentialFaults = res.data.potentialFaults;
          this.heathIndex = res.data.heathIndex;
        }).catch(() => {
          this.heathIndexLoading = false;
        });
      },

      // 根据故障模板id 和故障代码查询故障描述
      getDescription(faultId, faultCode) {
        this.$http.get(`faults/desc?faultId=${faultId}&faultCode=${faultCode}`).then(res => {
          this.faultDescription = res.data;
        });
      },

      // 获取相同使用单位的电梯列表
      getList() {
        let params = {
          "pageNo": 1,
          "useUnitId": this.elevatorInfo.elevator.useUnitId,
          "pageSize": 100,
        };
        this.$api.getList("singleLadderMonitor", params).then(res => {
          this.useUnitElevators = res.data.records;
        }).catch(() => {
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },

      // 初始化webSocket
      createWs() {
        this.connectState = "开始连接";
        try {
          this.ws = new WebSocket(this.wsUrl);
          this.initEventHandle();
        } catch (e) {
          this.reconnectWs();
        }
      },

      // 获取webSocket返回的信息
      initEventHandle() {
        this.ws.onclose = () => {
          this.connectState = "连接断开";
          if (!this.elevatorInfo.elevator.monitorLimit) {
            this.reconnectWs();
          } else {
            const currentTimeValue = this.$moment().valueOf();
            if (currentTimeValue < this.webSocketEndTime) {
              this.reconnectWs();
            }
          }
        };
        this.ws.onerror = () => {
          this.connectState = "连接异常";
          this.reconnectWs();
        };
        this.ws.onopen = () => {
          this.connectState = "连接中...";
          let authProto = {
            t: "B00",
            d: {
              regCode: this.elevatorInfo.elevator.regNo,
            },
          };
          if (this.elevatorInfo.elevator.monitorLimit) {
            this.webSocketStartTime = this.$moment().valueOf();
            this.webSocketEndTime = this.webSocketStartTime + this.elevatorInfo.elevator.monitorLimit * 60 * 1000;
          }
          this.ws.send(JSON.stringify(authProto)); // 发送认证信息
        };
        this.ws.onmessage = (event) => {
          try {
            let proto = JSON.parse(event.data);
            if (proto.t === "A05" || proto.t === "A09") {
              this.reconnectNum = 3;
            }
            if (proto.t === "A09") {
              this.webSocketInfo = proto.d;
              this.remoteLock = this.webSocketInfo.Remote_Lock;
              if (this.lockStatus === "") {
                this.lockStatus = this.webSocketInfo.Net_Ctrl;
              }
              if (this.isAC === "") {
                this.isAC = proto.d.Remote_Lock === 1;
              }
              if (proto.d.A4) {
                this.a4 = proto.d.A4;
              }
              this.connectState = "连接正常";
              switch (this.webSocketInfo.Service_Mode) {
                case 1:
                  this.currenStatus = this.$t("monitor.status.motion");
                  break;
                case 2:
                  this.currenStatus = this.$t("monitor.status.service");
                  break;
                case 3:
                  this.currenStatus = this.$t("monitor.status.fireReturn");
                  break;
                case 4:
                  this.currenStatus = this.$t("monitor.status.fire");
                  break;
                case 5:
                  this.currenStatus = this.$t("monitor.status.emergencyPower");
                  break;
                case 6:
                  this.currenStatus = this.$t("monitor.status.earthquakeModel");
                  break;
                case 7:
                  this.currenStatus = this.$t("monitor.status.unKnow");
                  break;
                case 8:
                  this.currenStatus = this.$t("monitor.status.safety");
                  break;
                case 9:
                  this.currenStatus = this.$t("monitor.status.fault");
                  break;
                case 10:
                  this.currenStatus = this.$t("monitor.status.Lock");
                  break;
                case 11:
                  this.currenStatus = this.$t("monitor.status.independence");
                  break;
                case 12:
                  this.currenStatus = this.$t("monitor.status.driver");
                  break;
              }
            }
          } catch (e) {
            console.log(e);
          }
        };
      },

      // 重连
      reconnectWs() {
        if (!this.lockReconnect) {
          this.lockReconnect = true;
          // 没连接上会一直重连，设置延迟避免请求过，自动重连三次
          setTimeout(() => {
            if (this.reconnectNum > 0) {
              this.reconnectNum--;
              this.createWs();
              this.lockReconnect = false;
            }
          }, this.reconnectInterval);
        }
      },

      getHM(time) {
        let day = parseInt(time / 60 / 60 / 24);
        let hour = parseInt(time / 60 / 60 % 24);
        let min = parseInt(time / 60 % 60);
        day = day > 9 ? day : "0" + day;
        hour = hour > 9 ? hour : "0" + hour;
        min = min > 9 ? min : "0" + min;
        return (time / 60 / 60).toFixed(2) + "小时";
      },

      getHMS(time) {
        let day = parseInt(time / 60 / 60 / 24);
        let hour = parseInt(time / 60 / 60 % 24);
        let min = parseInt(time / 60 % 60);
        let sec = parseInt(time % 60);
        day = day > 9 ? day : "0" + day;
        hour = hour > 9 ? hour : "0" + hour;
        min = min > 9 ? min : "0" + min;
        sec = sec > 9 ? sec : "0" + sec;
        return day + "天" + hour + "时" + min + "分" + sec + "秒";
      },
    },
  };
</script>

<style lang="scss" scoped>
.mainContent {
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  padding-right: 32px;

  .header {
    height: 56px;
    display: flex;
    align-items: center;
    padding-left: 32px;
    font-size: 12px;
    color: #4d4d4d;
  }

  .main {
    flex: 1;
    display: flex;
    min-height: 0;

    .mainLeft {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 20%;
      min-width: 320px;

      .elevatorName {
        font-size: 30px;
        color: #0747fd;
        padding: 10px 0 0 32px;
        min-height: 23px;
      }

      .model {
        flex: 1;
        // background: url(../../assets/elevator.png) center no-repeat;
        // background-size: 100% 100%;
        position: relative;

        .modelImg {
          position: absolute;
          top: 50px;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: space-around;
        }
      }

      .toggle {
        width: 64px;
        height: 64px;
        background-color: #fff;
        border-radius: 32px;
        position: absolute;
        left: 32px;
        bottom: 32px;
        box-shadow: 0px 6px 12px 1px rgba(0, 0, 0, 0.2);
        font-size: 14px;
        color: #0747fd;
        text-align: center;
        line-height: 64px;
        cursor: pointer;

        &.toggleActive {
          background-color: #0747fd;
          color: #fff;
        }
      }

      .toggleModal {
        max-height: 352px;
        background-color: #fff;
        position: absolute;
        bottom: 116px;
        left: 32px;
        border-radius: 12px;
        overflow: auto;
        right: 32px;

        ul {
          list-style: none;
          padding: 0 16px;

          li {
            line-height: 32px;
            font-size: 14px;
            color: #808080;
            cursor: pointer;

            &:hover {
              background-color: #f4f8ff;
            }
          }
        }
      }
    }

    .mainRight {
      flex: 1;
      min-width: 1200px;

      .mainRightContent {
        height: 100%;
        padding-bottom: 57px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        // 每个模块的共同样式
        .module {
          background-color: #fff;
          border-radius: 12px;
          box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
          display: flex;
          flex-direction: column;

          .moduleTitle {
            height: 48px;
            border-bottom: 1px solid #f2f2f2;
            display: flex;
            align-items: center;
            padding: 0 16px;
            box-sizing: border-box;
            color: #4d4d4d;

            .leftLine {
              width: 4px;
              height: 16px;
              background-color: #0747FD;
              border-radius: 100px;
              margin-right: 8px;
            }

            .title {
              flex: 1;
            }
          }

          .moduleContent {
            flex: 1;
            overflow: auto;
          }
        }

        .contentTop {
          height: 56%;
          display: flex;
          justify-content: space-between;
        }

        .contentBottom {
          flex: 1;
          display: flex;
          justify-content: space-between;
          margin-top: 16px;
          min-height: 0;
        }

        .contentLeft {
          width: 50.78%;
          display: flex;

          // 实时数据
          .liveData {
            width: 44.3%;
            border: 1px solid #0747FD;
            overflow: hidden;

            .moduleTitle {
              background-color: #0747FD;
              color: #fff;

              .leftLine {
                background-color: #fff;
              }
            }

            .moduleContent {
              padding: 15px 28px;
              position: relative;

              .liveDataItem {
                border-bottom: 1px solid #f2f2f2;
                display: flex;
                height: 41px;
                align-items: center;

                .liveDataTitle {
                  width: 56px;
                  font-size: 14px;
                  color: #808080;
                  margin-right: 16px;

                  &.floorAction {
                    background-color: #00C291;
                    border-radius: 100px;
                    color: #fff;
                    text-align: center;
                    line-height: 24px;
                    font-size: 12px;
                  }
                }

                .liveDataContent {
                  flex: 1;
                  color: #0747FD;
                  font-size: 18px;

                  .floor {
                    margin-right: 3px;
                    color: #1a1a1a;
                    font-size: 14px;
                  }
                }
              }

              // 重新连接
              .reconnect {
                background: rgb(255, 255, 255, 0.5);
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;

                .reconnectBtn {
                  width: 110px;
                  background-color: #0747FD;
                  border-radius: 100px;
                  color: #fff;
                  text-align: center;
                  padding: 8px 0;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  font-size: 14px;
                  cursor: pointer;
                }
              }
            }
          }

          // 电梯状态
          .elevatorStatus {
            flex: 1;
            margin-left: 16px;

            .moduleContent {
              padding: 15px 28px;
            }
          }

          // 实时故障
          .liveFault {
            display: flex;
            font-size: 14px;
            color: #1a1a1a;

            .faultLeft {
              width: 50%;
              padding: 15px 28px;
              box-sizing: border-box;
            }

            .faultRight {
              width: 50%;
              background: #F5F8FF;
              border-radius: 0px 0px 12px 0px;
              padding: 15px 28px;
              box-sizing: border-box;

              .dmbFaultBox {
                margin-top: 20px;
              }

              .dmbFault {
                padding: 3px 10px;
                border-radius: 16px;
                border: 1px solid #0747FD;
                color: #0747FD;
              }
            }
          }
        }

        .contentRight {
          flex: 1;
          margin-left: 16px;
          overflow: hidden;

          .moduleContent {
            padding: 15px 30px;
            font-size: 14px;
            color: #1a1a1a;
            position: relative;

            &.elevatorProduct {
              padding-left: 156px;
            }

            .healthIndex {
              width: 100px;
              height: 120px;
              background-color: #00C291;
              position: absolute;
              top: 20px;
              left: 28px;
              border-radius: 12px;
              text-align: center;

              .number {
                height: 88px;
                line-height: 88px;
                font-size: 44px;
                color: #fff;
              }

              .title {
                height: 32px;
                text-align: center;
                background: #B2EDDE;
                line-height: 32px;
                border-radius: 0 0 12px 12px;
              }
            }

            .dangersNum {
              height: 60px;
              background: #FEF8EC;
              border-radius: 8px 8px 8px 8px;
              display: flex;
              padding: 0 16px;
              align-items: center;
            }

            .dangersList {
              padding: 14px 16px 0 70px;

              li {
                margin-top: 10px;
              }
            }

            .elevatorInfo {
              display: flex;
              margin-bottom: 13px;

              .elevatorInfoLeft {
                color: #808080;
                width: 120px;
              }

              .elevatorInfoRight {
                flex: 1;
                color: #1a1a1a;
              }
            }

            .portTitle {
              color: #1a1a1a;
              margin-bottom: 10px;
            }

            .workOrderRecordVos {
              width: 100%;

              tr {
                line-height: 32px;

                td {
                  border-bottom: 1px solid #f2f2f2;
                  width: 50%;

                  &:first-child {
                    padding-left: 30px;
                  }
                }
              }
            }
          }

          // 运行参数
          .runParams {
            .elevatorInfoLeft {
              width: 150px !important;
            }
          }

          .instruct {
            .el-radio {
              margin-bottom: 0;
            }
          }

          .attention,
          .runCount {
            flex: 1;

            .moduleTitle {
              padding: 0 16px;
            }

            .moduleContent {
              padding: 15px 0 0 0;
            }
          }

          .attention {
            margin-left: 16px;
          }
        }
      }
    }
  }

  .borderBottomNone {
    border-bottom: none !important;
  }

  .currentTab {
    color: #0747FD;
    border-bottom: 1px solid #0747FD;
  }
}
</style>

<style lang="scss">
.liftMonitor {
  .el-radio {
    width: 50%;
    margin-right: 0;
    margin-bottom: 10px;
    cursor: default !important;

    .el-radio__input {
      cursor: default !important;
    }

    .el-radio__label {
      cursor: default !important;
      color: #1a1a1a !important;
    }

    .el-radio__input.is-checked .el-radio__inner {
      border-color: #0747fd;
      background-color: #0747fd;
    }

    .el-radio__input.is-checked .el-radio__inner::after {
      cursor: default !important;
      background-color: #fff;
    }

    .el-radio__input.is-disabled .el-radio__inner {
      cursor: default !important;
    }

    .el-radio__input.is-checked .el-radio__inner {
      cursor: default !important;
    }
  }

  .el-table::before {
    height: 0;
  }

  .el-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;

    .el-tabs__content {
      overflow-y: auto;
    }

    .el-tabs__item {
      height: 48px;
      line-height: 48px;
      font-size: 16px;
    }

    .el-tabs__nav-next, .el-tabs__nav-prev {
      font-size: 16px;
      line-height: 48px;
    }

    .el-tabs--top .el-tabs__item.is-top:last-child {
      padding-right: 16px;
    }

    .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 16px;
    }
  }
}
</style>
