<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$t('deviceGateway.editRoute')"
    width="600px"
    top="3vh">
    <div style="height: 300px" v-loading="submitLoading">
      <div style="padding-top: 10px;width: 100%">
        <div style="width: 25%;line-height:28px;float: left;text-align: right;padding-right: 5%;">终端编号</div>
        <div style="width: 50%;line-height:28px;float: left">
          {{info.dtuCode}}
        </div>
        <div style="width: 20%;float: left;text-align: left"></div>
      </div>
      <div style="padding-top: 40px;width: 100%">
        <div style="width: 25%;line-height:28px;float: left;text-align: right;padding-right: 5%;">设置限制参数</div>
        <div style="width: 50%;float: left">
          <el-input-number
            v-model="info.setLimitFlow"
            style="width: 100px;"
            controls-position="right"
            :min="3"
            :max="10240"></el-input-number>
          MByte
        </div>
        <div style="width: 20%;float: left;text-align: left">
          <el-button type="primary" @click="handleSet">设置</el-button>
        </div>
      </div>
      <div style="padding-top: 40px;width: 100%">
        <div style="width: 25%;line-height:28px;float: left;text-align: right;padding-right: 5%">复位计数</div>
        <div style="width: 50%;float: left">&nbsp;</div>
        <div style="width: 20%;float: left;text-align: left">
          <el-button type="primary" @click="handleReset">复位</el-button>
        </div>
      </div>
      <div style="padding-top: 40px;width: 100%">
        <div style="width: 25%;line-height:28px;float: left;text-align: right;padding-right: 5%">ETH参数</div>
        <div style="width: 50%;height: 150px;float: left">
          <div v-if="readVisible">
            <div style="padding-top: 10px">
              当月限制的流量： {{readParam.limitFlow}}（Mbyte）
            </div>
            <div style="padding-top: 10px">
              当前网口状态： {{readParam.wanConnState}}
            </div>
            <div style="padding-top: 10px">
              当前网口启用状态： {{readParam.wanUseState}}
            </div>
            <div style="padding-top: 10px">
              网口禁用时间: {{readParam.wanForbiddenTime}}
              <!--        年/月/日/时/分/秒;-->
              <!--        如果网口是启用状态则为0-->
            </div>
            <div style="padding-top: 10px">
              统计的当月流量： {{readParam.totalFlow}}（Mbyte）
            </div>
          </div>
          <div v-if="!readVisible">
            &nbsp;
          </div>
        </div>
        <div style="width: 20%;float: left;text-align: left">
          <el-button type="primary" @click="handleRead">读取</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      multiSelect: {
        type: Array,
      },
    },
    data() {
      return {
        isBlack: 0,
        dialogVisible: false,
        submitLoading: false,
        readVisible: false,
        info: {
          dtuCode: "",
          setLimitFlow: 0,
        },
        readParam: {
          limitFlow: "",
          wanConnState: "",
          wanUseState: "",
          wanForbiddenTime: "",
          totalFlow: "",
        },
      };
    },
    methods: {
      open(dtuCode) {
        this.dialogVisible = true;
        if (dtuCode.length > 0) {
          this.info.dtuCode = dtuCode;
        }
      },
      handleSet() {
        this.submitLoading = true;
        this.$http.get("deviceGateway/route/set/" + this.info.dtuCode + "/" + this.info.setLimitFlow).then(res => {
          this.submitLoading = false;
          if (res.data.status == 0) {
            this.$message.error(res.data.err);
          } else {
            this.$message.success(res.data.data);
          }
        }).catch(error => {
          this.submitLoading = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
        });
      },
      handleReset() {
        this.submitLoading = true;
        this.$http.get("deviceGateway/route/reset/" + this.info.dtuCode).then(res => {
          this.submitLoading = false;
          if (res.data.status == 0) {
            this.$message.error(res.data.err);
          } else {
            this.$message.success(res.data.data);
          }
        }).catch(error => {
          this.submitLoading = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
        });
      },
      handleRead() {
        this.submitLoading = true;
        this.readVisible = false;
        this.$http.get("deviceGateway/route/read/" + this.info.dtuCode).then(res => {
          this.submitLoading = false;
          if (res.data.status == 0) {
            this.$message.error(res.data.err);
          } else {
            this.readParam = Object.assign(this.readParam, res.data.data);
            // console.log("hello");
            // console.log(this.readParam);
            this.readVisible = true;
          }
        }).catch(error => {
          this.submitLoading = false;
          this.readVisible = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
        });
      },
    },
  };
</script>

<style lang="scss" scoped>

</style>
