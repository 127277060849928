import Vue from "vue";
import VueI18n from "vue-i18n";
import elementZhCnLocale from "element-ui/lib/locale/lang/zh-CN"; // element-ui lang
import elementEnLocale from "element-ui/lib/locale/lang/en"; // element-ui lang
import elementItLocale from "element-ui/lib/locale/lang/it"; // element-ui lang
import enUs from "./en-US";
import zhCn from "./zh-CN";
import it from "./it-Italy";

Vue.use(VueI18n);

const messages = {
  "zh-CN": Object.assign(zhCn, elementZhCnLocale),
  "en-US": Object.assign(enUs, elementEnLocale),
  "it-Italy": Object.assign(it, elementItLocale),
};

// 自动根据浏览器系统语言设置语言
let navLang = navigator.language;
if (navLang && navLang.startsWith("en")) {
  navLang = "en-US";
}
let lang = window.localStorage.getItem("local") || navLang || "zh-CN";

const i18n = new VueI18n({
  locale: lang,
  messages,
});

i18n.isCn = i18n.locale === "zh-CN";
i18n.isEn = i18n.locale === "en-US";
i18n.isIt = i18n.locale === "it-Italy";

export function myI18n(key, cn, values) {
  if (i18n.locale === "zh-CN" && cn) {
    if (values) {
      const interpolate = (string, values) => string.replace(/{(.*?)}/g, (match, offset) => values[offset]);
      return interpolate(cn, values);
    } else {
      return cn;
    }
  }
  return i18n.t(key, values);
}

export default i18n;
