<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$l('route.netApply','批量修改')"
    width="1800px"
    top="2vh">
    <div class="vm-separate">
      <div>
        <el-button type="primary" @click="$refs.netApplySelect.open()">
          {{$l("route.netApply", "选择入网申请")}}
        </el-button>
        <net-apply-select
          ref="netApplySelect"
          :multi-selection="true"
          @multi-select="onMultiNetApplySelect"
          @select="onNetApplySelect"></net-apply-select>
        <el-table border :data="netApplyList" height="85vh" style="margin-top: 10px;">
          <el-table-column prop="elevatorName" :label="$l('elevatorNetApply.elevatorName','电梯名称')" align="center"
                           width="170"></el-table-column>
          <el-table-column prop="dtuCode" :label="$l('elevatorNetApply.dtuCode','终端编号')" align="center"
                           width="110"></el-table-column>
          <el-table-column prop="factoryNumber" :label="$l('elevatorNetApply.factoryNumber','出厂编号')" align="center"
                           width="110"></el-table-column>
          <el-table-column prop="useUnit" :label="$l('elevatorNetApply.useUnit','使用单位')" align="center"
                           width="170"></el-table-column>
          <el-table-column prop="isWeb" :label="$l('elevatorNetApply.createSource','创建来源')" align="center" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.createType === 1">
                {{$l("userLoginLog.web", "网页新增")}}
              </span>
              <span v-if="scope.row.createType===2">
                {{$l("userLoginLog.app", "网页导入")}}
              </span>
              <span v-if="scope.row.createType===3">
                {{$l("userLoginLog.app", "APP")}}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="isCheck"
            :label="$l('elevator.joinStatus','审核状态')"
            width="100"
            align="center">
            <template v-slot="scope">
              <el-tag :type="scope.row.isCheck === 1 ? 'success' : 'info'">
                {{scope.row.isCheck === 1 ? $l("elevator.joined", "已审核") : $l("elevator.notJoined", "审核中")}}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.operation')" width="100px" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
                {{$t("common.delete")}}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="clear:both"></div>
      </div>
      <div style="margin-left: 20px;margin-top: 40px;">
        <div style="margin-bottom: 80px">
          <el-divider content-position="left">
            <span style="font-size: 16px;color: #000000">
              {{$l("elevator.floorComparisonTable", "楼层对照")}}
            </span>
          </el-divider>
          <el-form
            ref="form"
            :model="floor"
            label-width="115px"
            style="margin-top: 30px;">
            <el-form-item :label="$t('elevator.floorDisplayTemplate')" prop="floorDisplayId">
              <el-input v-model="floor.floorDisplayName" :placeholder="$t('common.pleaseSelect')" readonly>
                <el-button slot="append" icon="el-icon-search" @click="$refs.selectFloorDisplay.open()"></el-button>
              </el-input>
              <floor-display-select ref="selectFloorDisplay" @select="onSelectFloorDisplay"></floor-display-select>
            </el-form-item>
          </el-form>
          <div style="float:right;">
            <el-button size="small" @click="floor = ''">{{$l("elevatorNetApply.reset", "重置")}}</el-button>
            <el-button type="primary" size="small" :loading="submitLoadingFloor" @click="saveFloor">
              {{$t("common.save")}}
            </el-button>
          </div>
        </div>
        <div style="margin-bottom: 80px">
          <el-divider content-position="left">
            <span style="font-size: 16px;color: #000000">
              {{$l("elevator.faultTemplate", "使用单位")}}
            </span>
          </el-divider>
          <el-form
            ref="form"
            :model="useUnitName"
            label-width="115px"
            style="margin-top: 30px;">
            <el-form-item :label="$l('elevator.faultTemplate','使用单位')" prop="useUnitName">
              <el-input v-model="useUnitName" :placeholder="$t('common.pleaseSelect')" readonly>
                <el-button slot="append" icon="el-icon-search" @click="$refs.selectUseUnit.open()"></el-button>
              </el-input>
              <use-unit-select ref="selectUseUnit" @select="onSelectUseUnit"></use-unit-select>
            </el-form-item>
          </el-form>
          <div style="float:right;">
            <el-button size="small" @click="useUnitName = ''">{{$l("elevatorNetApply.reset", "重置")}}</el-button>
            <el-button type="primary" size="small" :loading="submitLoadingUseUnit" @click="saveUseUnit">
              {{$t("common.save")}}
            </el-button>
          </div>
        </div>
        <div style="margin-bottom: 80px">
          <el-divider content-position="left">
            <span style="font-size: 16px;color: #000000">
              {{$l("elevator.faultTemplate", "制造单位")}}
            </span>
          </el-divider>
          <el-form
            ref="form"
            :model="createComName"
            label-width="115px"
            style="margin-top: 30px;">
            <el-form-item :label="$l('elevator.faultTemplate','制造单位')" prop="createComName">
              <el-input v-model="createComName" :placeholder="$t('common.pleaseSelect')" readonly>
                <el-button slot="append" icon="el-icon-search" @click="$refs.selectCreateCom.open()"></el-button>
              </el-input>
              <company-select ref="selectCreateCom" @select="onSelectCreateCom"></company-select>
            </el-form-item>
          </el-form>
          <div style="float:right;">
            <el-button size="small" @click="createComName = ''">{{$l("elevatorNetApply.reset", "重置")}}</el-button>
            <el-button type="primary" size="small" :loading="submitLoadingCreateCom" @click="saveCreateCom">
              {{$t("common.save")}}
            </el-button>
          </div>
        </div>
        <div style="margin-bottom: 80px">
          <el-divider content-position="left">
            <span style="font-size: 16px;color: #000000">
              {{$l("elevator.faultTemplate", "维保单位")}}
            </span>
          </el-divider>
          <el-form
            ref="form"
            :model="maintComName"
            label-width="115px"
            style="margin-top: 30px;">
            <el-form-item :label="$l('elevator.faultTemplate','维保单位')" prop="maintComName">
              <el-input v-model="maintComName" :placeholder="$t('common.pleaseSelect')" readonly>
                <el-button slot="append" icon="el-icon-search" @click="$refs.selectMaintCom.open()"></el-button>
              </el-input>
              <company-select ref="selectMaintCom" @select="onSelectMaintCom"></company-select>
            </el-form-item>
          </el-form>
          <div style="float:right;">
            <el-button size="small" @click="maintComName = ''">{{$l("elevatorNetApply.reset", "重置")}}</el-button>
            <el-button type="primary" size="small" :loading="submitLoadingMaintCom" @click="saveMaintCom">
              {{$t("common.save")}}
            </el-button>
          </div>
        </div>
        <div style="margin-bottom: 80px">
          <el-divider content-position="left">
            <span style="font-size: 16px;color: #000000">
              {{$l("elevator.faultTemplate", "物业单位")}}
            </span>
          </el-divider>
          <el-form
            ref="form"
            :model="propertyComName"
            label-width="115px"
            style="margin-top: 30px;">
            <el-form-item :label="$l('elevator.faultTemplate','物业单位')" prop="propertyComName">
              <el-input v-model="propertyComName" :placeholder="$t('common.pleaseSelect')" readonly>
                <el-button slot="append" icon="el-icon-search" @click="$refs.selectPropertyCom.open()"></el-button>
              </el-input>
              <company-select ref="selectPropertyCom" @select="onSelectPropertyCom"></company-select>
            </el-form-item>
          </el-form>
          <div style="float:right;">
            <el-button size="small" @click="propertyComName = ''">{{$l("elevatorNetApply.reset", "重置")}}</el-button>
            <el-button type="primary" size="small" :loading="submitLoadingPropertyCom" @click="savePropertyCom">
              {{$t("common.save")}}
            </el-button>
          </div>
        </div>
        <div style="margin-bottom: 80px">
          <el-divider content-position="left">
            <span style="font-size: 16px;color: #000000">
              {{$l("elevator.faultTemplate", "生成电梯档案")}}
            </span>
          </el-divider>
          <div style="margin-top: 30px;margin-left: 50px">是否将选择的入网申请生成电梯档案？</div>
          <div style="float:right;margin-top: 10px">
            <el-button type="primary" size="small" :loading="createLoading" @click="create">
              确定
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import NetApplySelect from "@/views/elevatorNetApply/NetApplySelect";
  import FloorDisplaySelect from "@/views/floorDisplay/FloorDisplaySelect";
  import CompanySelect from "@/views/company/CompanySelect";
  import UseUnitSelect from "@/views/company/useUnitCom/UseUnitSelect";
  import auth from "@/util/auth";


  export default {
    components: {NetApplySelect, UseUnitSelect, FloorDisplaySelect, CompanySelect},
    data() {
      return {
        showOrgId:  auth.getUsername() === "superAdmin",
        dialogVisible: false,
        submitLoadingFloor: false,
        submitLoadingUseUnit: false,
        submitLoadingCreateCom: false,
        submitLoadingMaintCom: false,
        submitLoadingPropertyCom: false,
        createLoading: false,
        type: 1,
        netApplyList: [],
        elevatorFloors: [],
        useUnitName: "",
        createComName: "",
        maintComName: "",
        propertyComName: "",
        floor: {
          floorDisplayId: 0,
          floorDisplayName: "",
        },
      };
    },
    methods: {
      open() {
        this.dialogVisible = true;
      },
      saveFloor() {
        if (this.netApplyList.length === 0) {
          this.$message.error(this.$l("elevator.selectElevator", "请先选择入网申请"));
          return;
        }
        if (this.floor.floorDisplayId === 0) {
          this.$message.error(this.$l("elevator.selectFloorDisplay", "请先选择楼层对照"));
          return;
        }
        let netApplyIds = [];
        for (let item of this.netApplyList) {
          netApplyIds.push(item.id);
        }
        this.submitLoadingFloor = true;
        let params = {
          netApplyIds: netApplyIds,
          floorDisplayId: this.floor.floorDisplayId,
        };
        this.$api.save("elevatorNetApply/batchUpdateFloor", params).then(res => {
          this.submitLoadingFloor = false;
          this.$emit("save-success");
          this.netApplyList = [];
          this.floor = {
            floorDisplayId: 0,
            floorDisplayName: "",
          };
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.submitLoadingFloor = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error);
        });
      },
      saveUseUnit() {
        if (this.netApplyList.length === 0) {
          this.$message.error(this.$l("elevator.selectElevator", "请先选择入网申请"));
          return;
        }
        if (!this.useUnitName) {
          this.$message.error(this.$l("elevator.selectFloorDisplay", "请先选择使用单位"));
          return;
        }
        let netApplyIds = [];
        for (let item of this.netApplyList) {
          netApplyIds.push(item.id);
        }
        this.submitLoadingUseUnit = true;
        let params = {
          netApplyIds: netApplyIds,
          useUnitName: this.useUnitName,
        };
        this.$api.save("elevatorNetApply/batchUpdateUseUnit", params).then(res => {
          this.submitLoadingUseUnit = false;
          this.$emit("save-success");
          this.netApplyList = [];
          this.useUnitName = "";
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.submitLoadingUseUnit = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error);
        });
      },
      saveCreateCom() {
        if (this.netApplyList.length === 0) {
          this.$message.error(this.$l("elevator.selectElevator", "请先选择入网申请"));
          return;
        }
        if (!this.createComName) {
          this.$message.error(this.$l("elevator.selectFloorDisplay", "请先选择制造企业"));
          return;
        }
        let netApplyIds = [];
        for (let item of this.netApplyList) {
          netApplyIds.push(item.id);
        }
        this.submitLoadingCreateCom = true;
        let params = {
          netApplyIds: netApplyIds,
          companyName: this.createComName,
          type: 1,
        };
        this.$api.save("elevatorNetApply/batchUpdateCompany", params).then(res => {
          this.submitLoadingCreateCom = false;
          this.$emit("save-success");
          this.netApplyList = [];
          this.createComName = "";
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.submitLoadingCreateCom = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error);
        });
      },
      savePropertyCom() {
        if (this.netApplyList.length === 0) {
          this.$message.error(this.$l("elevator.selectElevator", "请先选择入网申请"));
          return;
        }
        if (!this.propertyComName) {
          this.$message.error(this.$l("elevator.selectFloorDisplay", "请先选择物业单位"));
          return;
        }
        let netApplyIds = [];
        for (let item of this.netApplyList) {
          netApplyIds.push(item.id);
        }
        this.submitLoadingPropertyCom = true;
        let params = {
          netApplyIds: netApplyIds,
          companyName: this.propertyComName,
          type: 5,
        };
        this.$api.save("elevatorNetApply/batchUpdateCompany", params).then(res => {
          this.submitLoadingPropertyCom = false;
          this.$emit("save-success");
          this.netApplyList = [];
          this.propertyComName = "";
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.submitLoadingPropertyCom = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error);
        });
      },
      saveMaintCom() {
        if (this.netApplyList.length === 0) {
          this.$message.error(this.$l("elevator.selectElevator", "请先选择入网申请"));
          return;
        }
        if (!this.maintComName) {
          this.$message.error(this.$l("elevator.selectFloorDisplay", "请先选择维保单位"));
          return;
        }
        let netApplyIds = [];
        for (let item of this.netApplyList) {
          netApplyIds.push(item.id);
        }
        this.submitLoadingMaintCom = true;
        let params = {
          netApplyIds: netApplyIds,
          companyName: this.maintComName,
          type: 2,
        };
        this.$api.save("elevatorNetApply/batchUpdateCompany", params).then(res => {
          this.submitLoadingMaintCom = false;
          this.$emit("save-success");
          this.netApplyList = [];
          this.maintComName = "";
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.submitLoadingMaintCom = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error);
        });
      },
      create() {
        if (this.netApplyList.length === 0) {
          this.$message.error(this.$l("elevator.selectElevator", "请先选择入网申请"));
          return;
        }
        for (let i = 0; i < this.netApplyList.length; i++) {
          if (this.netApplyList[i].isCheck === 1) {
            this.$message.error(this.$l("elevator.selectElevator", "已审核状态下表示已经生成电梯档案，请勿重复生成"));
            return;
          }
          if (this.netApplyList[i].elevatorId && this.netApplyList[i].elevatorId > 0){
            this.$message.error(this.$l("elevator.selectElevator", "已生成电梯档案，请勿重复生成"));
            return;
          }
        }
        let netApplyIds = [];
        for (let item of this.netApplyList) {
          netApplyIds.push(item.id);
        }
        this.createLoading = true;
        this.$http.post("elevatorNetApply/batchCreate", netApplyIds).then(() => {
          this.createLoading = false;
          this.$emit("save-success");
          this.netApplyList = [];
          this.dialogVisible = false;
          this.$message.success(this.$l("elevatorNetApply.generateSuccess", "生成成功"));
        }).catch(() => {
          this.createLoading = false;
        });
      },
      onSelectFloorDisplay(row) {
        this.floor.floorDisplayId = row.id;
        this.floor.floorDisplayName = row.name;
      },
      onSelectUseUnit(row) {
        this.useUnitName = row.name;
      },
      onSelectCreateCom(row) {
        this.createComName = row.name;
      },
      onSelectMaintCom(row) {
        this.maintComName = row.name;
      },
      onSelectPropertyCom(row) {
        this.propertyComName = row.name;
      },
      onNetApplySelect(row) {
        for (let item of this.netApplyList) {
          if (item.id === row.id) {
            return;
          }
        }
        this.netApplyList.push(row);
      },
      onMultiNetApplySelect(rows) {
        for (let row of rows) {
          let flag = true;
          for (let item of this.netApplyList) {
            if (item.id === row.id) {
              flag = false;
            }
          }
          if (flag) {
            this.netApplyList.push(row);
          }
        }
      },
      deleteRow(index) {
        this.netApplyList.splice(index, 1);
      },
    },
  };
</script>

<style scoped>
</style>
