<template>
  <div>
    <vm-table ref="vmTable" :filter.sync="search" url="bluetoothLinks">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="mainBoardProgram" label="主板程序"></el-table-column>
      <el-table-column prop="phone" label="手机号" width="200" align="center"></el-table-column>
      <el-table-column prop="createTime" label="连接时间" width="200" align="center"></el-table-column>
    </vm-table>
  </div>
</template>
<script>

export default {
  data() {
    return {
      search: {
        filter: "",
      },
    };
  },
  mounted() {
    this.$refs.vmTable.getList(1);
  },
};
</script>
<style lang="scss" scoped></style>
