<template>
  <el-dialog class="select-dialog" :title="$t('common.select') + ' ' + $l('route.netApply', '入网申请')"
    :visible.sync="dialogVisible" width="80%" top="6vh" append-to-body @close="onDialogClose">
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" :multi-selection="multiSelection"
      url="elevatorNetApply" @row-click="handleRowClick" @selection-change="handleMultiSelectChange">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('elevatorNetApply.createSource', '创建来源')">
          <el-select v-model.trim="search.createType" :placeholder="$t('common.pleaseSelect')" style="width: 100%"
            clearable>
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button v-if="multiSelection" type="primary" @click="multiSelectSure">
          {{ $l("elevator.confirmChoice", "确定选择") }}
        </el-button>
      </template>
      <el-table-column prop="elevatorName" :label="$l('elevatorNetApply.elevatorName', '电梯名称')"></el-table-column>
      <el-table-column prop="dtuCode" :label="$l('elevatorNetApply.dtuCode', '终端编号')"></el-table-column>
      <el-table-column prop="factoryNumber" :label="$l('elevatorNetApply.factoryNumber', '出厂编号')"></el-table-column>
      <el-table-column prop="useUnit" :label="$l('elevatorNetApply.useUnit', '使用单位')"></el-table-column>
      <el-table-column prop="userName" :label="$l('gatewayVersion.creator', '创建人')" width="200px"></el-table-column>
      <el-table-column prop="createType" :label="$l('elevatorNetApply.createSource', '创建来源')" width="150px">
        <template slot-scope="scope">
          <span v-if="scope.row.createType === 1">
            {{ $l("userLoginLog.web", "网页新增") }}
          </span>
          <span v-if="scope.row.createType === 2">
            {{ $l("userLoginLog.app", "网页导入") }}
          </span>
          <span v-if="scope.row.createType === 3">
            {{ $l("userLoginLog.app", "APP") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="isCheck" :label="$l('elevator.joinStatus', '审核状态')" width="150px" align="center">
        <template v-slot="scope">
          <el-tag :type="scope.row.isCheck === 1 ? 'success' : 'info'">
            {{ scope.row.isCheck === 1 ? $l("elevator.joined", "已审核") : $l("elevator.notJoined", "审核中") }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.operation')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="success" @click="handleSelect(scope.row)">{{ $t("common.select") }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>
<script>

export default {
  props: {
    multiSelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      search: {
        filter: "",
        createType: "",
      },
      typeOptions: [
        { value: 1, label: this.$l("newMsgSend.msgPush", "网页新增") },
        { value: 2, label: this.$l("newMsgSend.email", "网页导入") },
        { value: 3, label: this.$l("newMsgSend.email", "APP") },
      ],
    };
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    open() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.getList(1);
      });
    },
    handleSelect(row) {
      this.$emit("select", row);
      this.dialogVisible = false;
    },
    onDialogClose() {
      this.$copy(this.$data, this.$options.data.call(this));
    },
    handleMultiSelectChange(val) {
      this.multiSelect = val;
    },
    multiSelectSure() {
      this.$emit("multi-select", this.multiSelect);
      this.dialogVisible = false;
    },
    handleRowClick(row, column) {
      if (column && column.label === this.$t("common.operation")) {
        return;
      }
      this.$refs.vmTable.$refs.elTable.toggleRowSelection(row);
    },
  },
};
</script>
<style lang="scss" scoped></style>
