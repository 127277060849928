<template>
  <div class="cloudKanban">
    <div class="top">{{ title }}</div>
    <div class="bottom">
      <div class="left">
        <div class="countInfo">
          <el-row>
            <el-col :span="12">
              <div class="countItem">
                <img class="icon" src="../../assets/kanban/sbzs@2x.png"/>
                <div>
                  <div class="countName">设备总数</div>
                  <div class="value">{{ equipmentCount }}</div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="countItem">
                <img class="icon" src="../../assets/kanban/rwzs@2x.png"/>
                <div>
                  <div class="countName">入网总数</div>
                  <div class="value">{{ inNetCount }}</div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="countItem">
                <img class="icon" src="../../assets/kanban/zxzs@2x.png"/>
                <div>
                  <div class="countName">在线总数</div>
                  <div class="value">{{ onlineCount }}</div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="countItem">
                <img class="icon" src="../../assets/kanban/dqjx@2x.png"/>
                <div>
                  <div class="countName">当前急修</div>
                  <div class="value">{{ repairCount }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="elevatorStatus">
          <div class="itemTitle">电梯状态</div>
          <div id="statusChart" style="height: 212px;"></div>
        </div>
        <div class="alarm">
          <div class="itemTitle">警铃报警</div>
          <div class="itemContent">
            <vue-seamless-scroll :data="alarmList" :class-option="defineScroll">
              <div class="scrollItem" v-for="(item, index) in alarmList" :key="index">
                <div class="itemTime">{{ item.alarmTime }}</div>
                <div class="itemName">{{ item.elevatorName + "（" + item.elevatorNo + "）" }}</div>
                <div class="line"></div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="map" id="map"></div>
        <div>已对接各省/地方平台：<span style="font-family: 'electronicFont';font-size: 40px;color: #fff;">{{ cityTotal }}</span> 个</div>
        <vue-seamless-scroll :data="platforms" :class-option="classOption" class="warp">
          <ul class="ul-item">
            <li class="li-item" v-for="(item, index) in platforms" :key="index">
              <div style="color: #fff;"><span class="cityCount">{{ item.value }}</span> 台</div>
              <div style="color: #B4C7FF;">{{ item.name }}</div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
      <div class="right">
        <div class="fault">
          <div class="itemTitle">实时故障</div>
          <div class="itemContent">
            <vue-seamless-scroll :data="faultList" :class-option="defineScroll">
              <div class="scorllItem" v-for="(item, index) in faultList" :key="index">
                <div class="itemTime">{{ item.faultTime }}</div>
                <div class="itemName">{{ item.elevatorName + "（" + item.elevatorNo + "）" }}</div>
                <div class="otherInfo">{{ "故障码：" + item.faultCode + "（" + item.faultDesc + "）" }}</div>
                <div class="line"></div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
        <div class="faultType">
          <div class="itemTitle">故障类型Top5</div>
          <div id="faultTop" style="height: 212px;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSeamlessScroll from "vue-seamless-scroll";
import * as echarts from "echarts";

export default {
  components: { VueSeamlessScroll },
  data() {
    return {
      equipmentCount: 0,
      inNetCount: 0,
      onlineCount: 0,
      repairCount: 0,
      statusData: [],
      alarmList: [],
      faultList: [],
      defineScroll: {
        step: 0.5
      },
      total: "0",
      geoCoordMap: {
        北京市: [116.46, 39.92],
        天津市: [117.2, 39.13],
        河北省: [114.48, 38.03],
        山西省: [112.53, 37.87],
        内蒙古自治区: [111.65, 40.82],
        辽宁省: [123.38, 41.8],
        吉林省: [125.35, 43.88],
        黑龙江省: [126.63, 45.75],
        上海市: [121.48, 31.22],
        江苏省: [118.78, 32.04],
        浙江省: [120.19, 30.26],
        安徽省: [117.27, 31.86],
        福建省: [119.3, 26.08],
        江西省: [115.89, 28.68],
        山东省: [117, 36.65],
        河南省: [113.65, 34.76],
        湖北省: [114.31, 30.52],
        湖南省: [113, 28.21],
        广东省: [113.23, 23.16],
        广西壮族自治区: [108.33, 22.84],
        海南省: [110.35, 20.02],
        重庆市: [106.54, 29.59],
        四川省: [104.06, 30.67],
        贵州省: [106.71, 26.57],
        云南省: [102.73, 25.04],
        西藏自治区: [91.11, 29.97],
        陕西省: [108.95, 34.27],
        甘肃省: [103.73, 36.03],
        青海省: [101.74, 36.56],
        宁夏回族自治区: [106.27, 38.47],
        新疆维吾尔自治区: [87.68, 43.77],
        台湾省: [121.58, 24.99],
        香港特别行政区: [116.42, 23.35],
        澳门特别行政区: [113.58, 22.15],
      },
      mapData: [],
      faultTypes: [],
      maxValue: 0,
      platforms: [],
      classOption: {
        limitMoveNum: 2,
        direction: 2,
        step: 0.5
      },
      cityTotal: 0,
      title:""
    };
  },

  mounted() {
    this.$echarts.registerMap("china", require("@/assets/json/china.json"));
    this.getTitle();
    this.getEquipmentCount();
    this.getElevatorStatus();
    this.getAlarmList();
    this.getFaultList();
    this.getFaultType();
    this.getMapData();
    this.getPlatforms();
    window.addEventListener("resize", this.handleResize);
  },

  methods: {
    // 获取看板标题
    getTitle() {
      this.$http.get("kanban/getTitle").then(res => {
        this.title = res.data;
      });
    },
    handleResize() {
      // 当窗口大小改变时调用此方法
      // 可以选择在这里执行一些逻辑，例如调整组件样式或布局

      // 如果需要重新加载页面，可以使用以下代码：
      location.reload();
    },
    // 获取设备总数
    getEquipmentCount() {
      this.$http.get("kanban/elevator/count").then(res => {
        this.equipmentCount = res.data;
        this.getInNetCount();
      });
    },
    // 获取入网总数
    getInNetCount() {
      this.$http.get("kanban/inNet/count").then(res => {
        this.inNetCount = res.data;
        this.getOnlineCount();
      });
    },
    // 获取在线总数
    getOnlineCount() {
      this.$http.get("kanban/online/count").then(res => {
        this.onlineCount = res.data;
        this.getRepairCount();
      });
    },
    // 获取当前急修数量
    getRepairCount() {
      this.$http.get("kanban/workOrder/count").then(res => {
        this.repairCount = res.data;
      });
    },
    // 获取电梯状态
    getElevatorStatus() {
      this.$http.get("kanban/count/status").then(res => {
        this.statusData = res.data;
        this.total = res.data.reduce((acc, item) => {
          return acc + item.value;
        }, 0);
        this.initElevatorStatusPie();
      });
    },
    // 电梯状态饼图
    initElevatorStatusPie() {
      const myChart = this.$echarts.init(document.getElementById("statusChart"));
      const option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          orient: "vertical",
          top: "center",
          left: "50%",
          formatter: (name) => {
            const value = this.statusData.find(item => item.name === name).value;
            return name + "{a|" + value + "}";
          },
          textStyle: {
            lineHeight: 40,
            color: "#fff",
            rich: {
              a: {
                color: "#fff",
                fontSize: 24,
                fontWeight: "bold",
                padding: [0, 0, 0, 16]
              }
            }
          }
        },
        // 配置饼图颜色
        color: ["#00C291", "#FF4B00", "#CCCCCC"],
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            center: ["120px", "50%"],
            data: this.statusData,
            label: {
              show: true,
              position: "center",
              fontSize: 22,
              fontWeight: "bold",
              formatter: this.total.toString(),
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    // 警铃报警
    getAlarmList() {
      this.$http.get("kanban/alarmBell").then(res => {
        this.alarmList = res.data;
      });
    },
    // 实时故障
    getFaultList() {
      this.$http.get("kanban/fault").then(res => {
        this.faultList = res.data;
      });
    },
    // 获取故障类型
    getFaultType() {
      this.$http.get("kanban/faultType/count").then(res => {
        this.maxValue = res.data[0].total;
        this.faultTypes = res.data.reverse();
        this.faultTopChart();
      });
    },
    // 故障类型Top5
    faultTopChart() {
      const myChart = this.$echarts.init(document.getElementById("faultTop"));
      const option = {
        dataset: {
          dimensions: ["name", "total"],
          source: this.faultTypes
        },
        grid: {
          top: "20%",
          bottom: 16,
          left: 24,
          right: 24,
          containLabel: true
        },
        xAxis: {
          type: "value",
          axisLabel: {
            color: "#fff",
          }
        },
        yAxis: {
          name: "单位：次",
          nameTextStyle: {
            color: "#fff",
            padding: [0, 0, 0, -155]
          },
          type: "category",
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: "#fff",
            formatter: function (value) {
              var maxLength = 8; // 设置最大字符数
              if (value.length > maxLength) {
                return value.slice(0, maxLength - 2) + ".."; // 截断并添加省略号
              }
              return value;
            }
          }
        },
        series: [
          {
            type: "bar",
            barWidth: 16,
            itemStyle: {
              borderRadius: 4,
              color: (params) => {
                if (params.data.total == this.maxValue) {
                  return new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    { offset: 0, color: "rgba(7,71,253,0)" }, // 起始颜色
                    { offset: 1, color: "#F1A118" } // 结束颜色
                  ]);
                } else {
                  return new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    { offset: 0, color: "rgba(7,71,253,0)" }, // 起始颜色
                    { offset: 1, color: "#0747FD" } // 结束颜色
                  ]);
                }
              }
            },
            label: {
              show: true,
              position: "right",
              color: "#fff"
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    // 获取地图信息
    getMapData() {
      this.$http.get("kanban/province/count").then(res => {
        this.mapData = res.data;
        this.initMap();
      });
    },
    // 地图
    initMap() {
      const myChart = this.$echarts.init(document.getElementById("map"));
      const option = {
        tooltip: {
          trigger: "item"
        },
        geo: {
          map: "china",
          zoom: 1.2,
          itemStyle: {
            borderColor: "#EBEBEB",
            shadowBlur: 2, // 阴影的模糊大小
            shadowColor: "rgba(0, 0, 0, 0.1)", // 阴影的颜色
            areaColor: "#fff", 
          },
          emphasis: {
            itemStyle:{
              areaColor: "#EBF0FF", 
            }
          }
        },
        series: [
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            data: this.convertData(this.mapData),
            symbolSize: (val) => {
              if (val[2] > 10000) {
                return 35;
              } else if (val[2] > 5000) {
                return 25;
              } else if (val[2] > 1000) {
                return 15;
              } else {
                return 5;
              }
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke"
            },
            hoverAnimation: true,
            encode: {
              value: 2
            },
            itemStyle: {
              color: "rgba(7,71,253,0.6)",
              shadowBlur: 10,
              shadowColor: "#333"
            },
            zlevel: 1,
            emphasis: {
              scale: true,
            }
          },
        ]
      };
      myChart.setOption(option);
    },
    convertData(data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var geoCoord = this.geoCoordMap[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            lat: geoCoord[data[i].name],
            value: geoCoord.concat(data[i].value)
          });
        }
      }
      return res;
    },
    // 获取对接省/地方平台
    getPlatforms() {
      this.$http.get("kanban/passport/count").then(res => {
        const arr = [];
        for (let key in res.data) {
          arr.push({
            name: key,
            value: res.data[key] ? res.data[key] : 0
          });
          if(res.data[key] > 0) {
            this.cityTotal ++;
          }
        }
        this.platforms = arr;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cloudKanban {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/kanban/content-bg@2x.png");
  background-position: center;
  background-size: cover;

  .top {
    height: 80px;
    background-image: url("../../assets/kanban/topline@2x.png");
    background-position: center;
    background-size: cover;
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    line-height: 80px;
  }

  .bottom {
    flex: 1;
    display: flex;
    padding-bottom: 32px;
    overflow: hidden;

    .itemTitle {
      height: 48px;
      border-radius: 24px 0px 0px 24px;
      background: linear-gradient(90deg, #0747FD 0%, rgba(7, 71, 253, 0) 93%, rgba(7, 71, 253, 0) 100%);
      font-size: 20px;
      color: #fff;
      line-height: 48px;
      padding-left: 24px;
    }

    .itemContent {
      flex: 1;
      padding-left: 24px;
      overflow: hidden;

      .itemTime {
        font-size: 14px;
        color: #B4C7FF;
      }

      .itemName {
        color: #fff;
      }

      .otherInfo {
        color: #F1A118;
      }

      div {
        margin-top: 10px;
      }
    }

    .line {
      height: 1px;
      background: linear-gradient(90deg, #B4C7FF 0%, rgba(7, 71, 253, 0) 100%);
    }

    .left {
      width: 488px;
      padding-left: 32px;
      display: flex;
      flex-direction: column;

      .countInfo {
        margin-bottom: 20px;

        .countItem {
          display: flex;
          color: #fff;

          .icon {
            width: 80px;
            height: 80px;
            margin-right: 10px;
          }

          .countName {
            color: #B4C7FF;
            margin-top: 6px;
          }

          .value {
            font-family: "electronicFont";
            font-size: 40px;
          }
        }
      }

      .alarm {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }

    .middle {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-right: 15px;
      color: #B4C7FF;
      font-size: 20px;
      overflow: hidden;

      .map {
        flex: 1;
      }

      .warp {
        width: 100%;
        height: 80px;
        overflow: hidden;
        margin: 15px 0;

        ul {
          list-style: none;
          padding: 0;
          margin: 0 auto;

          &.ul-item {
            display: flex;

            .li-item {
              border: 1px solid #0747FD;
              box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
              box-sizing: border-box;
              background: rgba(180, 199, 255, 0);
              border-radius: 12px;
              width: 110px;
              height: 80px;
              margin-right: 15px;
              color: #fff;
              text-align: center;
              font-size: 14px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              .cityCount {
                font-family: "electronicFont";
                font-size: 40px;
                color: #fff;
              }
            }
          }
        }
      }
    }

    .right {
      width: 488px;
      display: flex;
      flex-direction: column;
      padding-right: 32px;

      .fault {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
    }
  }
}
</style>