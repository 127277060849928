<template>
  <el-dialog v-if="dialogVisible" v-el-drag-dialog :close-on-click-modal="false" :visible.sync="dialogVisible"
             :title="'接口调用日志' + filter.integrationKey" width="1500px" top="2vh" @close="$reset('form')">
    <vm-table ref="vmTable" :filter.sync="filter" :url="url" @clear="restTime">
      <template slot="adSearch">
        <vm-search label="时间范围">
          <el-date-picker v-model="dateRange" :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss"
                          type="datetimerange"
                          :unlink-panels="true" :range-separator="$t('common.to')"
                          :start-placeholder="$t('common.startDate')"
                          :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']"
                          @change="handleDateChange" :clearable="false">
          </el-date-picker>
        </vm-search>

        <vm-search label="电梯标识">
          <el-input v-model.trim="filter.elevatorKey" clearable></el-input>
        </vm-search>
        <vm-search label="接口名称">
          <el-input v-model.trim="filter.apiName" clearable></el-input>
        </vm-search>
        <vm-search label="其他标签">
          <el-input v-model.trim="filter.otherTag" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="elevatorKey" label="标签" width="200px">
        <template slot-scope="scope">
          <div>请求时间：{{scope.row.ts}}</div>
          <div>电梯标识：{{scope.row.elevatorKey || "无"}}</div>
          <div>接口名称：{{scope.row.apiName || "无"}}</div>
          <div>其他标签：{{scope.row.otherTag || "无"}}</div>
        </template>
      </el-table-column>
      <el-table-column label="数据">
        <template slot-scope="scope">
          <div>
            <div style="font-weight: bolder">URL</div>
            <span v-if="scope.row.status !== 200" style="font-weight: bolder; ">{{
                scope.row.result === null ? "【请求中】" : ("【"
                  + scope.row.status + "】")
              }}</span>
            <span style="font-weight: bolder;color: #0d65ab">{{scope.row.method}}</span> {{scope.row.url}}
          </div>
          <div>
            <div style="font-weight: bolder;margin-top: 10px">请求数据</div>
            <div>{{scope.row.request}}</div>
          </div>
          <div>
            <div style="font-weight: bolder;margin-top: 10px">回复数据</div>
            <div>{{scope.row.response}}</div>
          </div>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>

  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        loading: false,
        dateRange: [],
        //url: window.config.integrationUrl + "integrationLog/logs",
        url:"integrationLog/logs",
        filter: {
          startTime: "",
          endTime: "",
          elevatorKey: "",
          apiName: "",
          otherTag: "",
        },
        pickerOptions: {
          disabledDate: function(time) {
            return time.getFullYear() < 2022;
          },
        },
      };
    },
    methods: {
      open(integrationKey) {
        this.filter.integrationKey = integrationKey;
        this.dialogVisible = true;
        this.getDate();
        this.$nextTick(() => {
          this.getList(1);
        });
      },
      restTime() {
        if ("shanghai" == this.filter.integrationKey) {
          this.dateRange = [];
          this.filter.startTime = this.aogTime;
          this.filter.endTime = this.nowTime;
          this.dateRange.push(this.aogTime, this.nowTime);
        } else {
          this.dateRange = [];
        }
      },
      getDate() {
        if ("shanghai" == this.filter.integrationKey) {
          let now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);
          let ago = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2, 0, 0, 0);
          this.nowTime = this.getTime(now);
          this.aogTime = this.getTime(ago);
          this.dateRange.push(this.aogTime, this.nowTime);
          this.filter.startTime = this.aogTime;
          this.filter.endTime = this.nowTime;
        }
      },
      getTime(now) {
        var year = now.getFullYear(); //得到年份
        var month = now.getMonth(); //得到月份
        var date = now.getDate(); //得到日期
        month = month + 1;
        month = month.toString().padStart(2, "0");
        date = date.toString().padStart(2, "0");

        var hour = now.getHours();
        var minute = now.getMinutes();
        var second = now.getSeconds();
        hour = hour.toString().padStart(2, "0");
        minute = minute.toString().padStart(2, "0");
        second = second.toString().padStart(2, "0");

        let yearTime = `${year}-${month}-${date}`;
        let hourTime = `${hour}:${minute}:${second}`;

        return yearTime + " " + hourTime;
      },
      getList(pageNum) {
        this.$refs.vmTable.clear();
        this.$refs.vmTable.getList(pageNum);
      },
      handleDateChange(dateRange) {
        console.log(dateRange);
        if (dateRange != null) {
          this.filter.startTime = dateRange[0];
          this.filter.endTime = dateRange[1];
          if ("shanghai" == this.filter.integrationKey) {
            if (dateRange.length > 0) {
              let fullTime = [];
              for (let i = 0; i < dateRange.length; i++) {
                let time = dateRange[i].split(" ");

                let fullYear = time[0].split("-");
                let year = fullYear[0];
                let month = fullYear[1];
                let day = fullYear[2];

                let fullDay = time[1].split(":");
                let hour = fullDay[0];
                let minute = fullDay[1];
                let second = fullDay[2];

                let date = new Date(year, month, day, hour, minute, second);
                fullTime.push(date.getTime());
              }
              if (Math.ceil((fullTime[1] - fullTime[0]) / (24 * 3600 * 1000)) > 3) {
                this.$message.error(this.$l("common.sevenTime", "请填写3天之内的时间！"));
                this.dateRange = [this.aogTime, this.nowTime];
                this.filter.startTime = this.aogTime;
                this.filter.endTime = this.nowTime;
              }
            }
          }
        } else {
          this.filter.startTime = this.aogTime;
          this.filter.endTime = this.nowTime;
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
