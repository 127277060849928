<template>
  <div class="body22">
    <div class="back">
      <div class="logo">
        <img class="large" :src="$tenant.bigLogo" style="height: 50px"/>
      </div>
      <div class="content">
        <p style="font-size: 12px;color: #404040;margin: 10px 0px 10px 10px">{{ $t("workOrder.currentLocation") }}</p>
        <div class="vm-separate" style="border: solid 1px #D63B3F;background-color: #FBEBEB;">
          <div style="margin-left:10px;margin-right:10px;line-height: 50px">
            <span
              style="font-size: 20px;font-weight: 600;color: #333333">{{ $t("workOrder.workOrderNo") }}：{{ workOrderRecord.workOrderNo }}</span>
          </div>
          <div style="line-height: 50px">
            <span style="font-size: 20px;font-weight: 600;color: #333333"> {{ $t("workOrder.workOrderStatus") }}：
              <span v-if="workOrderRecord.recordState >= 0 && workOrderRecord.recordState <= 2"
                style="color: #333333">{{ $t("workOrder.notFinished") }}</span>
              <span v-if="workOrderRecord.recordState >= 3 && workOrderRecord.recordState < 5">
                <span v-if="workOrderRecord.finishState === 0"
                  style="color: #333333">{{ $t("workOrder.finished_") }}</span>
                <span v-if="workOrderRecord.finishState === 1"
                  style="color: #333333">{{ $t("workOrder.recovery") }}</span>
                <span v-if="workOrderRecord.finishState === 2"
                  style="color: #333333">{{ $t("workOrder.twoAlarmCompleted") }}</span>
              </span>
              <span v-if="workOrderRecord.recordState === 5" style="color: #333333">{{ $t("workOrder.cancelled") }}</span>
            </span>
          </div>
          <div></div>
        </div>
        <el-row>
          <ul class="vm-separate" style="margin: 20px 0px;">
            <li v-if="workOrderRecord.recordState >= 0">
              <p class="title">{{ $t("workOrder.alarmReported") }}</p>
              <p><img src="/static/images/workOrder/yi_bao_jing.png"/></p>
              <p class="date">{{ workOrderRecord.alarmTime }}</p>
            </li>
            <li>
              <span v-if="workOrderRecord.acceptTime !== null && workOrderRecord.acceptTime !== ''">
                <p class="title">{{ $t("workOrder.alarmed") }}</p>
                <p><img src="/static/images/workOrder/yi_jie_jing.png"/></p>
              </span>
              <span v-else-if="(workOrderRecord.arriveTime !== null && workOrderRecord.arriveTime !== '')
                || (workOrderRecord.finishTime !== null && workOrderRecord.finishTime !== '')">
                <p class="title">{{ $t("workOrder.unAlarmed") }}</p>
                <p><img src="/static/images/workOrder/yi_jie_jing.png"/></p>
              </span>
              <span v-else>
                <p class="title_gray">{{ $t("workOrder.unAlarmed") }}</p>
                <p><img src="/static/images/workOrder/jiejing_b.png"/></p>
              </span>
              <p class="date">{{ workOrderRecord.acceptTime }}</p>
            </li>
            <li>
              <span v-if="workOrderRecord.arriveTime !== null && workOrderRecord.arriveTime !== ''">
                <p class="title">{{ $t("workOrder.arrived") }}</p>
                <p><img src="/static/images/workOrder/yi_dao_chang.png"/></p>
              </span>
              <span v-else-if="workOrderRecord.finishTime !== null && workOrderRecord.finishTime !== ''">
                <p class="title">{{ $t("workOrder.notArrived") }}</p>
                <p><img src="/static/images/workOrder/yi_dao_chang.png"/></p>
              </span>
              <span v-else>
                <p class="title_gray">{{ $t("workOrder.notArrived") }}</p>
                <p><img src="/static/images/workOrder/daochang_b.png"/></p>
              </span>
              <p class="date">{{ workOrderRecord.arriveTime }}</p>
            </li>
            <li>
              <span
                v-if="workOrderRecord.finishTime !== null && workOrderRecord.finishTime !== '' && workOrderRecord.recordState >= 3">
                <span v-if="workOrderRecord.finishState === 0">
                  <p class="title">{{ $t("workOrder.finished_") }}</p>
                  <p><img src="/static/images/workOrder/yi_wan_gong.png"/></p>
                </span>
                <span v-else-if="workOrderRecord.finishState === 1">
                  <p class="title">{{ $t("workOrder.recovery") }}</p>
                  <p><img src="/static/images/workOrder/yi_wan_gong.png"/></p>
                </span>
                <span v-else-if="workOrderRecord.finishState === 2">
                  <p class="title">{{ $t("workOrder.twoAlarmCompleted") }}</p>
                  <p><img src="/static/images/workOrder/yi_wan_gong.png"/></p>
                </span>
              </span>
              <span v-else>
                <p class="title_gray">{{ $t("workOrder.notFinished") }}</p>
                <p><img src="/static/images/workOrder/wangong_b.png"/></p>
              </span>
              <p class="date">{{ workOrderRecord.finishTime }}</p>
            </li>
            <li>
              <span
                v-if="workOrderRecord.confirmTime !== null && workOrderRecord.confirmTime !== '' && workOrderRecord.recordState >= 4">
                <span v-if="workOrderRecord.confirmState === 0">
                  <p class="title">{{ $t("workOrder.confirmed_") }}</p>
                  <p><img src="/static/images/workOrder/yi_que_ren.png"/></p>
                </span>
                <span v-else-if="workOrderRecord.confirmState === 1">
                  <p class="title">{{ $t("workOrder.notConfirmed") }}</p>
                  <p><img src="/static/images/workOrder/yi_que_ren.png"/></p>
                </span>
              </span>
              <span v-else>
                <p class="title_gray">{{ $t("workOrder.autoConfirmed") }}</p>
                <p><img src="/static/images/workOrder/queren_b.png"/></p>
              </span>
              <p class="date">{{ workOrderRecord.confirmTime }}</p>
            </li>
          </ul>
        </el-row>
        <!--<div class="vm-separate" style="margin: 20px 0px 20px 0px">-->
        <!--<el-steps :active="stepActive" align-center>-->
        <!--<el-step :title="$t('workOrder.alarmReported')" :description="workOrderRecord.alarmTime" v-if="workOrderRecord.recordState >= 0">-->
        <!--</el-step>-->
        <!--<el-step :title="$t('workOrder.notReported')" v-else></el-step>-->

        <!--<el-step :title="$t('workOrder.alarmed')" :description="workOrderRecord.acceptTime"-->
        <!--v-if="workOrderRecord.recordState >= 1"></el-step>-->
        <!--<el-step :title="$t('workOrder.unAlarmed')" v-else></el-step>-->

        <!--<el-step :title="$t('workOrder.arrived')" :description="workOrderRecord.arriveTime"-->
        <!--v-if="workOrderRecord.recordState >= 2"></el-step>-->
        <!--<el-step :title="$t('workOrder.notArrived')" v-else></el-step>-->

        <!--<el-step :title="$t('workOrder.finished_')" :description="workOrderRecord.finishTime"-->
        <!--v-if="workOrderRecord.recordState >= 3 && workOrderRecord.finishState === 0"></el-step>-->
        <!--<el-step :title="$t('workOrder.recovery')" :description="workOrderRecord.finishTime"-->
        <!--v-else-if="workOrderRecord.recordState >= 3 && workOrderRecord.finishState === 1"></el-step>-->
        <!--<el-step :title="$t('workOrder.twoAlarmCompleted')" :description="workOrderRecord.finishTime"-->
        <!--v-else-if="workOrderRecord.recordState >= 3 && workOrderRecord.finishState === 2"></el-step>-->
        <!--<el-step :title="$t('workOrder.notFinished')" v-else></el-step>-->

        <!--<el-step :title="$t('workOrder.confirmed_')" :description="workOrderRecord.confirmTime"-->
        <!--v-if="workOrderRecord.recordState >= 4 && workOrderRecord.confirmState === 0"></el-step>-->
        <!--<el-step :title="$t('workOrder.autoConfirmed')" :description="workOrderRecord.confirmTime"-->
        <!--v-else-if="workOrderRecord.recordState >= 4 && workOrderRecord.confirmState === 1"></el-step>-->
        <!--<el-step :title="$t('workOrder.notConfirmed')" v-else></el-step>-->

        <!--<el-step :title="$t('workOrder.cancelled')" v-if="workOrderRecord.recordState === 5"></el-step>-->
        <!--</el-steps>-->
        <!--</div>-->
        <div style="border: solid 1px #DCDFE6; ">
          <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;">
            <span style="margin: 10px;line-height: 30px;font-weight: 600">{{ $t("workOrder.baseInfo") }}</span>
          </div>
          <div style="border-bottom: solid 1px #DCDFE6;margin: 0px 20px 0px 20px;">
            <div style="margin: 10px;">
              <span
                style="line-height: 25px;font-weight: 600;font-size: 15px;color: #2B80DA;">{{ $t("workOrder.workOrder") }}</span>
              <div class="vm-separate">
                <span>
                  {{ $t("workOrder.faultTime") }}：{{ workOrderRecord.faultTime }}
                </span>
                <span>
                  {{ $t("workOrder.callTime") }}：{{ workOrderRecord.callTime }}
                </span>
              </div>
              <div class="vm-separate">
                <span>
                  {{ $t("workOrder.alarmReportedBy") }}：
                  <span v-if="workOrderRecord.repairMethod === 1">
                    <el-tag type="danger">{{ $t("workOrder.selfRepair") }}</el-tag>
                  </span>
                  <span v-else>
                    <span
                      v-if="workOrderRecord.repairPeople === null || workOrderRecord.repairPeople.length === 0">[未记录]</span>
                    <span>{{ workOrderRecord.repairPeople }}</span>
                  </span>
                </span>
                <span>{{ $t("workOrder.alarmPeopleTel") }}：{{ workOrderRecord.repairPhone }}</span>
              </div>
              <div class="vm-separate">
                <span>
                  {{ $t("workOrder.alarmType") }}：
                  <span v-if="workOrderRecord.alarmType === 0" style="cursor:pointer"><img height="15px"
                      src="/static/images/elevator/tingti.png"
                      :title="$t('workOrder.alarmType_0')"/>&nbsp;{{ $t("workOrder.alarmType_0") }}</span>
                  <span v-if="workOrderRecord.alarmType === 1" style="cursor:pointer"><img height="15px"
                      src="/static/images/elevator/guanren.png"
                      :title="$t('workOrder.alarmType_1')"/>&nbsp;{{ $t("workOrder.alarmType_1") }}</span>
                  <span v-if="workOrderRecord.alarmType === 2" style="cursor:pointer"><img height="15px"
                      src="/static/images/elevator/serious.png"
                      :title="$t('workOrder.alarmType_2')"/>&nbsp;{{ $t("workOrder.alarmType_2") }}</span>
                  <span v-if="workOrderRecord.alarmType === 3" style="cursor:pointer"><img height="15px"
                      src="/static/images/elevator/jingling.png"
                      :title="$t('workOrder.alarmType_3')"/>&nbsp;{{ $t("workOrder.alarmType_3") }}</span>
                  <span v-if="workOrderRecord.alarmType === 4">{{ $t('workOrder.deviceReasonTrapped') }}</span>
                  <span v-if="workOrderRecord.alarmType === 5">{{ $t('workOrder.deviceReasonSafe') }}</span>
                  <span v-if="workOrderRecord.alarmType === 6">{{ $t('workOrder.elevatorDamageOrFault') }}</span>
                  <span v-if="workOrderRecord.alarmType === 7">{{ $t('workOrder.minorIssues') }}</span>
                  <span v-if="workOrderRecord.alarmType === 8">{{ $t('workOrder.userReasonNoTrapped') }}</span>
                  <span v-if="workOrderRecord.alarmType === 9">{{ $t('workOrder.userReasonTrapped') }}</span>
                </span>
                <span>
                  {{ $t("workOrder.inspection") }}：
                  <span v-if="workOrderRecord.isYearFault === 0">{{ $t("common.no") }}</span>
                  <span v-if="workOrderRecord.isYearFault === 1">{{ $t("common.yes") }}</span>
                </span>
              </div>
              <div class="vm-separate">
                <span>{{ $t("workOrder.repairPeople") }}：{{ workOrderRecord.alarmPeopleName }}</span>
                <span>{{ $t("workOrder.acceptor") }}：{{ workOrderRecord.acceptorName }}</span>
              </div>
              <div class="vm-separate">
                <span>{{ $t("workOrder.revocationReason") }}：{{ workOrderRecord.revokeReason }}</span>
                <span v-if="workOrderRecord.recordState === 4">{{ $t("workOrder.confirmPeople") }}：
                  <span v-if="workOrderRecord.confirmPeopleId === 0">{{ $t("workOrder.system") }}</span>
                  <span v-else>{{ workOrderRecord.confirmPeopleName }}</span>
                </span>
              </div>
              <div class="vm-separate">
                <span>{{ $t("workOrder.faultReason") }}：{{ workOrderRecord.faultReasonDesc }}</span>
                <span>{{ $t("workOrder.handleMethod") }}：{{ workOrderRecord.handleMethod }}</span>
              </div>
            </div>
          </div>
          <div style="border-bottom: solid 1px #DCDFE6;margin: 0px 20px 0px 20px;">
            <div style="margin: 10px;">
              <span
                style="line-height: 25px;font-weight: 600;font-size: 15px;color: #2B80DA;">{{ $t("workOrder.propertyInfo") }}</span>
              <div class="vm-separate">
                <span>{{ $t("workOrder.propertyInfo") }}：{{ workOrderRecord.propertyComName }}</span>
              </div>
              <div class="vm-separate">
                <span>{{ $t("workOrder.contactPerson") }}：{{ workOrderRecord.propertyComPerson }}</span>
                <span>
                  {{ $t("workOrder.contactTel") }}：{{ workOrderRecord.propertyComPhone }}
                </span>
              </div>
            </div>
          </div>
          <div style="border-bottom: solid 1px #DCDFE6;margin: 0px 20px 0px 20px;">
            <div style="margin: 10px;">
              <span
                style="line-height: 25px;font-weight: 600;font-size: 15px;color: #2B80DA;">{{ $t("elevator.maintenanced") }}</span>
              <div class="vm-separate">
                <span>{{ $t("elevator.maintenanced") }}：{{ workOrderRecord.maintComName }}</span>
              </div>
              <div class="vm-separate">
                <span>{{ $t("workOrder.servicePersonnel") }}：
                  <span v-for="(personnel, index) in workOrderRecord.workOrderPersonnels" :key="index">
                    {{ personnel.userName }}
                    ({{ personnel.userMobile }})&nbsp; &nbsp;</span>
                </span>
              </div>
            </div>
          </div>
          <div style="margin: 0px 20px 0px 20px;">
            <div style="margin: 10px;">
              <span
                style="line-height: 25px;font-weight: 600;font-size: 15px;color: #2B80DA;">{{ $t("workOrder.elevatorInfo") }}</span>
              <div class="vm-separate">
                <span>{{ $t("elevator.no") }}：
                  <router-link :to="'/elevator/' + workOrderRecord.elevatorId" target="_blank"><span
                      style="color: #409EFF">{{ workOrderRecord.elevatorNo }}</span>
                  </router-link>&nbsp;|&nbsp;
                  <router-link v-if="$i18n.isCn" :to="'/singleMonitor/' + workOrderRecord.elevatorId"
                    target="_blank"><span
                      style="color: #409EFF">{{ $t("workOrder.monitorElevator") }}</span></router-link>
                  <router-link v-else :to="'/singleMonitorOld/' + workOrderRecord.elevatorId" target="_blank"><span
                      style="color: #409EFF">{{ $t("workOrder.monitorElevator") }}</span></router-link>
                </span>
                <span>{{ $t("elevator.name") }}：{{ workOrderRecord.elevatorName }}</span>
              </div>
              <div class="vm-separate">
                <span>{{ $t("elevator.maintWorker") }}：{{ workOrderRecord.maintName }}<span
                    v-if="workOrderRecord.maintEmpMobile !== null">({{ workOrderRecord.maintEmpMobile }})</span></span>
                <span>{{ $t("elevator.elevatorType") }}：{{ workOrderRecord.elevatorProductName }}</span>
              </div>
              <div class="vm-separate">
                <span>{{ $t("elevator.model") }}：{{ workOrderRecord.product }}</span>
                <span>{{ $t("elevator.address") }}：{{ workOrderRecord.address }}</span>
              </div>
              <div class="vm-separate">
                <span>{{ $t("elevator.projectName") }}：{{ workOrderRecord.projectName }}</span>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div style="border: solid 1px #DCDFE6;margin-top: 20px">
          <div
            style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;display: flex;justify-content: space-between">
            <span style="margin: 5px 10px;font-weight: 600;color: #2B80DA;cursor:pointer;"
              @click="display1 = changeDisplay(display1)">{{ $t("elevator.faultRecord") }}<i v-if="display1 === 'block'"
                class="el-icon-arrow-down"></i><i v-else class="el-icon-arrow-up"></i></span>
            <!--          <span-->
            <!--            style="margin: 5px 10px;font-weight: 600;color: #2B80DA;cursor:pointer;"-->
            <!--            @click="gotoImageList">{{$l("workOrder.screenshot","截图")}}</span>-->
          </div>
          <div :style="{ display: display1 }">
            <el-table :data="workOrderRecord.faultRecords" border stripe style="width: 100%">
              <el-table-column type="expand">
                <template v-slot="scope">
                  <table class="childTable">
                    <tr>
                      <th style="width: 5%">{{ $t("common.index") }}</th>
                      <th style="width: 7%">{{ $t("faultTemplate.subCode") }}</th>
                      <th style="width: 30%">{{ $t("faultTemplate.reason") }}</th>
                      <th>{{ $t("faultTemplate.solution") }}</th>
                    </tr>
                    <tr v-for="(solution, index) in scope.row.solutionSettings" :key="index">
                      <td style="text-align: center">{{ index + 1 }}</td>
                      <td></td>
                      <td>{{ solution.reason }}</td>
                      <td>{{ solution.solution }}</td>
                    </tr>
                  </table>
                </template>
              </el-table-column>
              <el-table-column prop="faultCode" :label="$t('workOrder.faultNo')" align="center"></el-table-column>
              <el-table-column v-if="false" prop="faultCode" :label="$t('workOrder.hex')" align="center">
                <template slot-scope="scope">
                  {{ Number(scope.row.faultCode).toString(16) }}
                </template>
              </el-table-column>
              <el-table-column prop="faultDesc" :label="$t('workOrder.faultType')" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.faultDesc === '000000'">{{ $t("workOrder.unKnowFault") }}</span>
                  <span v-else>{{ scope.row.faultDesc }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="faultTime" :label="$t('workOrder.faultTime')" align="center"></el-table-column>
              <el-table-column v-if="false" prop="sendTime" :label="$t('workOrder.mainBoardTime')"
                align="center"></el-table-column>
              <el-table-column v-if="false" prop="faultFloor" :label="$t('workOrder.faultStorey')"></el-table-column>
              <el-table-column prop="faultFloorCode" :label="$t('workOrder.faultFloorCode')" width="110px"
                align="center"></el-table-column>
              <el-table-column prop="displayFaultFloor" :label="$t('workOrder.displayFaultFloor')" width="110px"
                align="center"></el-table-column>
            </el-table>
          </div>
        </div>
        <div style="border: solid 1px #DCDFE6;margin-top: 20px">
          <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;">
            <span style="margin: 10px;line-height: 30px;font-weight: 600;color: #2B80DA;cursor:pointer;"
              @click="display2 = changeDisplay(display2)">{{ $t("workOrder.workorderInfoTrack") }}<i
                v-if="display2 === 'block'" class="el-icon-arrow-down"></i><i v-else
                class="el-icon-arrow-up"></i></span>
          </div>
          <div :style="{ display: display2 }">
            <div v-for="(workOrderStateNote, index) in workOrderRecord.workOrderStateNotes" :key="index" style="">
              <div style="margin: 10px;font-size: 14px;">
                <span v-if="workOrderStateNote.userId === 0" style="margin: 20px">
                  <img src="/static/images/elevator/xitong.png" :title="$t('workOrder.system')"/>
                </span>
                <span v-else style="margin: 20px;">
                  {{ workOrderStateNote.userName }}
                </span>
                <span style="margin: 20px">{{ workOrderStateNote.datetime }}</span>
                <span style="margin: 20px;width: 300px">{{ workOrderStateNote.note }}</span>
                <span v-if="showOrgId && workOrderStateNote.hexString !== '' && workOrderStateNote.hexString !== null"
                  style="margin: 20px;width: 100px">
                  <el-popover v-if="workOrderStateNote.hexString !== '' && workOrderStateNote.hexString !== null"
                    placement="top-start" width="400" trigger="hover" :content="workOrderStateNote.hexString"
                    style="color: #0747FD;">
                    <div style="width: 100%">
                      原始数据包:{{ workOrderStateNote.hexString }}
                    </div>
                    <div v-if="workOrderStateNote.hexMap">
                      <div
                      v-if="workOrderStateNote.hexMap.floorCode !== '' && workOrderStateNote.hexMap.floorCode !== null"
                      style="width: 100%;padding-top: 10px">
                      <span>物理楼层:{{ workOrderStateNote.hexMap.floorCode }}</span>
                      <span>楼层显示:{{ workOrderStateNote.hexMap.displayFloor }}</span>
                    </div>
                    <div v-if="workOrderStateNote.hexMap.liftUp !== '' && workOrderStateNote.hexMap.liftUp !== null"
                      style="width: 100%;padding-top: 10px">
                      <div>
                        <span>上行:{{ workOrderStateNote.hexMap.liftUp }}</span>
                        <span>下行:{{ workOrderStateNote.hexMap.liftDown }}</span>
                        <span>运行:{{ workOrderStateNote.hexMap.liftRun }}</span>
                        <span>门区:{{ workOrderStateNote.hexMap.doorRegion }}</span>
                        <span>开门中:{{ workOrderStateNote.hexMap.doorOpening }}</span>
                        <span>关门中:{{ workOrderStateNote.hexMap.doorClosing }}</span>
                        <span>开门到位:{{ workOrderStateNote.hexMap.doorOpened }}</span>
                        <span>关门到位:{{ workOrderStateNote.hexMap.doorClosed }}</span>
                      </div>
                      <div style="padding-top: 5px">
                        <span>总门锁:{{ workOrderStateNote.hexMap.totalDoorLock }}</span>
                        <span>厅门锁:{{ workOrderStateNote.hexMap.officeDoorLock }}</span>
                        <span>满载:{{ workOrderStateNote.hexMap.fullLoad }}</span>
                        <span>超载:{{ workOrderStateNote.hexMap.overLoad }}</span>
                        <span>车厢叫车:{{ workOrderStateNote.hexMap.carriageCall }}</span>
                        <span>开门按钮:{{ workOrderStateNote.hexMap.openButton }}</span>
                        <span>轿厢报警:{{ workOrderStateNote.hexMap.carAlarm }}</span>
                        <span>群控:{{ workOrderStateNote.hexMap.groupControl }}</span>
                      </div>
                      <div style="padding-top: 5px">
                        <span>前门光幕:{{ workOrderStateNote.hexMap.frontDoorCurtain }}</span>
                        <span>后门光幕:{{ workOrderStateNote.hexMap.backDoorCurtain }}</span>
                        <span>上平层:{{ workOrderStateNote.hexMap.upperLayer }}</span>
                        <span>下平层:{{ workOrderStateNote.hexMap.lowerLayer }}</span>
                      </div>
                    </div>
                    <div v-if="workOrderStateNote.hexMap.overhaul !== '' && workOrderStateNote.hexMap.overhaul !== null"
                      style="width: 100%;padding-top: 10px">
                      <div>
                        <span>检修:{{ workOrderStateNote.hexMap.overhaul }}</span>
                        <span>司机:{{ workOrderStateNote.hexMap.driver }}</span>
                        <span>锁梯:{{ workOrderStateNote.hexMap.lockLadder }}</span>
                        <span>VIP:{{ workOrderStateNote.hexMap.vip }}</span>
                        <span>消防返回:{{ workOrderStateNote.hexMap.fireReturn }}</span>
                        <span>消防员:{{ workOrderStateNote.hexMap.firefighter }}</span>
                        <span>安全回路通:{{ workOrderStateNote.hexMap.safetyCircuit }}</span>
                        <span>正常（非故障）:{{ workOrderStateNote.hexMap.normal }}</span>
                      </div>
                      <div style="padding-top: 5px">
                        <span>地震运行:{{ workOrderStateNote.hexMap.seismicOperation }}</span>
                        <span>应急电源运行:{{ workOrderStateNote.hexMap.emergencySupply }}</span>
                        <span>后备电源运行:{{ workOrderStateNote.hexMap.backupPowerSupply }}</span>
                        <span>抱闸力检测:{{ workOrderStateNote.hexMap.brakeForceDetection }}</span>
                        <span>井道学习:{{ workOrderStateNote.hexMap.hoistwayLearning }}</span>
                        <span>测试运行:{{ workOrderStateNote.hexMap.testRun }}</span>
                      </div>
                      <div style="padding-top: 5px">
                        <span>网关掉电:{{ workOrderStateNote.hexMap.gatewayPowerDown }}</span>
                        <span>电梯离线:{{ workOrderStateNote.hexMap.offline }}</span>
                        <span>警铃报警:{{ workOrderStateNote.hexMap.alarmBellAlarm }}</span>
                      </div>
                    </div>
                    <div
                      v-if="workOrderStateNote.hexMap.faultTime !== '' && workOrderStateNote.hexMap.faultTime !== null"
                      style="width: 100%;padding-top: 10px">
                      <span>故障物理楼层:{{ workOrderStateNote.hexMap.faultFloorCode }}</span>
                      <span>故障显示楼层:{{ workOrderStateNote.hexMap.displayFaultFloor }}</span>
                      <span>故障码:{{ workOrderStateNote.hexMap.faultCode }}</span>
                      <span>故障子码:{{ workOrderStateNote.hexMap.faultSubCode }}</span>
                      <span>故障发生时间:{{ workOrderStateNote.hexMap.faultTime }}</span>
                    </div>
                    <div
                      v-if="workOrderStateNote.hexMap.terminalTime !== '' && workOrderStateNote.hexMap.terminalTime !== null"
                      style="width: 100%;padding-top: 10px">
                      <div>终端时间戳:{{ workOrderStateNote.hexMap.terminalTime }}</div>
                    </div>
                    <div v-if="workOrderStateNote.hexMap.runSpeed !== '' && workOrderStateNote.hexMap.runSpeed !== null"
                      style="width: 100%;padding-top: 10px">
                      <span>电梯速度:{{ workOrderStateNote.hexMap.runSpeed }}</span>
                      <span>电梯高度:{{ workOrderStateNote.hexMap.runHigh }}</span>
                    </div>
                    <div
                      v-if="workOrderStateNote.hexMap.levelingErrorValue !== '' && workOrderStateNote.hexMap.levelingErrorValue !== null"
                      style="width: 100%;padding-top: 10px">
                      <span>平层误差值:{{ workOrderStateNote.hexMap.levelingErrorValue }}</span>
                    </div>
                    </div>
                    <i slot="reference" class="el-icon-warning"></i>
                  </el-popover>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div style="border: solid 1px #DCDFE6;margin-top: 20px">
          <div
            style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;display: flex;justify-content: space-between">
            <span style="margin: 5px 10px;font-weight: 600;color: #2B80DA;cursor:pointer;"
              @click="display3 = changeDisplay(display3)">{{ $t("route.newMsgSend") }}<i v-if="display3 === 'block'"
                class="el-icon-arrow-down"></i><i v-else class="el-icon-arrow-up"></i></span>
          </div>
          <div :style="{ display: display3 }">
            <el-table :data="workOrderRecord.newMsgSends" border stripe style="width: 100%">
              <el-table-column prop="type" :label="$t('newMsgSend.type')" align="center" width="200px">
                <template slot-scope="scope">
                  <span v-if="scope.row.type === 'msgPush'">
                    {{ $t("newMsgSend.msgPush") }}
                  </span>
                  <span v-if="scope.row.type === 'sms'">
                    {{ $t("newMsgSend.sms") }}
                  </span>
                  <span v-if="scope.row.type === 'email'">
                    {{ $t("newMsgSend.email") }}
                  </span>
                  <span v-if="scope.row.type === 'wxMsgPush'">
                    {{ $t("newMsgSend.wxMsgPush") }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="content" :label="$t('newMsgSend.content')" align="center"></el-table-column>
              <el-table-column prop="target" :label="$t('newMsgSend.target')" align="center"
                width="200px"></el-table-column>
              <el-table-column prop="status" :label="$t('newMsgSend.status')" align="center" width="200px">
                <template slot-scope="scope">
                  <span v-if="scope.row.status === 1">
                    {{ $t("newMsgSend.sendSucceed") }}
                  </span>
                  <span v-if="scope.row.status === 2">
                    {{ $t("newMsgSend.failSend") }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="sendTime" :label="$t('newMsgSend.sendTime')" align="center"
                width="200px"></el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 图片/视频 -->
        <div style="border: solid 1px #DCDFE6;margin-top: 20px">
          <div
            style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;display: flex;justify-content: space-between">
            <span style="margin: 5px 10px;font-weight: 600;color: #2B80DA;cursor:pointer;"
              @click="display4 = changeDisplay(display4)">{{ $t('alarm.imageOrVideo') }}<i v-if="display4 === 'block'"
                class="el-icon-arrow-down"></i><i v-else class="el-icon-arrow-up"></i></span>
          </div>
          <div :style="{ display: display4 }">
            <div style="padding: 10px;">
              <el-image :preview-src-list="pictureList" v-for="item in workOrderRecord.pictureList" :key="item.id" style="width: 150px; height: 150px;border-radius: 4px;margin-right: 10px;" :src="item.path"></el-image>
              <video width="200" height="150" controls v-for="item in workOrderRecord.videoList" :key="item.id" :src="item.path"></video>
            </div>
          </div>
        </div>
        <!--      <div style="border: solid 1px #DCDFE6;margin-top: 20px">-->
        <!--        <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;">-->
        <!--          <span-->
        <!--            style="margin: 10px;line-height: 30px;font-weight: 600;color: #2B80DA;cursor:pointer;"-->
        <!--            @click="display3 = changeDisplay(display3)">{{$t("workOrder.deviceInfo")}}<i-->
        <!--              v-if="display3 === 'block'"-->
        <!--              class="el-icon-arrow-down"></i><i-->
        <!--                v-else-->
        <!--                class="el-icon-arrow-up"></i></span>-->
        <!--        </div>-->
        <!--        <div style="border-bottom: solid 1px #DCDFE6;" :style="{display: display3}">-->
        <!--          <el-tabs v-model="activeTab" type="card">-->
        <!--            <el-tab-pane label="DTU" name="first">-->
        <!--              <vm-table-->
        <!--                ref="vmTable"-->
        <!--                :table-data="tableData1"-->
        <!--                :total="total1"-->
        <!--                :current-page="currentPage1"-->
        <!--                :show-toolbar="false"-->
        <!--                @page-change="changePage1">-->
        <!--                <el-table-column prop="status" :label="$t('workOrder.status')">-->
        <!--                  <template slot-scope="scope">-->
        <!--                    <span v-if="scope.row.status === 1 "><img-->
        <!--                      src="/static/images/elevator/online.png"-->
        <!--                      :title="$t('workOrder.onLine')"/>{{$t("workOrder.onLine")}}</span>-->
        <!--                    <span v-else><img-->
        <!--                      src="/static/images/elevator/offline.png"-->
        <!--                      :title="$t('workOrder.offLine')"/>{{$t("workOrder.offLine")}}</span>-->
        <!--                  </template>-->
        <!--                </el-table-column>-->
        <!--                <el-table-column prop="createTime" :label="$t('workOrder.createTime')"></el-table-column>-->
        <!--              </vm-table>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane :label="$t('workOrder.launcher')" name="second">-->
        <!--              <vm-table-->
        <!--                ref="vmTable"-->
        <!--                :table-data="tableData2"-->
        <!--                :total="total2"-->
        <!--                :current-page="currentPage2"-->
        <!--                :show-toolbar="false"-->
        <!--                @page-change="changePage2">-->
        <!--                <el-table-column prop="status" :label="$t('workOrder.status')">-->
        <!--                  <template slot-scope="scope">-->
        <!--                    <span v-if="scope.row.status === 1 "><img-->
        <!--                      src="/static/images/elevator/online.png"-->
        <!--                      :title="$t('workOrder.onLine')"/>{{$t("workOrder.onLine")}}</span>-->
        <!--                    <span v-else><img-->
        <!--                      src="/static/images/elevator/offline.png"-->
        <!--                      :title="$t('workOrder.offLine')"/>{{$t("workOrder.offLine")}}</span>-->
        <!--                  </template>-->
        <!--                </el-table-column>-->
        <!--                <el-table-column prop="createTime" :label="$t('workOrder.createTime')"></el-table-column>-->
        <!--              </vm-table>-->
        <!--            </el-tab-pane>-->
        <!--          </el-tabs>-->
        <!--        </div>-->
        <!--      </div>-->
        <div v-if="workOrderRecord.recordState === 1" style="border: solid 1px #DCDFE6;margin-top: 20px">
          <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;">
            <span
              style="margin: 10px;line-height: 30px;font-weight: 600;cursor:pointer;">{{ $t("workOrder.rescueMap") }}</span>
          </div>
          <div style="border-bottom: solid 1px #DCDFE6;">
            <bai-du-map style="height:70vh;border:#ccc solid 1px;" :start-point-id="workOrderRecord.alarmPeopleId"
              :end-point-id="workOrderRecord.clientId"></bai-du-map>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
    </div>
  </div>
</template>

<script>
import VmTable from "../../components/VmTable.vue";
import BaiDuMap from "./BaiDuMap.vue";
import auth from "@/util/auth";


const moduleName = "workOrders";

export default {
  components: { BaiDuMap },
  data() {
    return {
      pictureList:[],
      showOrgId: auth.getUsername() === "admin" || auth.getUsername() === "superAdmin",
      copyright: window.config.copyright,
      stepActive: 1,
      display1: "block",
      display2: "block",
      display3: "block",
      display4: "block",
      activeTab: "first",
      tableData1: [],
      tableData2: [],
      total1: 0,
      total2: 0,
      currentPage1: 1,
      currentPage2: 1,
      workOrderRecord: {
        id: 0,
        faultTime: "",
        projectName: "",
        callTime: "",
        repairPhone: "",
        workOrderNo: "",
        repairPeople: "",
        handleMethod: "",
        faultReasonDesc: "",
        alarmPeopleName: "",
        alarmPeopleId: 0,
        alarmType: 0,
        isYearFault: 0,
        acceptorName: "",
        confirmPeopleId: "",
        confirmPeopleName: "",
        acceptTime: "",
        alarmTime: "",
        arriveTime: "",
        finishTime: "",
        confirmTime: "",
        cancelTime: "",
        revokeReason: "",
        confirmState: -1,
        recordState: -1,
        finishState: -1,
        clientId: 0,
        useUnitName: "",
        repairMethod: "",
        elevatorId: 0,
        elevatorNo: "",
        elevatorName: "",
        dtuCode: "",
        address: "",
        propertyComName: "",
        propertyComPerson: "",
        propertyComTel: "",
        propertyComPhone: "",
        maintComName: "",
        maintName: "",
        maintEmpMobile: "",
        elevatorProductName: "",
        product: "",
        contactPerson: "",
        contactTel: "",
        alarmSolution: "",
        note: "",
        alarmReason: "",
        workOrderPersonnels: [],
        workOrderStateNotes: [],
        faultRecords: [],
        newMsgSends: [],
        fileList:[],
      },
    };
  },
  mounted() {
    this.workOrderRecord.id = this.$route.params.workOrderId;
    this.getData();
  },
  methods: {
    getMsgSendData(referBizNo) {
      this.$http.get("msgSend/referBizNo/" + referBizNo).then(res => {
        this.workOrderRecord.newMsgSends = res.data;
      }).catch((error) => {
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
        }
      });
    },
    getData() {
      this.$api.getById(moduleName + "/detail", this.workOrderRecord.id).then(res => {
        this.workOrderRecord = Object.assign(this.workOrderRecord, res.data);
        this.getMsgSendData(this.workOrderRecord.workOrderNo);
        this.workOrderRecord.fileList = [...this.workOrderRecord.pictureList,...this.workOrderRecord.videoList];
        this.pictureList = this.workOrderRecord.pictureList.map(item => item.path);
        if (!this.$i18n.isCn) {
          if (this.workOrderRecord.elevatorProductName === "曳引驱动乘客电梯") {
            this.workOrderRecord.elevatorProductName = "Traction Drive Passenger Elevator";
          }
          if (this.workOrderRecord.elevatorProductName === "曳引驱动载货电梯") {
            this.workOrderRecord.elevatorProductName = "Traction Drive Freight Elevator";
          }
          if (this.workOrderRecord.elevatorProductName === "强制驱动载货电梯") {
            this.workOrderRecord.elevatorProductName = "Forced Drive Freight Elevator";
          }
          if (this.workOrderRecord.elevatorProductName === "液压乘客电梯") {
            this.workOrderRecord.elevatorProductName = "Hydraulic Passenger Elevator";
          }
          if (this.workOrderRecord.elevatorProductName === "液压载货电梯") {
            this.workOrderRecord.elevatorProductName = "Hydraulic Freight Elevator";
          }
          if (this.workOrderRecord.elevatorProductName === "自动扶梯") {
            this.workOrderRecord.elevatorProductName = "Escalator";
          }
          if (this.workOrderRecord.elevatorProductName === "自动人行道") {
            this.workOrderRecord.elevatorProductName = "Moving Walk";
          }
          if (this.workOrderRecord.elevatorProductName === "防爆电梯") {
            this.workOrderRecord.elevatorProductName = "Explosion-proof Elevator";
          }
          if (this.workOrderRecord.elevatorProductName === "消防员电梯") {
            this.workOrderRecord.elevatorProductName = "Firefighter Elevator";
          }
          if (this.workOrderRecord.elevatorProductName === "杂物电梯") {
            this.workOrderRecord.elevatorProductName = "Debris Elevator";
          }
          for (let i = 0; i < this.workOrderRecord.workOrderStateNotes.length; i++) {
            if (this.workOrderRecord.workOrderStateNotes[i].note === "有人") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Has People";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "无人") {
              this.workOrderRecord.workOrderStateNotes[i].note = "No People";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修报警") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair The Alarm";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修接警") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair Called";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修到场") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair To Be Present";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修完工") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repairing Completion";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修确认") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair Confirmation";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修撤销") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair To Cancel";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修暂停") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair Suspended";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修恢复") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair Back";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "自动转检修") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Automatic To Overhaul";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "检修转自动") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Overhaul To Automatic";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "故障转检修") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Fault To Overhaul";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "检修转故障") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Overhaul To Fault";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "报警发送A0") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Alarm Send A0";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "报警收到A0门锁打开") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Alarm Received A0 Door Lock Open";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "查询电梯状态") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Query Elevator Status";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "报警收到A0门锁闭合") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Alarm received A0 door lock closed";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "急修二次报警完工") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Emergency Repair Secondary Alarm Completed";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "自动确认") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Automatic Confirmation";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "自动撤销转维保记录") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Automatically Cancel The Transfer Maintenance Record";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "警铃报警") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Alarm Bell";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "严重故障") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Critical Failure";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "自动") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Auto";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "自动故障") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Auto Failure";
            }
            if (this.workOrderRecord.workOrderStateNotes[i].note === "检修自动") {
              this.workOrderRecord.workOrderStateNotes[i].note = "Overhaul Automatically";
            }
          }
        }
        this.initStepActive();
        this.getList1(1);
        this.getList2(1);
      }).catch((error) => {
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
        }
      });
    },
    // 图片/视频下载
    downLoad(filePath) {
      window.open(filePath);
    },
    getList1(pageIndex) {
      this.currentPage1 = pageIndex;
      let params;
      if (this.workOrderRecord.recordState >= 0 && this.workOrderRecord.recordState < 3) {
        params = {
          pageIndex,
          parentNo: 0,
          no: this.workOrderRecord.dtuCode,
          startTime: this.workOrderRecord.alarmTime,
          endTime: "",
        };
      } else if (this.workOrderRecord.recordState >= 3 && this.workOrderRecord.recordState <= 4) {
        params = {
          pageIndex,
          parentNo: 0,
          no: this.workOrderRecord.dtuCode,
          startTime: this.workOrderRecord.alarmTime,
          endTime: this.workOrderRecord.finishTime,
        };
      } else if (this.workOrderRecord.recordState === 5) {
        params = {
          pageIndex,
          parentNo: 0,
          no: this.workOrderRecord.dtuCode,
          startTime: this.workOrderRecord.alarmTime,
          endTime: this.workOrderRecord.cancelTime,
        };
      }
    },
    getList2(pageIndex) {
      this.currentPage2 = pageIndex;
      let params;
      if (this.workOrderRecord.recordState >= 0 && this.workOrderRecord.recordState < 3) {
        params = {
          pageIndex,
          parentNo: 0,
          no: this.workOrderRecord.dtuCode,
          startTime: this.workOrderRecord.alarmTime,
          endTime: "",
        };
      } else if (this.workOrderRecord.recordState >= 3 && this.workOrderRecord.recordState <= 4) {
        params = {
          pageIndex,
          parentNo: 0,
          no: this.workOrderRecord.dtuCode,
          startTime: this.workOrderRecord.alarmTime,
          endTime: this.workOrderRecord.finishTime,
        };
      } else if (this.workOrderRecord.recordState === 5) {
        params = {
          pageIndex,
          parentNo: 0,
          no: this.workOrderRecord.dtuCode,
          startTime: this.workOrderRecord.alarmTime,
          endTime: this.workOrderRecord.cancelTime,
        };
      }
    },
    initStepActive() {
      if (this.workOrderRecord.recordState === 1) {
        this.stepActive = 2;
      }
      if (this.workOrderRecord.recordState === 2) {
        this.stepActive = 3;
      }
      if (this.workOrderRecord.recordState >= 3) {
        this.stepActive = 4;
      }
      if (this.workOrderRecord.recordState >= 4) {
        this.stepActive = 5;
      }
      if (this.workOrderRecord.recordState === 5) {
        this.stepActive = 6;
      }
    },
    changePage1(pageIndex) {
      this.getList1(pageIndex);
    },
    changePage2(pageIndex) {
      this.getList2(pageIndex);
    },
    changeDisplay(e) {
      if (e === "block") {
        e = "none";
      } else {
        e = "block";
      }
      return e;
    },
    // gotoImageList() {
    //   let routeUrl = this.$router.resolve({
    //     path: "/jx/imageList",
    //     query: {jxId: this.workOrderRecord.id},
    //   });
    //   window.open(routeUrl.href, "_blank");
    // },
  },
};
</script>
<style lang="scss" scoped>
.body22 {
  background-color: #f2f2f2;
  height: 100%;
  overflow-y: scroll;

  .back {
    width: 100%;

    .logo {
      width: 1090px;
      margin: 0 auto;
    }

    .content {
      width: 1090px;
      background-color: white;
      margin: 0 auto;
      border: solid 1px #DCDFE6;
      padding-bottom: 100px;
    }
  }

  .footer {
    color: #FFF;
    background-color: #404040;
    position: fixed;
    text-align: center;
    width: 100%;
    left: 0;
    bottom: 0px;
    z-index: 100;
  }
}


span {
  color: #606266;
}

.vm-separate {
  font-size: 12px;
  margin-bottom: 6px;
  margin-left: 6px;
  margin-right: 6px;
}

ul {
  white-space: nowrap;
  width: 92%;
}

li {
  list-style-type: none;
  display: inline;
}

p {
  margin: 0;
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #5a9d11;
  margin-left: -10px;
}

.title_gray {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #999999;
  margin-left: -10px;
}

.date {
  font-size: 12px;
  color: #666666;
  text-align: center;
  margin-top: 5px;
}

.childTable {
  width: 100%;
  border-collapse: collapse;

  td {
    border: 1px solid #D8DCE5;
    padding: 5px 10px;
    background-color: #FAFAFA;
  }

  th {
    font-weight: 500;
    text-align: center;
    border: 1px solid #D8DCE5;
    padding: 8px 10px;
    background-color: #EEF2F7;
  }
}
</style>
