<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane v-if="recordAuth" label="ESDT故障记录" name="first">
      <fault-record-list></fault-record-list>
    </el-tab-pane>
    <el-tab-pane label="EMT故障记录" name="second">
      <insh-alarm-list></insh-alarm-list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import FaultRecordList from "./FaultRecordList";
  import InshAlarmList from "./InshAlarmList";

  export default {
    components: { FaultRecordList,InshAlarmList },
    data() {
      return {
        recordAuth: this.$auth(117),
        activeName: "first",
      };
    },
    mounted() {
      if (this.mbAuth) {
        this.activeName = "first";
        return;
      }
      if (this.nbAuth) {
        this.activeName = "second";
        return;
      }
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
    },
  };
</script>
<style lang="scss" scoped></style>
