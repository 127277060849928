<template>
  <div>
    <div
      v-loading="contentLoading"
      class="body"
      style="margin: 10px 10px 0px 10px;padding:10px;border: solid 1px #DCDFE6">
      <h2 align="center">{{$t("elevator.elevatorDebuggingPlatform")}}</h2>
      <div class="vm-separate">
        <div style="padding: 20px 2px 20px 20px;border: solid 1px #DCDFE6">
          <el-row :gutter="20" class="elEow">
            <el-col :span="4">
              <div><span>{{$t("elevator.name")}}：</span></div>
            </el-col>
            <el-col :span="6">
              <div><span>{{elevator.name}}</span></div>
            </el-col>
            <el-col :span="4">
            </el-col>
            <el-col :span="4">
              <div><span>{{$t("elevator.no")}}：</span></div>
            </el-col>
            <el-col :span="6">
              <div><span>{{elevator.no}}</span></div>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="elEow">
            <el-col :span="4">
              <div><span>{{$t("elevator.dtuCode")}}：</span></div>
            </el-col>
            <el-col :span="6">
              <div><span>{{elevator.dtuCode}}</span></div>
            </el-col>
            <el-col :span="4">
            </el-col>
            <el-col :span="4">
              <div><span>{{$t("elevator.nodeCode")}}：</span></div>
            </el-col>
            <el-col :span="6">
              <div><span>{{elevator.nodeCode}}</span></div>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 20px;" class="elEow">
            <el-col :span="8">
              <div>
                <el-button type="primary" @click="bindChange()">{{$t("elevator.activationBinding")}}</el-button>
                <el-button type="primary" @click="getBindChange()">{{$t("common.search")}}</el-button>
              </div>
            </el-col>
            <el-col :span="16">
              <el-checkbox-group v-model="checkList">
                <el-row :gutter="20">
                  <el-checkbox label="1">{{$t("elevator.AllBoards")}}</el-checkbox>
                  <el-checkbox label="2">{{$t("elevator.elseBoards")}}</el-checkbox>
                </el-row>
                <el-row :gutter="20">
                  <el-checkbox label="3">{{$t("elevator.simCardBinding")}}</el-checkbox>
                  <el-checkbox label="4">{{$t("elevator.groupControlBoard")}}</el-checkbox>
                  <el-checkbox label="5">{{$t("elevator.frequencyConverter")}}</el-checkbox>
                </el-row>
                <el-row :gutter="20">
                  <el-checkbox label="6">{{$t("elevator.carBoard")}}</el-checkbox>
                  <el-checkbox label="7">{{$t("elevator.sedanRoof")}}</el-checkbox>
                  <el-checkbox label="8">{{$t("elevator.callBoard")}}</el-checkbox>
                </el-row>
              </el-checkbox-group>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="elEow">
            <el-col :span="8">
              <div>
                <el-button type="primary" @click="probationChange()">
                  {{$t("elevator.changeProbationPeriod")}}
                </el-button>
                <el-button type="primary" @click="getProbationChange()">{{$t("common.search")}}</el-button>
              </div>
            </el-col>
            <el-col :span="16">
              <el-row :gutter="5">
                <el-col :span="3">
                  <el-input v-model="day"></el-input>
                </el-col>
                <el-col :span="2">
                  <span>{{$t("elevator.day")}}</span>
                </el-col>
                <el-col :span="3">
                  <el-input v-model="hour"></el-input>
                </el-col>
                <el-col :span="3">
                  <span>{{$t("elevator.hour")}}</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="elEow">
            <el-col :span="8">
              <div>
                <el-button type="primary" @click="authorityChange()">{{$t("elevator.openDebugAuth")}}</el-button>
              </div>
            </el-col>
            <el-col :span="16">
              <el-row>
                <el-radio v-model="authority" label="1" @change="onAuthChange()">{{$t("elevator.closeAuth")}}</el-radio>
              </el-row>
              <el-row :gutter="2">
                <el-col :span="5">
                  <el-radio v-model="authority" label="2">{{$t("elevator.openAuth")}}</el-radio>
                </el-col>
                <el-col :span="2" :offset="1">
                  <span>{{$t("elevator.grade")}}</span>
                </el-col>
                <el-col :span="4">
                  <el-select v-model="authLevel">
                    <el-option v-for="item in authLevels" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="2" :offset="1">
                  <span>{{$t("elevator.time")}}</span>
                </el-col>
                <el-col :span="3">
                  <el-input v-model="time"></el-input>
                </el-col>
                <el-col :span="3">
                  <span>{{$t("elevator.hour")}}</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="elEow">
            <el-col :span="8">
              <div>
                <el-button type="primary" @click="lockElevatorChange()">{{$t("elevator.stopOrStart")}}</el-button>
              </div>
            </el-col>
            <el-col :span="16">
              <el-row>
                <el-radio-group v-model="lockElevator">
                  <el-radio label="1">{{$t("elevator.unlock")}}</el-radio>
                  <el-radio label="2">{{$t("elevator.lock")}}</el-radio>
                </el-radio-group>
              </el-row>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="elEow">
            <el-col :span="8">
              <div>
                <el-button type="primary" @click="lockContractChange()">{{$t("elevator.lockOrUnlock")}}</el-button>
              </div>
            </el-col>
            <el-col :span="16">
              <el-row>
                <el-radio-group v-model="lockContract">
                  <el-radio label="1">{{$t("elevator.Unlock")}}</el-radio>
                  <el-radio label="2">{{$t("elevator.Lock")}}</el-radio>
                </el-radio-group>
              </el-row>
              <el-row>
                <span>{{$t("elevator.contractNo")}}：</span>
                <span>{{elevator.contractNo}}</span>
              </el-row>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="elEow">
            <el-col :span="6">
              <el-button type="primary" :loading="loading1" @click="send(1)">发送开始ZB</el-button>
            </el-col>
            <el-col :span="6">
              <el-button type="primary" :loading="loading2" @click="send(2)">发送终止ZB</el-button>
            </el-col>
            <el-col :span="6">
              <el-button type="primary" :loading="loading3" @click="send(3)">发送开始ZC</el-button>
            </el-col>
            <el-col :span="6">
              <el-button type="primary" :loading="loading4" @click="send(4)">发送终止ZC</el-button>
            </el-col>
          </el-row>
        </div>
        <div>
          <div style="width: 98%;margin-left: 10px">
            <el-button type="primary" style="float: right;margin-bottom: 5px" @click="getList(1)">
              {{$t("elevator.refresh")}}
            </el-button>
          </div>
          <vm-table
            ref="vmTable"
            :filter.sync="search"
            url="remote"
            style="width: 98%;margin-left: 10px">
            <el-table-column :label="$t('elevator.operationRecord')" align="center" width="100%">
              <el-table-column prop="type" :label="$t('elevator.type')">
                <template slot-scope="scope">
                  <span v-if="scope.row.type === 1">{{$t("elevator.activationBinding")}}</span>
                  <span v-if="scope.row.type === 2">{{$t("elevator.changeProbationPeriod")}}</span>
                  <span v-if="scope.row.type === 3">{{$t("elevator.openDebugAuth")}}</span>
                  <span v-if="scope.row.type === 4">{{$t("elevator.stopOrStart")}}</span>
                  <span v-if="scope.row.type === 5">{{$t("elevator.lockOrUnlock")}}</span>
                  <span v-if="scope.row.type === 11">{{$t("elevator.activationBinding")}}{{$t("common.search")}}</span>
                  <span
                    v-if="scope.row.type === 21">{{$t("elevator.changeProbationPeriod")}}{{$t("common.search")}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="data" :label="$t('elevator.order')" width="320px"></el-table-column>
              <el-table-column prop="status" :label="$t('elevator.status')">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status === 1">{{$t("elevator.executing")}}</el-tag>
                  <el-tag v-if="scope.row.status === 2" type="success">{{$t("elevator.success")}}</el-tag>
                  <el-tag v-if="scope.row.status === 3" type="danger">{{$t("elevator.error")}}</el-tag>
                  <el-tag v-if="scope.row.status === 4" type="warning">{{$t("elevator.overTime")}}</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="lastOperatingTime" :label="$t('elevator.time')"></el-table-column>
              <el-table-column prop="operaterAccount" :label="$t('elevator.operator')"></el-table-column>
            </el-table-column>
          </vm-table>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <el-table :data="newTableData" style="width: 100%;margin-top: 10px" :border="true">
            <el-table-column label="ZC数据包" align="center" width="100%">
              <el-table-column prop="dtuNO" label="终端编号"></el-table-column>
              <el-table-column prop="zigbeeNO" label="节点"></el-table-column>
              <el-table-column prop="dataNO" label="数据包序号"></el-table-column>
              <el-table-column prop="dataQuantity" label="数据包数量"></el-table-column>
              <el-table-column prop="dataTime" label="数据时间"></el-table-column>
              <el-table-column prop="floor" label="楼层"></el-table-column>
              <el-table-column prop="current" label="电流"></el-table-column>
              <el-table-column prop="hight" label="高度"></el-table-column>
              <el-table-column prop="speed" label="速度"></el-table-column>
              <el-table-column prop="ioStatus" label="IO状态"></el-table-column>
              <el-table-column prop="data" label="原始数据"></el-table-column>
              <el-table-column prop="dataRecievedTime" label="数据收到时间"></el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const moduleName = "elevators";
  const moduleName_remote = "remote";

  export default {
    data() {
      return {
        contentLoading: false,
        loading1: false,
        loading2: false,
        loading3: false,
        loading4: false,
        search: {
          elevatorId: "",
        },
        newTableData: [],
        sentUrl: "",
        total: 0,
        pageSize: 5,
        currentPage: 1,
        elevator: {
          id: 0,
          no: "",
          name: "",
          dtuCode: "",
          nodeCode: "",
          contractNo: "",
        },
        day: "",
        hour: "",
        authority: "",
        lockElevator: "",
        contractNo: "",
        lockContract: "",
        authLevel: "",
        time: "",
        interval: "",
        authLevels: [
          {
            value: "1",
            label: "1",
          },
          {
            value: "2",
            label: "2",
          },
          {
            value: "3",
            label: "3",
          },
          {
            value: "4",
            label: "4",
          }],
        checkList: [],
      };
    },
    computed: {
      title() {
        return this.$t("elevator.elevatorDebuggingPlatform");
      },
    },
    mounted() {
      this.elevator.id = this.$route.params.elevatorId;
      this.getData();
      this.getList(1);
    },
    methods: {
      getData() {
        this.$api.getById(moduleName, this.elevator.id).then(res => {
          this.elevator = Object.assign(this.elevator, res.data);
          console.log(this.elevator);
          this.contentLoading = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      send(type) {
        let params = {
          "dtuCode": this.elevator.dtuCode,
          "nodeCode": this.elevator.nodeCode,
        };
        if (type === 1) {
          this.loading1 = true;
          let sentUrl = moduleName_remote + "/sendStartZB";
          this.start(sentUrl, params, type);
        } else if (type === 2) {
          this.loading2 = true;
          let sentUrl = moduleName_remote + "/sendStopZB";
          this.start(sentUrl, params, type);
        } else if (type === 3) {
          this.loading3 = true;
          let sentUrl = moduleName_remote + "/sendStartZC";
          this.start(sentUrl, params, type);
        } else {
          this.loading4 = true;
          let sentUrl = moduleName_remote + "/sendStopZC";
          this.start(sentUrl, params, type);
        }
      },
      start(sentUrl, params, type) {
        this.$api.getData(sentUrl, params).then(res => {
          if (res.status === 200) {
            if (type === 3) {
              this.$message.success("执行成功,开始轮询");
              clearInterval(this.interval);
              this.loading3 = false;
              this.interval = setInterval(() => {
                this.getZC();
              }, 1000);
            } else {
              this.$message.success("执行成功");
              this.loading1 = false;
              this.loading2 = false;
              this.loading4 = false;
            }
          } else {
            this.$message.error(res.data);
          }
        }).catch((error) => {
          if (error.response) {
            this.loading1 = false;
            this.loading2 = false;
            this.loading3 = false;
            this.loading4 = false;
            this.$message.error(this.$t("elevator.tip.operateError") + "，" + error.response.data.message);
          }
        });
      },
      getZC() {
        this.$api.getData(moduleName_remote + "/getZC").then(res => {
          this.newTableData = res.data;
          console.log(this.newTableData);
        }).catch((error) => {
          if (error.response) {
            clearInterval(this.interval);
            this.$message.error(this.$t("elevator.tip.operateError") + "，" + error.response.data.message);
          }
        });
      },
      getList(pageIndex) {
        this.search.elevatorId = this.elevator.id;
        this.$refs.vmTable.getList(pageIndex);
      },
      changePage(pageIndex) {
        this.getList(pageIndex);
      },
      getBindChange() {
        let params = {
          "id": this.elevator.id,
          "dtuCode": this.elevator.dtuCode,
          "nodeCode": this.elevator.nodeCode,
        };
        this.$api.getData(moduleName_remote + "/CX_JHBD", params).then(res => {
          if (res.data === "success") {
            this.$message.success(this.$t("elevator.tip.operateSuccess"));
          } else if (res.data === "error") {
            this.$message.error(this.$t("elevator.tip.operateError"));
          } else {
            this.$message.error(res.data);
          }
          this.getList(1);
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("elevator.tip.operateError") + "，" + error.response.data.message);
          }
        });
      },
      bindChange() {
        if (this.checkList.length === 0) {
          this.$message.error(this.$t("elevator.tip.atLeastOne"));
          return;
        }
        let data = ["0", "0", "0", "0", "0", "0", "0", "0"];
        for (let item of this.checkList) {
          data[item - 1] = 1;
        }
        let dataStr = data.join("");
        let params = {
          "id": this.elevator.id,
          "dtuCode": this.elevator.dtuCode,
          "nodeCode": this.elevator.nodeCode,
          "data": dataStr,
        };
        this.$api.getData(moduleName_remote + "/JHBD", params).then(res => {
          if (res.data === "success") {
            this.$message.success(this.$t("elevator.tip.operateSuccess"));
          } else {
            this.$message.error(res.data);
          }
          this.getList(1);
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("elevator.tip.operateError") + "，" + error.response.data.message);
          }
        });
      },
      getProbationChange() {
        let params = {
          "id": this.elevator.id,
          "dtuCode": this.elevator.dtuCode,
          "nodeCode": this.elevator.nodeCode,
        };
        this.$api.getData(moduleName_remote + "/CX_YCSYQ", params).then(res => {
          if (res.data === "success") {
            this.$message.success(this.$t("elevator.tip.operateSuccess"));
          } else if (res.data === "error") {
            this.$message.error(this.$t("elevator.tip.operateError"));
          } else {
            this.$message.error(res.data);
          }
          this.getList(1);
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("elevator.tip.operateError") + "，" + error.response.data.message);
          }
        });
      },
      probationChange() {
        if (this.day === "" || this.hour === "") {
          this.$message.error(this.$t("elevator.tip.atLeastOne"));
          return;
        }
        let params = {
          "id": this.elevator.id,
          "dtuCode": this.elevator.dtuCode,
          "nodeCode": this.elevator.nodeCode,
          "day": this.day,
          "hour": this.hour,
        };
        this.$api.getData(moduleName_remote + "/YCSYQ", params).then(res => {
          if (res.data === "success") {
            this.$message.success(this.$t("elevator.tip.operateSuccess"));
          } else {
            this.$message.error(res.data);
          }
          this.getList(1);
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("elevator.tip.operateError") + "，" + error.response.data.message);
          }
        });
      },
      lockElevatorChange() {
        if (this.lockElevator === "") {
          this.$message.error(this.$t("elevator.tip.chooseFirst"));
        } else {
          let str = this.lockElevator === "1" ? this.$t("elevator.unlock") : this.$t("elevator.lock");
          this.$confirm(this.$t("elevator.tip.want") + str + " ?", this.$t("common.tips"), {
            confirmButtonText: this.$t("common.confirm"),
            cancelButtonText: this.$t("common.cancel"),
            type: "warning",
          }).then(() => {
            let params = {
              "id": this.elevator.id,
              "dtuCode": this.elevator.dtuCode,
              "nodeCode": this.elevator.nodeCode,
              "type": this.lockElevator,
            };
            this.$api.getData(moduleName_remote + "/TFT", params).then(res => {
              if (res.data === "success") {
                this.$message.success(this.$t("elevator.tip.operateSuccess"));
              } else {
                this.$message.error(res.data);
              }
              this.getList(1);
            }).catch((error) => {
              if (error.response) {
                this.$message.error(this.$t("elevator.tip.operateError") + "，" + error.response.data.message);
              }
            });
          });
        }
      },
      lockContractChange() {
        if (this.lockContract === "") {
          this.$message.error(this.$t("elevator.tip.chooseFirst"));
        } else if (this.elevator.contractNo === "") {
          this.$message.error(this.$t("elevator.tip.contractNo"));
        } else {
          let str = this.lockContract === "1" ? this.$t("elevator.Unlock") : this.$t("elevator.Lock");
          this.$confirm(this.$t("elevator.tip.want") + str + " ?", this.$t("common.tips"), {
            confirmButtonText: this.$t("common.confirm"),
            cancelButtonText: this.$t("common.cancel"),
            type: "warning",
          }).then(() => {
            let params = {
              "id": this.elevator.id,
              "dtuCode": this.elevator.dtuCode,
              "nodeCode": this.elevator.nodeCode,
              "contractNo": this.elevator.contractNo,
              "type": this.lockContract,
            };
            this.$api.getData(moduleName_remote + "/YCJSHTH", params).then(res => {
              if (res.data === "success") {
                this.$message.success(this.$t("elevator.tip.operateSuccess"));
              } else {
                this.$message.error(res.data);
              }
              this.getList(1);
            }).catch((error) => {
              if (error.response) {
                this.$message.error(this.$t("elevator.tip.operateError") + "，" + error.response.data.message);
              }
            });
          });
        }
      },
      authorityChange() {
        if (this.authority === "") {
          this.$message.error(this.$t("elevator.tip.chooseFirst"));
        } else {
          let str = this.authority === "1" ? this.$t("elevator.closeAuth") : this.$t("elevator.openAuth");
          this.$confirm(this.$t("elevator.tip.want") + str + " ?", this.$t("common.tips"), {
            confirmButtonText: this.$t("common.confirm"),
            cancelButtonText: this.$t("common.cancel"),
            type: "warning",
          }).then(() => {
            let params = "";
            if (this.authority === "1") {
              params = {
                "id": this.elevator.id,
                "dtuCode": this.elevator.dtuCode,
                "nodeCode": this.elevator.nodeCode,
                "type": this.authority,
                "authLevel": "",
                "time": "",
              };
            } else {
              if (this.authLevel === "" || this.time === "") {
                this.$message.error(this.$t("elevator.tip.gradeAndTime"));
                return;
              }
              params = {
                "id": this.elevator.id,
                "dtuCode": this.elevator.dtuCode,
                "nodeCode": this.elevator.nodeCode,
                "type": this.authority,
                "authLevel": this.authLevel,
                "time": this.time,
              };
            }
            this.$api.getData(moduleName_remote + "/YCKFQX", params).then(res => {
              if (res.data === "success") {
                this.$message.success(this.$t("elevator.tip.operateSuccess"));
              } else {
                this.$message.error(res.data);
              }
              this.getList(1);
            }).catch((error) => {
              if (error.response) {
                this.$message.error(this.$t("elevator.tip.operateError") + "，" + error.response.data.message);
              }
            });
          });
        }
      },
      onAuthChange() {
        this.authLevel = "";
        this.time = "";
      },
    },
  };
</script>

<style lang="scss" scoped>
span {
  color: #606266;
  font-size: 14px;
}

.elEow {
  margin-bottom: 20px;
}

.page {
  float: right;
}
</style>
