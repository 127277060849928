<template>
  <div
    v-loading="loading"
    :element-loading-text="msg"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="videoObj">
    <div v-if="false" style="width: 150px;height:75px;clear:both;margin: 10px auto;">
      <div style="float:left;display: inline-block">
        <div class="call" @click="lcdCall">
          <div class="el-icon-phone-outline"></div>
          <div style="position: relative;top: 29px;left: 17px">
            呼叫
          </div>
        </div>
      </div>
      <div style="float:right;display: inline-block">
        <div class="icon" @click="stop">
          <div class="el-icon-close"></div>
          <div style="position: relative;top: 29px;left: 17px">
            挂断
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 200px;">
      <div id="manualvideo">
      </div>
    </div>
  </div>
</template>

<script>
  import {Base64} from "js-base64";

  export default {
    name: "VideoPlayer",
    data() {
      return {
        dtuCode: "",
        platform: "",
        loading: true,
        msg: "拼命加载中",
        callLoading: false,
        dialogVisible: false,
        showIcon: true,
        showIcon1: true,
        interval: null,
        modal: {},
        appId: "",
      };
    },
    mounted() {
      this.loading = false;
      let pat = this.$route.query.pat;
      if (pat) {
        let platform = Base64.decode(pat);
        this.platform = platform;
      }
      this.getAppId();
      let token = this.$route.query.token;
      if (token) {
        this.dtuCode = Base64.decode(token);
        console.log("dtuCode=====" + this.dtuCode);
        this.lcdCall();
      }
    },
    methods: {
      lcdCall() {
        this.msg = "视频加载中....";
        this.loading = true;
        this.$http.post("lcd/call/" + this.platform + "/" + this.dtuCode, null).then(res => {
          // this.interval = setInterval(() => this.getRoom(), 5000);
          // this.getRoom();
          this.$nextTick(() => {
            window.BRTC_Stop();
            this.start(this.dtuCode);
            if (this.interval !== null) {
              clearInterval(this.interval);
            }
          });
        }).catch(() => {
          this.$message.error("加载失败");
        });
      },
      getAppId() {
        this.$http.get("lcd/" + this.platform + "/webRtcKey").then(res => {
          this.appId = res.data.paramKeyValue;
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      start(roomName) {
        window.BRTC_Start({
          server: "wss://rtc.exp.bcelive.com/janus",
          appid: this.appId,
          roomname: roomName,
          userid: new Date().getTime(),
          displayname: "hello",
          videocodec: "h264",//视频编码类型配置
          token: "",
          usingvideo: true,//是否使用本地视频设备
          usingaudio: true,//	是否使用本地音频设备
          rtmpmix: false,//直播转推是否混流
          bitrate: 1500,//	设置摄像头编码的码率
          videoprofile: {"height": 640, "width": 1200},//本地摄像头视频配置参数
          showspinner: true,//	是否显示加载过程
          userevent: true,//是否启用用户级事件
          waitpermissiontimeoutms: 3000,//等待权限超时大小，单位是毫秒
          autosubscribe: false,//	是否自动订阅流
          autopublish: true,//是否自动发布流
          showvideobps: true,//是否显示视频的带宽值
          sharescreen: false,//是否是屏幕共享
          aspublisher: true,//是否是发布者
          usingdatachannel: true,//是否使用数据通道
          remotevideoviewid: "manualvideo",//远端视频
          localvideoviewid: "herevideo",//本地视频
          error: (err) => {
            console.log("err:" + err);
          },
          localvideopublishing: () => {
            this.showIcon = false;
            console.log("本地视频流发布成功");
          },
          onlocalstream_end: (name) => {
            this.showIcon = true;
            console.log("本地视频流关闭," + name);
          },
          remotevideoon: (idx) => {
            this.showIcon1 = false;
            console.log("远端视频流到达,remotevideoon, index:" + idx);
          },
          remotevideooff: (idx) => {
            this.showIcon1 = true;
            console.log("远端视频流离开,remotevideooff, index:" + idx);
          },
          destroyed: (error) => {
            console.log("error:" + error);
          },
          remotevideocoming: (id, display, attribute) => {
            this.$message.success("远端用户：" + display + "，上线！" + id);
            if (id.toString().includes("12345")) {
              this.loading = false;
              console.log("自动订阅-----------");
              window.BRTC_SubscribeStreaming("manualvideo", id);
            }
          },
          remotevideoleaving: (id) => {
            this.$message.success("远端用户：" + id + "离开房间！");
            window.BRTC_StopSubscribeStreaming(id);
          },
          // userevent_leavingroom: (id, display) => {
          //   this.$message.success("用户：" + display + "，离开房间！" + id);
          // },
          // userevent_joinedroom: (id, display, attribute) => {
          //   this.$message.success("用户：" + display + "，加入房间！" + id);
          // },
          onattribute: (e) => {

          },
        });
      },
      stop() {
        this.dialogVisible = false;
        this.showIcon1 = true;
        this.showIcon = true;
        window.BRTC_Stop();
        // if (this.interval !== null) {
        //   clearInterval(this.interval);
        // }
      },
    },
  };
</script>

<style lang="scss" scoped>
.hide {
  display: none !important;
}

.rtc-font-color {
  font-size: 12px;
  color: #4D66FE;
}

.rtc-title-font-color {
  font-size: 20px;
  color: #1A1C47;
}

//#manualvideo {
//  width: 100%;
//  height: 600px;
//  background-color: #FBFBFB;
//  border: 1px solid #E1E1E1;
//  box-sizing: border-box;
//}

.videoObj {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #FBFBFB;

  #herevideo {
    width: 100%;
    height: 600px;
    background-color: #FBFBFB;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
  }

  .iconfont {
    font-size: 100px;
    color: #999;
    width: 100px;
    position: relative;
    top: 200px;
    left: 200px;
  }

  .call {
    clear: both;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: #fa5151;
    display: inline-block;

    &:hover {
      background-color: #ee4d4d;
    }
  }


  .icon {
    clear: both;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: #fa5151;
    display: inline-block;

    &:hover {
      background-color: #ee4d4d;
    }
  }

  .close-video {
    clear: both;
    margin: 0 auto;
  }

  .el-icon-close {
    color: #FFFFFF;
    font-size: 33px;
    position: relative;
    top: 15px;
    left: 12px;
  }

  .el-icon-phone-outline {
    color: #FFFFFF;
    font-size: 33px;
    position: relative;
    top: 15px;
    left: 12px;
  }

}
</style>
