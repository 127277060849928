<template>
  <el-dialog
    class="small-padding"
    :title="title"
    :visible.sync="dialogVisible"
    width="800"
    top="5vh">
    <div>
      <vm-table ref="vmTable" v-loading="loading" :filter.sync="filter" url="elevatorLcds/getConnectLog">
        <el-table-column prop="terminalID" :label="$t('device.code')" align="center"></el-table-column>
        <el-table-column :label="$t('device.status')" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1" type="success">{{ $t("workOrder.onLine") }}</el-tag>
            <el-tag v-else type="info">{{ $t("workOrder.offLine") }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" :label="$t('workOrder.createTime')" align="center"></el-table-column>
      </vm-table>
    </div>
  </el-dialog>
</template>
<script>

  export default {
    components: {},
    data() {
      return {
        title: this.$t("device.record"),
        dialogVisible: false,
        loading: false,
        filter: {
          terminalID: "",
        },
      };
    },
    mounted() {
    },
    methods: {
      open(code) {
        this.dialogVisible = true;
        if (code) {
          this.filter.terminalID = code;
          this.$nextTick(() => {
            this.getList(1);
          });
        }
      },
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      changePage(pageIndex) {
        this.getList(pageIndex);
      },
    },
  };
</script>
<style lang="scss" scoped></style>
