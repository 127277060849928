<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane :label="$t('elevator.eocdException')" name="first">
      <eocd-exception-list></eocd-exception-list>
    </el-tab-pane>
    <el-tab-pane label="EOCD参数设置" name="two">
      <eocd-setting-list></eocd-setting-list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import EocdSettingList from "./EocdSettingList";
  import EocdExceptionList from "./EocdExceptionList";

  export default {
    components: {EocdSettingList,EocdExceptionList},
    data() {
      return {
        activeName: "first",
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
    },
  };
</script>
<style lang="scss" scoped></style>
